var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[(_vm.saved)?_c('div',{staticClass:"alert alert-primary",attrs:{"role":"alert"}},[_vm._v(" 保存しました。 ")]):_vm._e(),(_vm.deleted)?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_vm._v(" 削除しました。 ")]):_vm._e(),_c('b-modal',{attrs:{"size":"xl","id":"form-modal","no-close-on-backdrop":true,"no-close-on-esc":true,"title":!_vm.selectedTag.name ? '新規作成' : '「' + _vm.selectedTag.name + '」を編集',"hide-footer":""}},[(_vm.selectedTag.id && _vm.selectedTag.videoCount == 0)?_c('p',[_vm._v(" 削除する場合は、名前をカラにしてください。 ")]):_vm._e(),_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveTag.apply(null, arguments)}}},[_c('b-form-group',{staticClass:"form-group"},[_c('div',{staticClass:"input-group text-center"},[_c('div',{staticClass:"mr-2 col-3 text-right"},[_vm._v("名前")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.selectedTag.name),expression:"selectedTag.name",modifiers:{"trim":true}}],staticClass:"form-control col-9 mt-1",attrs:{"type":"text","required":"required","placeholder":"名前"},domProps:{"value":(_vm.selectedTag.name)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;if($event.target !== $event.currentTarget)return null;$event.preventDefault();},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.selectedTag, "name", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"input-group row mt-2 mb-2"},[(
            _vm.selectedTag.id &&
            _vm.selectedTag.name.trim() == '' &&
            _vm.selectedTag.videoCount == 0
          )?_c('a',{staticClass:"btn btn-sm btn-danger offset-7 col-2",on:{"click":function($event){return _vm.deleteTag()}}},[_vm._v(" 削除 ")]):_c('button',{staticClass:"btn btn-sm btn-primary offset-7 col-2",attrs:{"type":"submit"}},[_vm._v(" 保存 ")]),_c('a',{staticClass:"btn btn-sm btn-outline-secondary offset-1 col-2",on:{"click":function($event){return _vm.$bvModal.hide('form-modal')}}},[_vm._v(" キャンセル ")])])],1),(_vm.confirmMessage)?_c('div',{staticClass:"alert alert-info",staticStyle:{"white-space":"pre-wrap","word-wrap":"break-word"},attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.confirmMessage.trim())+" ")]):_vm._e()],1),_c('div',{staticClass:"pt-3"},[_c('b-form-group',[_c('label',[_vm._v("絞り込み:")]),_c('b-form-select',{staticClass:"m-md-2 col-3",attrs:{"options":_vm.sortOrderOptions,"variant":"outline-secondary"},on:{"change":function($event){return _vm.routerPush()}},model:{value:(_vm.sortOrderOption),callback:function ($$v) {_vm.sortOrderOption=$$v},expression:"sortOrderOption"}})],1)],1),_c('div',[_vm._v(" ※タグをクリックすると編集です。動画数ゼロのタグは編集画面の名前をカラにすると削除できます。タグはカテゴリと密接に関係する情報です。 ")]),_c('div',[_vm._v(" ※タグはアプリから閲覧できませんが、直接閲覧できると思ってください。「○○が出来ない人向け」ではなく「XXだけで出来る」などの言葉選択をしましょう。 ")]),_c('div',[_vm._v("※(動画数)が0になったものは早めに削除しましょう。")]),_c('div',{staticClass:"text-right p-3"},[_c('button',{staticClass:"btn btn-primary col-3",on:{"click":function($event){_vm.confirmMessage = null;
        _vm.newTag();
        _vm.$bvModal.show('form-modal');}}},[_vm._v(" ＋新規作成 ")])]),(_vm.videos)?_c('div',{staticClass:"row px-3"},_vm._l((this.tags),function(item,key){return _c('div',{key:key,staticClass:"col-4 col-md-2 p-1"},[_c('button',{staticClass:"btn btn-outline-secondary",on:{"click":function($event){return _vm.editTag(item)}}},[_vm._v(" "+_vm._s(item.name)+" ("+_vm._s(_vm.videos.filter((x) => x.tags && x.tags.some((g) => g.id == item.id)) .length)+") ")])])}),0):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }