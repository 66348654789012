import firebase from "firebase";
import "firebase/firestore";

export { getVideos };
async function getVideos() {
  const docs = await firebase
    .firestore()
    .collection("videos")
    .get();

  let datas = [];
  docs.forEach(function(doc) {
    let d = doc.data();
    d.ref = doc.ref;
    d.reference = doc.ref;
    d.id = doc.id;
    datas.push(d);
  });
  return datas;
}
