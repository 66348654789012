<template>
  <h1>作業ありがとうございました。</h1>
</template>

<script>
import firebase from "firebase/app";
import router from "../router";

export default {
  name: "SignOut",
  beforeCreate: () => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        //SignOutはなぜか/にリダイレクトする仕様
        firebase
          .auth()
          .signOut()
          .then(() => {
            router.go("/SignOut");
          })
          .catch(error => {
            console.error(error);
            alert("サインアウトに失敗しました。");
          });
      }
    });
  }
};
</script>