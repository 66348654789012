var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"border bg mx-auto",style:((_vm.category.published ?
       '' :
       'filter: grayscale(100%);') +
    'background-image: url(' +
    'https://storage.googleapis.com/' +
    _vm.bucket +
    '/' +
    _vm.category.thumbnailFileName +
    '?' +
    _vm.moment().valueOf() +
    ')')},[_c('div',{staticClass:"sample1oya"})])
}
var staticRenderFns = []

export { render, staticRenderFns }