<template>
  <div class="home">
    <div v-if="saved" class="alert alert-primary" role="alert">
      保存しました。
    </div>
    <div v-if="deleted" class="alert alert-danger" role="alert">
      削除しました。
    </div>
    <b-modal
      size="huge"
      id="form-modal"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      v-bind:title="
        !selectedCategory.id
          ? '新規作成'
          : '「' + selectedCategory.ja + '」を編集'
      "
      hide-footer
    >
      <b-form @submit.prevent="saveCategory">
        <b-row class="px-3 pt-3">
          <h5 class="col-12 text-left pb-0">サムネイルと基本情報</h5>
          <b-col cols="3" class="text-left pl-4">
            <draggableImage
              :value="selectedCategory.base64Image"
              @value="selectedCategory.base64Image = $event"
              :defaultImage="
                selectedCategory.thumbnailFileName
                  ? getStorageUrlFromFileName(
                      selectedCategory.thumbnailFileName
                    )
                  : '/img/camera-1-1.png'
              "
              :copyOriginUrl="selectedCategory.copyOriginUrl"
              sizingStyle="width:14vw; height:14vw"
              validSize="512,512"
            ></draggableImage>
            <b-row>
              <b-col cols="12" class="text-center" style="line-height: 1.1em">
                <h5 class="p-0 m-0">{{ selectedCategory.ja }}</h5>
                <small>※イメージ画像&nbsp;512x512 勝手にwebpになります</small
                ><br />
                <small>クリックもしくはドラッグドロップ</small><br />
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="9">
            <b-row class="pt-2">
              <b-col cols="2" class="text-right">
                <label class="pt-2">和名:</label>
              </b-col>
              <b-col cols="9">
                <b-form-input
                  type="text"
                  required="required"
                  v-model.trim="selectedCategory.ja"
                  placeholder="「柔道」 など短く端的に"
                  @keydown.self.prevent.enter
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row class="pt-2">
              <b-col cols="2" class="text-right">
                <label class="pt-2">特殊ID:</label>
              </b-col>
              <b-col cols="9" style="line-height: 1">
                <b-form-input
                  :disabled="selectedCategory.displayType != $categoryDisplayTypeCategories"
                  type="text"
                  class="pb-0"
                  v-model.trim="selectedCategory.specialId"
                  placeholder="英数字で短く"
                  @keydown.self.prevent.enter
                ></b-form-input>
                <br />
                <small class="align-top"
                  >アプリ開発者から指示が無い限り、カラのまま変更しないでください。<br />
                  メイン画面に出すような特別な「カテゴリーリスト」になります。</small
                >
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2" class="text-right">
                <label class="pt-2">タイプ:</label>
              </b-col>
              
              <b-col cols="9" class="text-left pt-3" style="line-height: 1">
                <b-form-radio-group
                  v-model.number="selectedCategory.displayType"
                >
                  <b-form-radio :value="$categoryDisplayTypeCategories">{{
                    getCategoryTypeName($categoryDisplayTypeCategories)
                  }}</b-form-radio
                  ><br />
                  <b-form-radio :value="$categoryDisplayTypeMovies">{{
                    getCategoryTypeName($categoryDisplayTypeMovies)
                  }}</b-form-radio
                  ><br />
                  <b-form-radio :value="$categoryDisplayTypeChapters"
                    >{{
                      getCategoryTypeName($categoryDisplayTypeChapters)
                    }}
                    (上級ユーザ向け)</b-form-radio
                  ><br />
                  <b-form-radio :value="$categoryDisplayTypeTrainers">{{
                    getCategoryTypeName($categoryDisplayTypeTrainers)
                  }}</b-form-radio>
                </b-form-radio-group>
                <br />
                <small class="align-top text-danger" v-if="selectedCategory.specialId"
                  >※特殊IDがあるときはカテゴリリストのみ有効です</small
                >
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <div
          v-if="selectedCategory.displayType == $categoryDisplayTypeCategories"
        >
          <b-row>
            <b-col cols="12 text-center">
              ※リストアップするカテゴリを設定します。<br />
              ※お手数ですが、カテゴリの編集をするたびに並び替えてください。
            </b-col>
          </b-row>
        </div>
        <div v-if="selectedCategory.displayType == $categoryDisplayTypeMovies">
          <b-row>
            <b-col cols="12 text-center">
              ※「タグのいずれかとパラメータ両方の条件を満たす動画」を絞り込みます。アンド検索です。
            </b-col>
          </b-row>
        </div>
        <div
          v-if="selectedCategory.displayType == $categoryDisplayTypeChapters"
        >
          <b-row>
            <b-col cols="12 text-center">
              ※「タグのいずれかとパラメータ両方の条件を満たす動画からパラメータの条件を満たすチャプター」
              を絞り込みます。アンド検索です。
            </b-col>
          </b-row>
        </div>
        <div
          v-if="selectedCategory.displayType == $categoryDisplayTypeTrainers"
        >
          <b-row>
            <b-col cols="12 text-center">
              ※チェックしたトレーナーたちから動画を陳列するためのカテゴリーです。
            </b-col>
          </b-row>
        </div>
        <div
          v-if="selectedCategory.displayType == $categoryDisplayTypeCategories"
        >
          <b-row class="px-3">
            <h5 class="col-12 text-left pb-0 mb-0">子カテゴリ</h5>
            <b-col cols="12" class="text-left pl-4">
              <b-form-checkbox-group
                v-model="selectedCategory.categories"
                :options="categories.filter((c) => c.id != selectedCategory.id)"
                value-field="id"
                text-field="ja"
              ></b-form-checkbox-group>
            </b-col>
          </b-row>
        </div>
        <div
          v-if="selectedCategory.displayType == $categoryDisplayTypeTrainers"
        >
          <div class="input-group text-center mt-4">
            <div class="mt-2 mr-2 col-3 text-right">トレーナー：</div>
            <div class="col-8 text-left">
              <b-form-checkbox-group
                @change="setFilteredVideosForEdit(selectedCategory)"
                v-model="selectedCategory.trainers"
                :options="trainers"
                value-field="id"
                text-field="titleAndName"
              ></b-form-checkbox-group>
            </div>
          </div>
        </div>
        <div
          v-else-if="
            selectedCategory.displayType != $categoryDisplayTypeCategories &&
            selectedCategory.displayType != $categoryDisplayTypeTrainers
          "
        >
          <b-row class="px-3">
            <h5 class="col-12 text-left pb-0 mb-0">タグ</h5>
            <b-col cols="12" class="text-left pl-4">
              <b-form-checkbox-group
                @change="
                  selectedCategory.displayType == $categoryDisplayTypeMovies
                    ? setFilteredVideosForEdit(selectedCategory)
                    : setFilteredChaptersForEdit(selectedCategory)
                "
                v-model="selectedCategory.tags"
                :options="tags"
                class="mb-3"
                value-field="id"
                text-field="name"
              ></b-form-checkbox-group>
            </b-col>
          </b-row>

          <b-row
            class="px-3"
            v-if="selectedCategory.displayType == $categoryDisplayTypeMovies"
          >
            <b-col>
              <h5 class="col-12 text-left pl-0 pb-2 mb-0">強度目安</h5>
              <b-row>
                <b-col cols="1"> </b-col>
                <b-col cols="9">
                  <vue-slider
                    size="sm"
                    v-model="selectedCategory.strengthRange"
                    :enable-cross="false"
                    :min="0"
                    :max="100"
                    @change="setFilteredVideosForEdit(selectedCategory)"
                    :lazy="true"
                  ></vue-slider>
                </b-col>
                <b-col cols="2" class="text-right pl-0 ml-0">
                  <div
                    v-if="
                      selectedCategory.strengthRange[0] == 0 &&
                      selectedCategory.strengthRange[1] == 100
                    "
                  >
                    全て
                  </div>
                  <div v-else>
                    <label size="sm" class="text-right" style="width: 39px">
                      {{ selectedCategory.strengthRange[0].toString() }}</label
                    >
                    -
                    <label size="sm" class="text-left" style="width: 39px">
                      {{ selectedCategory.strengthRange[1].toString() }}</label
                    >
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row
            class="px-3"
            v-if="selectedCategory.displayType == $categoryDisplayTypeMovies"
          >
            <h5 class="col-12 text-left pb-2 mb-0">
              パラメータ&nbsp;(動画のチャプタの合計値)
            </h5>
            <b-col
              cols="6"
              class="p-0"
              v-for="(item, key) in paramsDefinitions"
              :key="key"
            >
              <b-row>
                <b-col cols="3" class="text-right">
                  <label size="sm">{{ item.ja }}:</label>
                </b-col>
                <b-col cols="7">
                  <vue-slider
                    size="sm"
                    v-model="
                      selectedCategory.paramRangesForInputForMovie[item.id]
                    "
                    :enable-cross="false"
                    :min="0"
                    :max="paramMovieMax"
                    @change="setFilteredVideosForEdit(selectedCategory)"
                    :lazy="true"
                    :key="key + 'mv'"
                  ></vue-slider>
                </b-col>
                <b-col cols="2" class="text-right pl-0 ml-0">
                  <div
                    v-if="
                      selectedCategory.paramRangesForInputForMovie[
                        item.id
                      ][0] == 0 &&
                      selectedCategory.paramRangesForInputForMovie[
                        item.id
                      ][1] == paramMovieMax
                    "
                  >
                    全て
                  </div>
                  <div v-else>
                    <label size="sm" class="text-right" style="width: 39px">
                      {{
                        selectedCategory.paramRangesForInputForMovie[
                          item.id
                        ][0].toString()
                      }}</label
                    >
                    -
                    <label size="sm" class="text-right" style="width: 39px">
                      {{
                        selectedCategory.paramRangesForInputForMovie[
                          item.id
                        ][1].toString()
                      }}</label
                    >
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row
            class="px-3"
            v-if="selectedCategory.displayType == $categoryDisplayTypeChapters"
          >
            <h5 class="col-12 text-left pb-0 mb-0">パラメータ</h5>
            <b-col
              cols="6"
              class="p-0"
              v-for="(item, key) in paramsDefinitions"
              :key="key + 'ch'"
            >
              <b-row>
                <b-col cols="3" class="text-right">
                  <label size="sm">{{ item.ja }}:</label>
                </b-col>
                <b-col cols="7">
                  <vue-slider
                    size="sm"
                    v-model="
                      selectedCategory.paramRangesForInputForChapter[item.id]
                    "
                    :enable-cross="false"
                    :min="0"
                    :max="paramChapterMax"
                    @change="setFilteredChaptersForEdit(selectedCategory)"
                    :lazy="true"
                  ></vue-slider>
                </b-col>
                <b-col cols="2" class="text-right pl-0 ml-0">
                  <div
                    v-if="
                      selectedCategory.paramRangesForInputForChapter[
                        item.id
                      ][0] == 0 &&
                      selectedCategory.paramRangesForInputForChapter[
                        item.id
                      ][1] == paramChapterMax
                    "
                  >
                    全て
                  </div>
                  <div v-else>
                    <label size="sm" class="text-right" style="width: 30px">
                      {{
                        selectedCategory.paramRangesForInputForChapter[
                          item.id
                        ][0].toString()
                      }}</label
                    >
                    -
                    <label size="sm" class="text-right" style="width: 27px">
                      {{
                        selectedCategory.paramRangesForInputForChapter[
                          item.id
                        ][1].toString()
                      }}</label
                    >
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
        <hr />
        <b-row>
          <b-col cols="12" class="input-group pb-3">
            <b-form-checkbox
              v-model="selectedCategory.published"
              class="offset-3 col-2 py-0"
              size="lg"
              ><small>公開</small></b-form-checkbox
            >
            <button class="btn btn-sm btn-primary offset-1 col-2" type="submit">
              保存
            </button>
            <a
              class="btn btn-sm btn-outline-secondary offset-1 col-2"
              @click="$bvModal.hide('form-modal')"
            >
              キャンセル
            </a>
          </b-col>
        </b-row>
      </b-form>
      <div
        v-if="selectedCategory.displayType != $categoryDisplayTypeCategories"
      >
        <hr />
        <categoryPreview
          :category="selectedCategory"
          :filteredTrainers="getFilteredTrainers(selectedCategory.trainers)"
          :filteredVideos="filteredVideosForEdit"
          :filteredChapters="setFilteredChaptersForEdit"
          :bucket="bucket"
        ></categoryPreview>
      </div>
      <hr />
    </b-modal>
    <b-modal
      v-if="displayedCategory"
      size="xl"
      id="sort-modal"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      hide-footer
      centered
    >
      <template #modal-title> ドラッグアンドドロップで並べ替え </template>
      <div class="d-block text-center">
        <draggable
          tag="ul"
          style="list-style-type: none"
          v-model="displayedCategory.categories"
        >
          <li
            v-for="(category, key) in displayedCategory.categories"
            :key="key"
            style="cursor: pointer; padding: 10px; border: solid #ddd 1px"
            class="rounded m-2"
            :id="category.id"
          >
            <b-img
              style="width: 45px"
              :src="
                'https://storage.googleapis.com/' +
                bucket +
                '/' +
                categories.find((c) => c.id == category.id).thumbnailFileName +
                '?' +
                moment().valueOf()
              "
            ></b-img>
            {{ categories.find((c) => c.id == category.id).ja }}
          </li>
        </draggable>
      </div>
      <b-row align-h="end" class="p-4">
        <a class="btn btn-sm btn-primary col-2" @click="saveSort()">保存</a>
        <a
          class="btn btn-sm btn-outline-secondary offset-1 col-2"
          @click="$bvModal.hide('sort-modal')"
        >
          キャンセル
        </a>
      </b-row>
      <b-row class="pl-3" align-h="end">
        <p>※即座に反映されます。</p>
      </b-row>
    </b-modal>
    <b-row v-if="displayedCategory">
      <b-col cols="12">
        <hr />
        <b-link @click="categoryLink()">カテゴリトップへ</b-link>
        <hr />
      </b-col>
      <b-col cols="3" offset="3">
        <categoryCard :category="displayedCategory"></categoryCard>
      </b-col>
      <b-col cols="3" class="text-left pt-4 pl-3">
        <h5 @click="categoryLink(category)">{{ displayedCategory.ja }}</h5>
        <div></div>
        <div class="pt-3">
          <b-button
            variant="primary"
            size="sm"
            @click="editCategory(displayedCategory)"
          >
            編集
          </b-button>
          <b-button
            :disabled="displayedCategory.published"
            variant="danger"
            class="ml-4"
            size="sm"
            @click.stop.prevent="onDelete(displayedCategory)"
          >
            削除
          </b-button>
        </div>

        <div class="pt-1">
          <b-button
            variant="secondary"
            size="sm"
            @click="newCategoryFrom(displayedCategory)"
          >
            コピーから作成
          </b-button>
        </div>
      </b-col>
    </b-row>
    <div>
      <hr />
      <span v-if="displayedCategory">以下{{ displayedCategory.ja }}の内容</span>

      <div
        class="pt-1"
        v-if="
          displayedCategory &&
          displayedCategory.displayType == this.$categoryDisplayTypeCategories
        "
      >
        <b-button
          variant="info"
          size="sm"
          @click="
            confirmMessage = null;
            $bvModal.show('sort-modal');
          "
        >
          並べ替え
        </b-button>
      </div>
      <div
        class="pt-1"
        v-if="
          displayedCategory &&
          displayedCategory.displayType == this.$categoryDisplayTypeCategories
        "
      >
        <small
          >※お手数ですが、カテゴリの編集をするたびに並び替えてください</small
        >
      </div>
      <b-form-checkbox
        v-if="
          !displayedCategory ||
          displayedCategory.displayType == this.$categoryDisplayTypeCategories
        "
        v-model="displaySimulated"
        class="pb-3"
        switch
        size="md"
        >公開のものだけを表示(少しだけアプリで見るとき感が出ます)</b-form-checkbox
      >
      <div v-if="!displayedCategory" class="pt-1">
        <b-button variant="info" size="sm" @click="newCategory()">
          +カテゴリ追加
        </b-button>
        <br />
        <small
          >※画像をタップすると詳細を見れます。削除についてはそちらから、非公開の時のみ可能です。</small
        >
        <br />

        <small>※対応ブラウザはChrome/Edgeのみです。</small>
      </div>
      <hr />
    </div>
    <b-row
      class="mx-auto"
      v-if="
        !displayedCategory ||
        displayedCategory.displayType == $categoryDisplayTypeCategories
      "
    >
      <div v-for="(category, key) in getCurrentCategories" :key="key">
        <b-col
          v-if="
            !displaySimulated || category.published
          "
        >
          <div @click="categoryLink(category)" style="cursor: pointer">
            <categoryCard :category="category"></categoryCard>
            <b-link
              :href="'Category?id=' + category.id"
              style="cursor: pointer"
            >
              {{ category.published ? "【公開】" : "【非公開】" }}
              {{ category.ja }}
              {{ category.specialId ? "【特殊 " + category.specialId + "】" : "" }}</b-link
            >
            <br /><small
              >({{ getCategoryTypeName(category.displayType) }})</small
            >
          </div>
          <div class="pt-1">
            <b-button
              variant="primary"
              size="sm"
              class="mr-2"
              @click="editCategory(category)"
            >
              編集
            </b-button>
            <b-button
              variant="secondary"
              size="sm"
              @click="newCategoryFrom(category)"
            >
              コピーから作成
            </b-button>
          </div>
        </b-col>
      </div>
    </b-row>
    <div v-else-if="displayedCategory">
      <categoryPreview
        :category="displayedCategory"
        :filteredTrainers="getFilteredTrainers(displayedCategory.trainers)"
        :filteredVideos="filteredVideos"
        :filteredChapters="filteredChapters"
        :bucket="bucket"
      ></categoryPreview>
    </div>
  </div>
</template>

<script>
// min maxパラメータは省略される。最大値が更新されたときに追従できるため。

import firebase from "firebase";
import "firebase/firestore";
import "firebase/storage";

import Vue from "vue";
import VueHolder from "vue-holderjs";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

import draggable from "vuedraggable";

import { getParamsDefinitions } from "../getParamsDefinitions";
import { getVideos } from "../getVideos";
import { getTags } from "../getTags";
import { getCategories } from "../getCategories";
import { getTrainers } from "../getTrainers";
import draggableImage from "./DraggableImage";
import categoryCard from "./CategoryCard";
import categoryPreview from "./CategoryPreview";
import moment from "moment";
Vue.use(VueHolder);

export default {
  name: "Category",
  components: {
    draggable: draggable,
    draggableImage: draggableImage,
    categoryCard: categoryCard,
    categoryPreview: categoryPreview,
    VueSlider: VueSlider
  },
  data: function() {
    return {
      displaySimulated: false,
      displayedCategory: null,
      bucket: firebase.storage().ref().bucket,
      categoryId: null,
      selectedCategory: {},
      videos: [],
      tags: [],
      specialId: [],
      trainers: [],
      errors: [],
      confirmMessage: null,
      dragging: false,
      paramsDefinitions: [],
      categories: [],
      filteredVideos: [],
      filteredChapters: [],

      filteredVideosForEdit: [],
      filteredChaptersForEdit: [],

      thumbnailFileProgressMessage: null,
      saved: false,
      deleted: false,
      min: 0,
      paramMovieMax: 3000,
      paramChapterMax: 100,
      moment: moment
    };
  },
  mounted: async function() {
    this.trainers = await getTrainers();
    this.getFromUrlParams();
    this.paramsDefinitions = await getParamsDefinitions();
    this.tags = await getTags();
    this.videos = await getVideos();
    this.refreshState();
  },
  computed: {
    getCurrentCategories: function() {
      if (this.displayedCategory) {
        if (
          this.displayedCategory.displayType !=
          this.$categoryDisplayTypeCategories
        ) {
          return [];
        }
        return this.displayedCategory.categories.map(g =>
          this.categories.find(c => c.id == g.id)
        );
      } else {
        return this.categories;
      }
    },
    getCategoriesTypeCategories: function() {
      return this.categories.filter(
        c => c.displayType == this.$categoryDisplayTypeCategories
      );
    },
  },
  methods: {
    getStorageUrlFromFileName: function(fileName) {
      return (
        "https://storage.googleapis.com/" +
        this.bucket +
        "/" +
        fileName +
        "?" +
        moment().valueOf()
      );
    },
    setFilteredVideosForEdit: function(category) {
      this.filteredVideosForEdit = this.getFilteredVideos(
        category.tags,
        category.paramRangesForInputForMovie,
        category.trainers,
        category.strengthRange
      );
    },
    setFilteredChaptersForEdit: function(category) {
      this.filteredChaptersForEdit = this.getFilteredChapters(
        category.tags,
        category.paramRangesForInputForChapter
      );
    },
    setFilteredVideosAndChapters: function(category) {
      if (category.displayType == this.$categoryDisplayTypeMovies) {
        this.filteredVideos = this.getFilteredVideos(
          category.tags,
          category.paramRanges,
          null,
          category.strengthRange
        );
      }
      if (category.displayType == this.$categoryDisplayTypeTrainers) {
        this.filteredVideos = this.getFilteredVideos(
          category.tags,
          category.paramRanges,
          category.trainers,
          category.strengthRange
        );
      }
      if (category.displayType == this.$categoryDisplayTypeChapters) {
        this.filteredChapters = this.getFilteredChapters(
          category.tags,
          category.paramRanges
        );
      }
    },
    getFilteredVideos: function(tags, paramRanges, trainers, strengthRange) {
      let videos = [];
      let res = [];
      let resStrength = [];
      let self = this;
      self.videos.forEach(v => {
        if (tags && v.tags) {
          v.tags.some(t => {
            tags.some(any => {
              if (typeof any === "object") {
                if (any.id == t.id) {
                  videos.push(v);
                  return true;
                }
              } else {
                if (any == t.id) {
                  videos.push(v);
                  return true;
                }
              }
            });
            return videos.includes(v);
          });
        }
        if (trainers && v.trainers) {
          v.trainers.some(t => {
            trainers.some(any => {
              if (typeof any === "object") {
                if (any.id == t.id) {
                  videos.push(v);
                  return true;
                }
              } else {
                if (any == t.id) {
                  videos.push(v);
                  return true;
                }
              }
            });
            return videos.includes(v);
          });
        }
      });
      if (paramRanges) {
        videos.forEach(v => {
          let ok = true;
          Object.keys(paramRanges).forEach(key => {
            if (Array.isArray(paramRanges[key])) {
              if (
                paramRanges[key][0] > v.params[key] ||
                paramRanges[key][1] < v.params[key]
              ) {
                ok = false;
              }
            } else {
              if (
                paramRanges[key] &&
                (paramRanges[key].min > v.params[key] ||
                  paramRanges[key].max < v.params[key])
              ) {
                ok = false;
              }
            }
          });
          if (ok) {
            res.push(v);
          }
        });
      } else {
        res = videos;
      }
      if (!strengthRange || (strengthRange[0] == 0 && strengthRange[1] == 100)) {
        return res;
      }
      res.forEach(v => {
        if (!(strengthRange[0] > v.strength || strengthRange[1] < v.strength)) {
          resStrength.push(v);
        }
      });
      return resStrength;
    },
    getFilteredTrainers: function(catTrainers) {
      let res = [];
      let trainers = catTrainers;
      if (catTrainers) {
        if (catTrainers[0] && catTrainers[0].id) {
          trainers = catTrainers.map(g => g.id);
        }

        let self = this;
        self.trainers.forEach(t => {
          if (trainers.includes(t.id)) {
            res.push(t);
          }
        });
      }
      return res;
    },
    getFilteredChapters: function(tags, paramRanges) {
      let videos = this.getFilteredVideos(tags);
      let res = [];
      videos.forEach(v => {
        if (v.chapters) {
          v.chapters.forEach(chapter => {
            let ok = true;
            if (Object.keys(paramRanges).length > 0) {
              Object.keys(paramRanges).forEach(key => {
                if (Array.isArray(paramRanges[key])) {
                  if (
                    paramRanges[key][0] > chapter.params[key] ||
                    paramRanges[key][1] < chapter.params[key]
                  ) {
                    ok = false;
                  }
                } else {
                  if (
                    paramRanges[key] &&
                    (paramRanges[key].min > chapter.params[key] ||
                      paramRanges[key].max < chapter.params[key])
                  ) {
                    ok = false;
                  }
                }
              });
            }

            if (ok) {
              res.push({ video: v, chapter: chapter });
            }
          });
        }
      });
      return res;
    },
    getCategoryTypeName: function(typeId) {
      if (typeId == this.$categoryDisplayTypeMovies) {
        return "動画リスト";
      } else if (typeId == this.$categoryDisplayTypeChapters) {
        return "チャプターリスト";
      } else if (typeId == this.$categoryDisplayTypeTrainers) {
        return "トレーナーリスト";
      }
      return "カテゴリリスト";
    },
    newCategory: function() {
      this.saved = false;
      this.deleted = false;
      this.filteredVideosForEdit = [];
      this.selectedCategory = {
        published: false,
        displayType: this.$categoryDisplayTypeCategories,
        categories: [],
        tags: [],
        trainers: [],
        paramRanges: [],
        strengthRange: [0, 100],
        paramRangesForInputForChapter: [],
        paramRangesForInputForMovie: []
      };
      // 無いパラメータを埋める
      this.paramsDefinitions.forEach(param => {
        this.selectedCategory.paramRangesForInputForChapter[param.id] = [
          0,
          this.paramChapterMax
        ];
        this.selectedCategory.paramRangesForInputForMovie[param.id] = [
          0,
          this.paramMovieMax
        ];
      });
      this.$bvModal.show("form-modal");
    },
    getBase64: function(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    newCategoryFrom: function(category) {
      this.editCategory(category);
      this.selectedCategory.copyOriginUrl = this.getStorageUrlFromFileName(
        this.selectedCategory.thumbnailFileName
      );
      delete this.selectedCategory.thumbnailFileName;
      delete this.selectedCategory.id;
      this.selectedCategory.ja += "(コピー)";
      /*
       */
    },
    onDelete: async function(category) {
      this.saved = false;
      this.deleted = false;
      let data = this;
      let result = await this.$bvModal.msgBoxConfirm(
        "「" +
          category.ja +
          "」を削除します。この操作はやり直せません。削除しますか？"
      );
      if (!result) {
        return;
      }
      const storageRef = firebase.storage().ref();
      try {
        await storageRef.child(category.thumbnailFileName).delete();
      } catch (error) {
        alert("削除エラー: " + error);
      }
      firebase
        .firestore()
        .collection("categories")
        .doc(category.id)
        .delete()
        .then(function() {
          data.deleted = true;
          data.categoryId = null;
          data.$router.push({ path: "" });
          data.$bvModal.msgBoxConfirm("削除しました。");
        })
        .catch(function(error) {
          alert("削除エラー: " + error);
        });
    },
    editCategory: function(category) {
      this.saved = false;
      this.deleted = false;

      this.filteredVideosForEdit = [];

      const tags = [];
      if (category.tags) {
        category.tags.forEach(t => {
          tags.push(t.id);
        });
      }

      const trainers = [];
      if (category.trainers) {
        category.trainers.forEach(t => {
          trainers.push(t.id);
        });
      }

      let paramRanges = category.paramRanges ? category.paramRanges : {};

      const maxValue =
        category.displayType == this.$categoryDisplayTypeChapters
          ? this.paramChapterMax
          : this.paramMovieMax;

      // 無いパラメータを埋める
      this.paramsDefinitions.forEach(param => {
        if (!paramRanges[param.id]) {
          paramRanges[param.id] = { min: 0, max: maxValue };
        }
      });

      const paramRangesForInputForMovie = {};
      Object.keys(paramRanges).forEach(key => {
        paramRangesForInputForMovie[key] =
          category.displayType == this.$categoryDisplayTypeMovies
            ? [paramRanges[key].min, paramRanges[key].max]
            : [0, this.paramMovieMax];
      });

      const paramRangesForInputForChapter = {};
      Object.keys(paramRanges).forEach(key => {
        paramRangesForInputForChapter[key] =
          category.displayType == this.$categoryDisplayTypeChapters
            ? [paramRanges[key].min, paramRanges[key].max]
            : [0, this.paramChapterMax];
      });

      const strengthRange = [0, 100];
      if (category.strengthRange) {
        strengthRange[0] = category.strengthRange.min;
        strengthRange[1] = category.strengthRange.max;
      }
      this.selectedCategory = {
        id: category.id,
        ja: category.ja,
        specialId: category.specialId,
        categories: category.categories
          ? category.categories.map(g => g.id)
          : [],
        tags: tags,
        trainers: trainers,
        paramRanges: paramRanges,
        paramRangesForInputForChapter: paramRangesForInputForChapter,
        paramRangesForInputForMovie: paramRangesForInputForMovie,
        strengthRange: strengthRange,
        displayType: category.displayType,
        thumbnailFileName: category.thumbnailFileName,
        published: category.published ? true : false
      };
      if (
        category.displayType == this.$categoryDisplayTypeMovies ||
        category.displayType == this.$categoryDisplayTypeTrainers
      ) {
        this.setFilteredVideosForEdit(this.selectedCategory);
      }
      if (category.displayType == this.$categoryDisplayTypeChapters) {
        this.setFilteredChaptersForEdit(this.selectedCategory);
      }
      this.$bvModal.show("form-modal");
    },

    saveCategory: async function() {
      if (
        !this.selectedCategory.thumbnailFileName &&
        !this.selectedCategory.base64Image
      ) {
        alert("イメージ画像は必須です。");
        return;
      }
      let category = {
        ja: this.selectedCategory.ja,
        specialId: this.selectedCategory.specialId
          ? this.selectedCategory.specialId
          : null,
        displayType: this.selectedCategory.displayType,
        published: this.selectedCategory.published,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      };

      if (this.selectedCategory.thumbnailFileName) {
        category.thumbnailFileName = this.selectedCategory.thumbnailFileName;
      } else {
        category.thumbnailFileName = "categories/" + this.getHash(64);
      }

      if (this.selectedCategory.id) {
        category.tags = firebase.firestore.FieldValue.delete();
        category.paramRanges = firebase.firestore.FieldValue.delete();
        category.strengthRange = firebase.firestore.FieldValue.delete();
      } else {
        category.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      }

      let paramRanges = {};
      let strengthRange = null;
      if (category.displayType == this.$categoryDisplayTypeCategories) {
        category.categories = this.getReferences(
          this.categories,
          this.selectedCategory.categories
        );
      } else {
        category.specialId = null;
        category.paramRanges = {};
        category.tags = this.getReferences(
          this.tags,
          this.selectedCategory.tags
        );
        category.trainers = this.getReferences(
          this.trainers,
          this.selectedCategory.trainers
        );
        if (category.displayType == this.$categoryDisplayTypeMovies) {
          this.paramsDefinitions.forEach(param => {
            const min = parseInt(
              this.selectedCategory.paramRangesForInputForMovie[param.id][0],
              10
            );
            const max = parseInt(
              this.selectedCategory.paramRangesForInputForMovie[param.id][1],
              10
            );
            if (min != 0 || max != this.paramMovieMax) {
              paramRanges[param.id] = {};
              paramRanges[param.id].min = min;
              paramRanges[param.id].max = max;
            }
          });
          if (
            this.selectedCategory.strengthRange[0] != 0 ||
            this.selectedCategory.strengthRange[1] != 100
          ) {
            strengthRange = {
              min: this.selectedCategory.strengthRange[0],
              max: this.selectedCategory.strengthRange[1]
            };
          }
        }
        if (category.displayType == this.$categoryDisplayTypeChapters) {
          this.paramsDefinitions.forEach(param => {
            const min = parseInt(
              this.selectedCategory.paramRangesForInputForChapter[param.id][0],
              10
            );
            const max = parseInt(
              this.selectedCategory.paramRangesForInputForChapter[param.id][1],
              10
            );
            if (min != 0 || max != this.paramChapterMax) {
              paramRanges[param.id] = {};
              paramRanges[param.id].min = min;
              paramRanges[param.id].max = max;
            }
          });
        }
      }
      if (Object.keys(paramRanges).length) {
        category.paramRanges = paramRanges;
      }

      if (strengthRange) {
        category.strengthRange = strengthRange;
      }
      if (this.selectedCategory.base64Image) {
        const storageRef = firebase.storage().ref();
        try {
          await storageRef
            .child(category.thumbnailFileName)
            .putString(this.selectedCategory.base64Image, "data_url");
        } catch (err) {
          alert(
            "クラウドストレージへのファイルアップロードに失敗しました。" + err
          );
          return;
        }
      }
      try {
        console.log(category);
        if (this.selectedCategory.id) {
          await firebase
            .firestore()
            .collection("categories")
            .doc(this.selectedCategory.id)
            .update(category);
          this.refreshState();
          this.saved = true;
          this.$bvModal.hide("form-modal");
        } else {
          await firebase
            .firestore()
            .collection("categories")
            .add(category);
          if (this.displayedCategory) {
            this.categoryLink();
          } else {
            this.refreshState();
          }

          setTimeout(() => {
            this.saved = true;
          }, 500);
          this.$bvModal.hide("form-modal");
        }
      } catch (err) {
        alert("DBへの保存に失敗しました。" + err);
        return;
      }
    },
    getCategoryTags: function(category) {
      let res = [];
      let self = this;
      if (!category.tags) {
        return [];
      }

      self.tags.forEach(tag => {
        category.tags.some(mt => {
          if (mt.id == tag.id) {
            res.push(tag);
            return true;
          }
        });
      });
      return res;
    },
    getFromUrlParams: function() {
      this.categoryId = null;
      if (this.$route.query["id"]) {
        this.categoryId = this.$route.query["id"];
      }
    },
    categoryLink: function(category) {
      if (!category) {
        this.categoryId = null;
        this.$router.push({ path: "" });
        return;
      }
      if (category.id == this.categoryId) {
        return;
      }
      this.categoryId = category.id;
      let pushRouteParam = { id: category.id };
      this.$router.push({ path: "", query: pushRouteParam });
    },
    refreshState: function() {
      this.saved = false;
      this.deleted = false;
      this.thumbnailFileProgressMessage = null;
      this.getFromUrlParams();
      getCategories().then(val => {
        this.categories = val;
        if (this.categoryId) {
          this.displayedCategory = this.categories.find(
            c => c.id == this.categoryId
          );

          this.setFilteredVideosAndChapters(this.displayedCategory);
        } else {
          this.displayedCategory = null;
        }
      });
    },
    saveSort: async function() {
      try {
        await firebase
          .firestore()
          .collection("categories")
          .doc(this.displayedCategory.id)
          .update({ categories: this.displayedCategory.categories });
        this.$bvModal.hide("sort-modal");
        this.refreshState();
      } catch (err) {
        alert("保存しなおしてください。エラーが発生しました:" + err);
      }
    }
  },
  watch: {
    $route() {
      this.refreshState();
    }
  }
};
</script>
