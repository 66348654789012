<template>
  <div class="home">
    <b-modal
      size="sm"
      cancel-only
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      id="upload-files"
      title="アップロード中です"
      hide-footer
    >
    </b-modal>
    <div v-if="saved" class="alert alert-primary" role="alert">
      保存しました。
    </div>
    <div v-if="deleted" class="alert alert-danger" role="alert">
      削除しました。
    </div>
    <b-modal
      size="xl"
      id="form-modal"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      v-bind:title="
        !selectedEvent.id ? '新規作成' : '「' + selectedEvent.title + '」を編集'
      "
      hide-footer
    >
      <div v-if="thumbnailFileProgressMessage || iconFileProgressMessage">
        <p>
          イメージ画像：{{
            thumbnailFileProgressMessage
              ? thumbnailFileProgressMessage
              : "変更なし"
          }}
        </p>
        <p>
          アイコン:{{
            iconFileProgressMessage ? iconProgressMessage : "変更なし"
          }}
        </p>
        <div class="text-right">
          <button
            class="btn btn-sm btn-outline-secondary col-5"
            @click="cancelSaving()"
          >
            キャンセル
          </button>
        </div>
      </div>
      <div v-else>
        <b-form @submit.prevent="saveEvent">
          <b-form-group class="form-group">
            <div class="input-group text-center">
              <div class="mr-2 col-3 text-right">タイトル</div>
              <input
                type="text"
                class="form-control col-9 mt-1"
                required="required"
                v-model.trim="selectedEvent.title"
                placeholder="タイトル"
                @keydown.self.prevent.enter
              />
            </div>
            <div class="input-group text-center mt-4">
              <div class="mr-2 col-3 text-right">説明:</div>
              <b-form-textarea
                id="textarea"
                required="required"
                v-model.trim="selectedEvent.description"
                placeholder="説明"
                rows="3"
                max-rows="6"
                class="col-9 mt-1"
              ></b-form-textarea>
            </div>
            <b-row class="align-items-center py-4">
              <div class="col-3 text-right">期間：</div>
              <div class="col-3">
                <datetime
                  required="required"
                  v-model="selectedEvent.startAt"
                  type="date"
                >
                </datetime>
              </div>
              <div class="col-1">～</div>
              <div class="col-3">
                <datetime
                  required="required"
                  v-model="selectedEvent.endAt"
                  type="date"
                >
                </datetime>
              </div>
            </b-row>

            <div
              v-if="selectedEvent.thumbnailFileName"
              class="col-12 text-center mt-4 text-center"
            >
              <strong
                >以下はイメージを差し替えるときのみアップロードしてください。</strong
              >
            </div>

            <div class="input-group text-center mt-4">
              <div class="col-3 text-right mt-2">アイコン画像：</div>
              <div class="col-9 px-2">
                <b-form-file
                  v-model="selectedEvent.iconFile"
                  :state="Boolean(selectedEvent.iconFile)"
                  accept=".webp, .jpg, .jpeg, .png, .gif"
                  placeholder="webp,jpeg,png,gifを選択かドロップ"
                  drop-placeholder="Drop file here..."
                  :required="selectedEvent.id == ''"
                ></b-form-file>
                ※256x256px以上の1:1。背景は透明も可。表示は丸型になります。
              </div>
            </div>
            <div class="input-group text-center mt-4">
              <div class="col-3 text-right mt-2">イメージ画像：</div>
              <div class="col-9 px-2">
                <b-form-file
                  v-model="selectedEvent.thumbnailFile"
                  :state="Boolean(selectedEvent.thumbnailFile)"
                  accept=".webp, .jpg, .jpeg, .png, .gif"
                  placeholder="webp,jpeg,png,gifを選択かドロップ"
                  drop-placeholder="Drop file here..."
                  :required="selectedEvent.id == ''"
                ></b-form-file>
                ※768x432pxのみ。splitエラーが出たときはwebページを更新してください。
              </div>
            </div>
          </b-form-group>

          <div class="input-group row mt-2 mb-3">
            <button class="btn btn-sm btn-primary offset-7 col-2" type="submit">
              保存
            </button>
            <a
              class="btn btn-sm btn-outline-secondary offset-1 col-2"
              @click="$bvModal.hide('form-modal')"
            >
              キャンセル
            </a>
          </div>
        </b-form>
        <div
          v-if="confirmMessage"
          class="alert alert-info"
          role="alert"
          style="white-space: pre-wrap; word-wrap: break-word"
        >
          {{ confirmMessage.trim() }}
        </div>
      </div>
    </b-modal>
    <div class="pt-3">
      <b-form inline>
        <label class="ml-auto">絞り込み:</label>
        <b-form-select
          id="offset-option"
          :options="offsetOptions"
          v-model="offsetOption"
          class="m-md-2 col-2"
          variant="outline-secondary"
          @change="searchTimeOut()"
        >
        </b-form-select>
        <b-form-select
          :options="sortOrderOptions"
          v-model="sortOrderOption"
          class="m-md-2 col-3"
          variant="outline-secondary"
          @change="searchTimeOut(true)"
        >
        </b-form-select>
        <b-form-input
          type="text"
          class="m-md-2 col-3"
          placeholder="フリーワード(タイトル・説明)"
          variant="outline-secondary"
          v-model="keyword"
          @keyup="searchTimeOut()"
          @keydown.self.prevent.enter
        ></b-form-input
      ></b-form>
    </div>
    <div class="text-right p-3">
      <button
        class="btn btn-primary col-3"
        @click="
          confirmMessage = null;
          newEvent();
          $bvModal.show('form-modal');
        "
      >
        ＋新規作成
      </button>
    </div>
    <table class="table">
      <tr>
        <th class="align-middle text-center" style="width: 10%">編集</th>
        <th class="align-middle text-center" style="width: 20%">アイコン</th>
        <th class="align-middle text-center" style="width: 30%">イメージ</th>
        <th class="align-middle text-center" style="width: 40%">情報</th>
      </tr>
      <tr v-for="(item, key) in filteredEvents()" :key="key">
        <td class="align-middle text-center">
          <div class="pt-4">
            <button class="btn btn-secondary" @click="editEvent(item)">
              編集
            </button>
          </div>
          <div class="pt-3">
            <button class="btn btn-danger" @click.stop.prevent="onDelete(item)">
              削除
            </button>
          </div>
        </td>
        <td class="align-middle text-center">
          <b-img
            rounded="circle"
            v-bind:src="item.iconUrl.ja"
            style="max-height: 128px"
          />
        </td>
        <td class="align-middle text-center">
          <img v-bind:src="item.thumbnailUrl.ja" style="max-height: 200px" />
          <div>
            <small>イベントID：{{ item.id }}</small>
          </div>
        </td>
        <td class="align-middle">
          <small>{{ item.title.ja }}</small>
          <p style="white-space: pre-wrap">
            {{ item.description.ja }}
          </p>
          <div>
            期間：{{ formatDateDay(item.startAt) }}～{{
              formatDateDay(item.endAt)
            }}
          </div>
          <div>
            <small>更新日時：{{ formatDate(item.updatedAt) }}</small>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import firebase from "firebase";
import "firebase/firestore";
import "firebase/storage";

import moment from "moment";
import Vue from "vue";
import VueHolder from "vue-holderjs";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
Vue.use(VueHolder);

export default {
  name: "Event",
  components: {
    datetime: Datetime,
  },
  data: function () {
    return {
      event: {
        id: "",
        name: "",
        description: "",
        thumbnailFileName: null,
        thumbnailUrl: null,
        thumbnailFile: null,
        iconFileName: null,
        iconUrl: null,
        iconFile: null,
        startAt: moment(
          moment().add(7, "days").format("YYYY-MM-DD")
        ).toISOString(),
        endAt: moment(
          moment().add(14, "days").format("YYYY-MM-DD")
        ).toISOString(),
      },
      offsetOption: "10",
      offsetOptions: [
        { value: "10", text: "10件" },
        { value: "30", text: "30件" },
        { value: "50", text: "50件" },
      ],
      sortOrderOption: "startAt,desc",
      sortOrderOptions: [
        { value: "startAt,desc", text: "最新開始日時" },
        { value: "endAt,desc", text: "最新終了日時" },
        { value: "name,asc", text: "名前昇順" },
        { value: "name,desc", text: "名前降順" },
      ],
      selectedEvent: {},
      events: [],
      errors: [],
      saved: false,
      deleted: false,
      keyword: "",
      editFlg: false,
      confirmMessage: null,
      thumbnailFileProgressMessage: null,
      thumbnailUploadTask: null,
      iconFileProgressMessage: null,
      iconUploadTask: null,
      needReLoad: false,
    };
  },
  mounted: async function () {
    this.getFromUrlParams();
    this.getEvents();
  },
  methods: {
    isEmptyOrSpaces: function (str) {
      return !str || str.match(/^ *$/) !== null;
    },
    //event
    newEvent: function () {
      this.saved = false;
      this.deleted = false;
      this.selectedEvent = {
        startAt: moment(
          moment().add(7, "days").format("YYYY-MM-DD")
        ).toISOString(),
        endAt: moment(
          moment().add(14, "days").format("YYYY-MM-DD")
        ).toISOString(),
      };
    },
    editEvent: function (event) {
      this.saved = false;
      this.deleted = false;
      this.selectedEvent = {
        id: event.id,
        title: event.title.ja,
        description: event.description.ja,
        thumbnailUrl: event.thumbnailUrl.ja,
        thumbnailFileName: event.thumbnailFileName.ja,
        thumbnailFile: null,
        iconUrl: event.iconUrl.ja,
        iconFileName: event.iconFileName.ja,
        iconFile: null,
        startAt: event.startAt.toDate().toISOString(),
        endAt: event.endAt.toDate().toISOString(),
      };
      this.$bvModal.show("form-modal");
    },
    storeEvent: async function (event) {
      let data = this;
      return new Promise(function (resolve, reject) {
        if (data.selectedEvent.id) {
          firebase
            .firestore()
            .collection("events")
            .doc(data.selectedEvent.id)
            .update(event)
            .then(function () {
              data.saved = true;
              resolve();
            })
            .catch(function (error) {
              reject("保存でエラーが発生しました:" + error);
            });
        } else {
          firebase
            .firestore()
            .collection("events")
            .add(event)
            .then(function () {
              data.saved = true;
              resolve();
            })
            .catch(function (error) {
              reject("保存でエラーが発生しました:" + error);
            });
        }
      });
    },
    putStorageThumbnail: function (event) {
      let data = this;
      return new Promise(function (resolve, reject) {
        const storageRef = firebase.storage().ref();
        data.thumbnailUploadTask = storageRef
          .child(event.thumbnailFileName.ja)
          .put(
            data.selectedEvent.thumbnailFile,
            data.selectedEvent.thumbnailFile.type
          );

        data.thumbnailUploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED,
          function (snapshot) {
            data.thumbnailFileProgressMessage =
              (
                (snapshot.bytesTransferred / snapshot.totalBytes) *
                100
              ).toString() + "%";
          },
          function (error) {
            switch (error.code) {
              case "storage/canceled":
                break;
              default:
                alert(
                  "イメージアップロード中にクラウドストレージにおけるその他のエラーが発生しました。:" +
                    error.code
                );
                break;
            }
            reject();
          },
          function () {
            data.thumbnailUploadTask.snapshot.ref
              .getDownloadURL()
              .then(function (downloadURL) {
                resolve(downloadURL);
              });
          }
        );
      });
    },
    putStorageIcon: function (event) {
      let data = this;
      return new Promise(function (resolve, reject) {
        const storageRef = firebase.storage().ref();
        data.iconUploadTask = storageRef
          .child(event.iconFileName.ja)
          .put(data.selectedEvent.iconFile, data.selectedEvent.iconFile.type);

        data.iconUploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED,
          function (snapshot) {
            data.iconFileProgressMessage =
              (
                (snapshot.bytesTransferred / snapshot.totalBytes) *
                100
              ).toString() + "%";
          },
          function (error) {
            switch (error.code) {
              case "storage/canceled":
                break;
              default:
                alert(
                  "イメージアップロード中にクラウドストレージにおけるその他のエラーが発生しました。:" +
                    error.code
                );
                break;
            }
            reject();
          },
          function () {
            data.iconUploadTask.snapshot.ref
              .getDownloadURL()
              .then(function (downloadURL) {
                resolve(downloadURL);
              });
          }
        );
      });
    },
    saveEvent: async function () {
      this.thumbnailFileProgressMessage = null;
      this.iconlFileProgressMessage = null;

      if (this.selectedEvent.id) {
        this.updateEvent();
      } else {
        this.createEvent();
      }
    },
    createEvent: async function () {
      let data = this;
      let event = {
        description: { ja: data.selectedEvent.description },
        title: { ja: data.selectedEvent.title },
        thumbnailFileName: { ja: "events/" + data.getHash(64) },
        iconFileName: { ja: "events/" + data.getHash(64) },
        startAt: moment(data.selectedEvent.startAt).toDate(),
        endAt: moment(data.selectedEvent.endAt).toDate(),
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      };
      try {
        event.thumbnailUrl = { ja: await this.putStorageThumbnail(event) };
        event.iconUrl = { ja: await this.putStorageIcon(event) };
        this.thumbnailFileProgressMessage = null;
        this.iconFileProgressMessage = null;
        await this.storeEvent(event);
        this.$bvModal.hide("form-modal");
        this.getEvents();
      } catch (err) {
        this.thumbnailFileProgressMessage = null;
        this.iconFileProgressMessage = null;
        alert("エラーが発生しました:" + err);
      }
    },
    updateEvent: async function () {
      let data = this;
      let event = {
        description: { ja: data.selectedEvent.description },
        title: { ja: data.selectedEvent.title },
        thumbnailFileName: { ja: "events/" + data.getHash(64) },
        iconFileName: { ja: "events/" + data.getHash(64) },
        startAt: moment(data.selectedEvent.startAt).toDate(),
        endAt: moment(data.selectedEvent.endAt).toDate(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      };
      try {
        if (data.selectedEvent.thumbnailFile) {
          event.thumbnailUrl = { ja: await this.putStorageThumbnail(event) };
        }
        if (data.selectedEvent.iconFile) {
          event.iconUrl = { ja: await this.putStorageIcon(event) };
        }
        this.thumbnailFileProgressMessage = null;
        this.iconFileProgressMessage = null;
        await this.storeEvent(event);
        this.$bvModal.hide("form-modal");
        this.getEvents();
      } catch (err) {
        this.thumbnailFileProgressMessage = null;
        this.iconFileProgressMessage = null;
        alert("もう一度ご送信ください エラー:" + err);
      }
    },
    onDelete: function (event) {
      this.saved = false;
      this.deleted = false;
      let data = this;
      this.$bvModal
        .msgBoxConfirm(
          "「" +
            event.title.ja +
            "」を削除します。この操作はやり直せません。削除しますか？"
        )
        .then((result) => {
          if (!result) {
            return;
          }
          firebase
            .firestore()
            .collection("events")
            .doc(event.id)
            .delete()
            .then(function () {
              data.deleted = true;
              data.getEvents();
            })
            .catch(function (error) {
              console.error(error);
              alert("削除エラー: ", error);
            });
        });
    },
    onCreateTopic: function (event) {
      this.$router.push({ path: "/Topic", query: { event: event.id } });
    },
    cancelSaving: function () {
      if (this.thumbnailUploadTask != null) {
        this.thumbnailUploadTask.cancel();
      }
      if (this.iconUploadTask != null) {
        this.iconUploadTask.cancel();
      }
      this.$bvModal.hide("upload-files");
    },
    filteredEvents: function () {
      let data = this;
      if (!this.keyword) {
        return this.events.slice(0, Number(this.offsetOption));
      }
      const res = [];
      this.events.forEach(function (event) {
        if (
          event.title.ja.toLowerCase().includes(data.keyword.toLowerCase()) ||
          event.description.ja.toLowerCase().includes(data.keyword.toLowerCase())
        ) {
          res.push(event);
        }
      });
      return res.slice(0, Number(this.offsetOption));
    },
    getEvents: function () {
      let self = this;
      const ref = firebase.firestore().collection("events");
      const query = ref;
      query
        .orderBy(
          this.sortOrderOption.split(",")[0],
          this.sortOrderOption.split(",")[1]
        )
        .get()
        .then(function (querySnapshot) {
          let datas = [];
          querySnapshot.forEach(function (doc) {
            let d = doc.data();
            d.id = doc.id;
            datas.push(d);
          });
          self.events = datas;
        })
        .catch(function (error) {
          console.error("Error getting documents: ", error);
        });
    },
    getFromUrlParams: function () {
      if (this.$route.query["c"]) {
        this.offsetOption = this.$route.query["c"];
      }

      if (this.$route.query["o"]) {
        this.sortOrderOption = this.$route.query["o"];
      }

      if (this.$route.query["kw"]) {
        this.keyword = this.$route.query["kw"];
      }
    },
    routerPush() {
      let pushRouteParam = {
        c: this.offsetOption,
        o: this.sortOrderOption,
        kw: this.keyword,
      };
      this.$router.push({ path: "", query: pushRouteParam });
    },
    searchTimeOut(needReLoad) {
      this.needReLoad = needReLoad;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.routerPush();
      }, 1000);
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (this.needReLoad) {
      this.needReLoad = false;
      this.getEvents();
    }
    this.saved = false;
    this.deleted = false;
    next();
  },
};
</script>
