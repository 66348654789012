<template>
  <div id="app">
    <div>
      <b-navbar
        toggleable="sm"
        type="dark"
        variant="dark"
        v-if="currentUser() != null"
      >
        <b-navbar-brand href="#"
          ><img
            src="@/assets/logo.svg"
            width="32"
            height="32"
          />&nbsp;管理ページ&nbsp;{{
            $production ? "(本番)" : "(開発)"
          }}</b-navbar-brand
        >
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item to="/Video">動画</b-nav-item>
            <b-nav-item to="/Trainer">トレーナー</b-nav-item>
            <b-nav-item to="/Quest">クエスト</b-nav-item>
            <b-nav-item to="/ParamDefinition">動画パラメータ</b-nav-item>
            <b-nav-item to="/FirstTest">初回判定</b-nav-item>
            <b-nav-item to="/Tag">タグ</b-nav-item>
            <b-nav-item to="/Category">カテゴリ</b-nav-item>
            <b-nav-item to="/Topic">トピック</b-nav-item>
            <b-nav-item to="/Event">イベント</b-nav-item>
            <b-nav-item to="/Comment">コメント</b-nav-item>
            <b-nav-item to="/Achievement">実績</b-nav-item>
            <b-nav-item to="/Data">データダウンロード</b-nav-item>
          </b-navbar-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item to="/SignOut">Sign Out</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
      <div>
        ここで編集する全てのデータの「閲覧は誰でもできる」ことを常に忘れないでください。<br />
      </div>
    </div>
    <router-view />
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.modal-open {
  padding-right: 0px !important;
}

.btn-primary,
.btn-secondary,
.btn-danger {
  color: white !important;
}

.router-link-active {
  color: white !important;
}
</style>


<script>
import firebase from "firebase";
import "firebase/firestore";

export default {
  name: "App",
  methods: {
    currentUser: function() {
      return firebase.auth().currentUser;
    }
  }
};
</script>
