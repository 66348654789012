<template>
  <div class="home">
    <div v-if="saved" class="alert alert-primary" role="alert">
      保存しました。
    </div>
    <div v-if="deleted" class="alert alert-danger" role="alert">
      削除しました。
    </div>
    <b-modal
      size="huge"
      id="form-modal"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      v-bind:title="
        !selectedFirstTest.id
          ? '新規作成'
          : '「' + selectedFirstTest.subject.ja + '」を編集'
      "
      hide-footer
    >
      <b-form @submit.prevent="saveFirstTest">
        <b-row class="px-3 pt-3">
          <b-col cols="12">
            <b-row class="pt-2">
              <b-col cols="2" class="text-right">
                <label class="pt-2">タイトル:</label>
              </b-col>
              <b-col cols="6">
                <b-form-input
                  type="text"
                  required="required"
                  v-model.trim="selectedFirstTest.subject.ja"
                  placeholder="腕立て伏せなど"
                  @keydown.self.prevent.enter
                ></b-form-input>
              </b-col>
              <b-col cols="12"></b-col>
              <b-col cols="2" class="text-right">
                <label class="pt-2">説明:</label>
              </b-col>
              <b-col cols="6">
                <b-form-input
                  type="text"
                  required="required"
                  v-model.trim="selectedFirstTest.description.ja"
                  placeholder="あなたができる一番レベルが高い種目をお選びください"
                  @keydown.self.prevent.enter
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row class="pt-2">
              <b-col cols="12" class="text-left">
                <label class="pt-2">選択肢（2～6つ、順番は左上から右下。名前をカラにすると画像にかかわらず選択肢は削除されます）:</label>
              </b-col>
              <b-col cols="12">
                <b-row>
                  <b-col v-for="(n, i) of 6" :key="n" cols="6" class="px-4 border rounded p-1">
                    <b-row>
                      <b-col cols="3" class="text-right pt-2">Lv.{{i+1}} 名前：</b-col>
                      <b-col cols="9">
                        <b-form-input
                          type="text"
                          v-model.trim="selectedFirstTest.options[i].name.ja"
                          placeholder="ひざをたててなど"
                          @keydown.self.prevent.enter
                        ></b-form-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" class="text-center p-2" style="line-height: 1.1em;">
                        <small>※イメージ画像&nbsp;512x512</small
                        ><br />
                        <small>クリックもしくはドラッグドロップ</small><br />
                        <draggableImage
                        :value="selectedFirstTest.options[i].base64Image"
                        @value="selectedFirstTest.options[i].base64Image = $event"
                        :defaultImage="
                          selectedFirstTest.options[i].image
                            ? getStorageUrlFromFileName(
                                selectedFirstTest.options[i].image 
                              )
                            : '/img/camera-1-1.png'
                        "
                        :copyOriginUrl="selectedFirstTest.copyOriginUrl"
                        sizingStyle="width:14vw; height:14vw"
                        validSize="512,512"></draggableImage>
                      </b-col>
                    </b-row>
                    <b-row class="pl-3 pb-3 pr-3 pt-0">
                      <b-input-group
                        size="sm"
                        v-for="(param, paramKey) in paramsDefinitions"
                        :key="paramKey"
                        class="col-4 p-0"
                      >
                        <b-input-group-prepend>
                          <b-input-group-text class="text-right rounded-0">{{
                            param.ja.padStart(2, "\u3000")
                          }}</b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          v-model.number="selectedFirstTest.options[i].params[param.id]"
                          class="text-right rounded-0"
                          type="number"
                          min="0"
                          max="100"
                          step="1"
                          required
                        ></b-form-input>
                      </b-input-group>
                    </b-row>
                  </b-col>
                  <b-col cols="6">
                    <b-col cols="3" class="text-left pl-4">
                    </b-col>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col cols="12" class="input-group pb-3">
            <b-form-checkbox
              v-model="selectedFirstTest.published"
              class="offset-3 col-2 py-0"
              size="lg"
              ><small>公開</small></b-form-checkbox
            >
            <button class="btn btn-sm btn-primary offset-1 col-2" type="submit">
              保存
            </button>
            <a
              class="btn btn-sm btn-outline-secondary offset-1 col-2"
              @click="$bvModal.hide('form-modal')"
            >
              キャンセル
            </a>
          </b-col>
        </b-row>
      </b-form>
      <hr />
    </b-modal>
    <b-modal
      size="xl"
      id="sort-modal"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      hide-footer
      centered
    >
      <template #modal-title> ドラッグアンドドロップで並べ替え </template>
      <div class="d-block text-center">
        <draggable
          tag="ul"
          style="list-style-type: none"
          v-model="firstTests"
        >
          <li
            v-for="(firstTest, key) in firstTests"
            :key="key"
            style="cursor: pointer; padding: 10px; border: solid #ddd 1px"
            class="rounded m-2"
            :id="firstTest.id"
          >
            {{ firstTests.find((c) => c.id == firstTest.id).subject.ja }}
          </li>
        </draggable>
      </div>
      <b-row align-h="end" class="p-4">
        <a class="btn btn-sm btn-primary col-3" @click="saveSort()">保存</a>
        <a
          class="btn btn-sm btn-outline-secondary offset-1 col-3"
          @click="$bvModal.hide('sort-modal')"
        >
          キャンセル
        </a>
      </b-row>
      <b-row class="pl-3" align-h="end">
        <p>※即座に反映されます。</p>
      </b-row>
    </b-modal>
    <b-row  class="d-flex justify-content-center py-2">
      <b-button
        class="col-2"
        variant="primary" @click="createFirstTest()">
          追加
      </b-button>
      <b-col cols="2"></b-col>
      <b-button
        class="col-2 text-white"
        variant="info"
        @click="
          confirmMessage = null;
          $bvModal.show('sort-modal');
        "
      >
        質問の並べ替え
      </b-button>
    </b-row>
    <b-row class="mx-auto" style="max-width:1920px;">
      <b-col cols="6" v-for="(firstTest, key) in firstTests" :key="key">
        <b-card :header="'質問:' + (key+1).toString() + (firstTest.published ? '【公開】' : '【非公開】')  + firstTest.subject.ja"
          border-variant="secondary"
          class="text-center">
          <b-card-body class="text-center">
            <div>
              <small>{{firstTest.description.ja}}</small>
            </div>
            <b-row >
                <b-col cols="6" v-for="(option, key2) in firstTest.options" :key="key2" class="d-flex justify-content-center">
                  <b-card
                    :header="'Lv.' + (key2 + 1) + ' ' + option.name.ja"
                    style="background-color: #48bcc1; border-color:#48bcc1;"
                    header-text-variant="white"
                    body-bg-variant="white"
                    align="center"
                  >
                    <b-card-img-lazy :src="getStorageUrlFromFileName(option.image)"></b-card-img-lazy>
                  </b-card>
                </b-col>
            </b-row>
          </b-card-body>
            <b-button
              class="px-4 mr-4"
              variant="secondary"
              @click="editFirstTest(firstTest)"
            >
              編集
            </b-button>
            <b-button
              class="ml-4 px-4"
              :disabled="firstTest.published"
              variant="danger"
              @click="onDelete(firstTest)"
            >
              削除{{firstTest.published ? '（非公開時のみ）' : ""}}
            </b-button>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import firebase from "firebase";
import "firebase/firestore";
import "firebase/storage";

import Vue from "vue";
import VueHolder from "vue-holderjs";
import "vue-slider-component/theme/default.css";

import draggable from "vuedraggable";

import { getParamsDefinitions } from "../getParamsDefinitions";
import { getFirstTests } from "../getFirstTests";
import moment from "moment";
import draggableImage from "./DraggableImage";
Vue.use(VueHolder);

export default {
  name: "FirstTest",
  components: {
    draggable: draggable,
    draggableImage: draggableImage,
  },
  data: function() {
    return {
      bucket: firebase.storage().ref().bucket,
      selectedFirstTest: {
        subject: {},
        description: {},
        options: [
          { name: {}, params: {} },
          { name: {}, params: {} },
          { name: {}, params: {} },
          { name: {}, params: {} },
          { name: {}, params: {} },
          { name: {}, params: {} },
        ], 
        published: false
      },
      errors: [],
      confirmMessage: null,
      dragging: false,
      paramsDefinitions: [],
      firstTests: [],

      thumbnailFileProgressMessage: null,
      saving: false,
      saved: false,
      deleted: false,
      min: 0,
      moment: moment
    };
  },
  mounted: async function() {
    this.paramsDefinitions = await getParamsDefinitions();
    this.refreshState();
  },
  computed: {
  },
  methods: {
    getStorageUrlFromFileName: function(fileName) {
      return (
        "https://storage.googleapis.com/" +
        this.bucket +
        "/" +
        fileName +
        "?" +
        moment().valueOf()
      );
    },
    getBase64: function(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    onDelete: async function(firstTest) {
      this.saved = false;
      this.deleted = false;
      let data = this;
      let result = await this.$bvModal.msgBoxConfirm(
        "「" +
          firstTest.subject.ja +
          "」を削除します。この操作はやり直せません。削除しますか？"
      );
      if (!result) {
        return;
      }
      const storageRef = firebase.storage().ref();
      firstTest.options.forEach(async function (option)
      {
        try
        {
          await storageRef.child(option.image).delete();
        } catch (error) { // 無ければそれはそれで無視でいいらしい
          console.log(error);
        }
      });
      firebase
        .firestore()
        .collection("firstTests")
        .doc(firstTest.id)
        .delete()
        .then(function() {
          data.deleted = true;
          data.refreshState();
          data.$bvModal.msgBoxConfirm("削除しました。");
        })
        .catch(function(error) {
          alert("削除エラー: " + error);
        });
    },
    editFirstTest: function(firstTest) {
      this.saved = false;
      this.deleted = false;
      this.selectedFirstTest = JSON.parse(JSON.stringify(firstTest));
      for (let i = 0; i < 6; i++) {
        if (!this.selectedFirstTest.options[i]) {
          const params = {};
          this.paramsDefinitions.forEach(param => {
              params[param.id] = 0;
          });
          this.selectedFirstTest.options.push({ name: {}, params: params });
        } else {
          this.paramsDefinitions.forEach(param => {
              if (!this.selectedFirstTest.options[i].params[param.id]) {
                this.selectedFirstTest.options[i].params[param.id] = 0;
              }
          });
        }
      }
      this.$bvModal.show("form-modal");
    },
    createFirstTest: function() {
      this.saved = false;
      this.deleted = false;
      this.selectedFirstTest = this.newFirstTest();
      for (let i = 0; i < 6; i++) {
          this.paramsDefinitions.forEach(param => {
              this.selectedFirstTest.options[i].params[param.id] = 0;
          });
      }
      this.$bvModal.show("form-modal");
    },
    newFirstTest: function() {
       return {
        subject: {},
        description: {},
        options: [
          { name: {}, params: {} },
          { name: {}, params: {} },
          { name: {}, params: {} },
          { name: {}, params: {} },
          { name: {}, params: {} },
          { name: {}, params: {} },
        ], 
        published: false
      };
    },
    saveFirstTest: async function() {
      let len = 0;
      for (let i = 0; i < 6; i++) {
        if (this.selectedFirstTest.options[i].name &&
            this.selectedFirstTest.options[i].name.ja &&
            (!this.selectedFirstTest.options[i].base64Image && 
            !this.selectedFirstTest.options[i].image)) {
            alert("選択肢に名前、画像両方をセットしてください。");
        }
        if (this.selectedFirstTest.options[i].name &&
            this.selectedFirstTest.options[i].name.ja &&
            (this.selectedFirstTest.options[i].base64Image || 
            this.selectedFirstTest.options[i].image)) {
            len++;
        }
      }
      if (len < 2) {
        alert("名前、画像をしっかりセットし、選択肢は二つ以上にしてください");
        return;
      }
      if (this.saving) {
        return;
      }
      this.saving = true;
      let firstTest = {
        subject: { ja: this.selectedFirstTest.subject.ja },
        description: { ja: this.selectedFirstTest.description.ja},
        options: [],
        published: this.selectedFirstTest.published,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        sortOrder: this.firstTests.length + 1
      };

      if (!this.selectedFirstTest.id) {
        firstTest.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      }

      for (let i = 0; i < 6; i++) {
        if (this.selectedFirstTest.options[i].name &&
            this.selectedFirstTest.options[i].name.ja) {
          let option = { name: this.selectedFirstTest.options[i].name, params: this.selectedFirstTest.options[i].params};
          if (this.selectedFirstTest.options[i].image) {
            option.image = this.selectedFirstTest.options[i].image;
          } else {
            option.image = "firstTests/" + this.getHash(64);
          }
          if (this.selectedFirstTest.options[i].base64Image) {
            const storageRef = firebase.storage().ref();
            try {
              await storageRef
                .child(option.image)
                .putString(this.selectedFirstTest.options[i].base64Image, "data_url");
            } catch (err) {
              alert(
                "クラウドストレージへのファイルアップロードに失敗しました。" + err
              );
              this.saving = false;
              return;
            }
          }
          firstTest.options.push(option);
        }
      }
      try {
        if (this.selectedFirstTest.id) {
          await firebase
            .firestore()
            .collection("firstTests")
            .doc(this.selectedFirstTest.id)
            .update(firstTest);
          this.refreshState();
          this.saved = true;
          this.$bvModal.hide("form-modal");
        } else {
          await firebase
            .firestore()
            .collection("firstTests")
            .add(firstTest);
          this.refreshState();

          setTimeout(() => {
            this.saved = true;
          }, 500);
          this.$bvModal.hide("form-modal");
          this.saving = false;
        }
      } catch (err) {
        alert("DBへの保存に失敗しました。" + err);
        this.saving = false;
        return;
      }
    },
    refreshState: function() {
      this.saved = false;
      this.deleted = false;
      this.thumbnailFileProgressMessage = null;
      getFirstTests().then(val => {
        this.firstTests = val;
      });

    },
    saveSort: async function() {
      try {
        this.firstTests.forEach(async function(firstTest, index) {
          await firebase
            .firestore()
            .collection("firstTests")
            .doc(firstTest.id)
            .update({ sortOrder: index });
        });
        this.$bvModal.hide("sort-modal");
        this.refreshState();
      } catch (err) {
        alert("保存しなおしてください。エラーが発生しました:" + err);
      }
    }
  },
  watch: {
    $route() {
      this.refreshState();
    }
  }
};
</script>
