<template>
  <div class="home">
    <b-row class="align-items-center pt-4">
      <b-col class="h5">期間ダウンロード</b-col>
    </b-row>
    <b-row class="align-items-center py-2">
      <div class="col-3 text-right">期間：</div>
      <div class="col-3">
        <datetime v-model="queryOption.startAt" type="date"> </datetime>
      </div>
      <div class="col-1">～</div>
      <div class="col-3">
        <datetime v-model="queryOption.endAt" type="date"> </datetime>
      </div>
    </b-row>
    <b-row class="align-items-center">
      <div class="col-3 text-right">動画：</div>
      <button
        class="btn btn-sm btn-secondary col-4"
        @click="downloadWatchedVideos()"
      >
        ユーザの動画閲覧履歴
      </button>
    </b-row>
    <b-row class="align-items-center pt-1">
      <div class="col-3 text-right">ポイント等：</div>
      <button class="btn btn-sm btn-secondary col-8" @click="downloadParams()">
        ユーザのSPORYポイント・負荷パラメータ・万歩計など
      </button>
    </b-row>
    <hr>
    
    <b-row class="align-items-center pt-4">
      <b-col class="h5">ユーザ番号から検索：</b-col>
    </b-row>
    <b-row class="align-items-center pt-4">
      <b-form-textarea
        class="col-5 ml-4"
        id="textarea"
        v-model="userNo"
        placeholder="改行、カンマ、タブ等区切りでユーザNoをご入力ください。"
        rows="3"
      ></b-form-textarea>
      <button
        :disabled="downloadingFromUserNo"
        class="btn btn-sm btn-secondary ml-3 col-3"
        @click="downloadFromUserNo()"
      >
        表示
      </button>
    </b-row>
    <b-row class="pt-4 align-items-center">
      <b-form-textarea class="col-11 mx-4" v-model="textContent" rows="60"></b-form-textarea>
    </b-row>
  </div>
</template>

<script>
import firebase from "firebase";
import "firebase/firestore";

import Vue from "vue";
import moment from "moment";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import VueHolder from "vue-holderjs";
import { getParamsDefinitions } from "../getParamsDefinitions";
Vue.use(VueHolder);

export default {
  name: "Data",
  components: {
    datetime: Datetime,
  },
  data: function () {
    return {
      queryOption: {
        startAt: moment(
          moment().subtract(1, "months").format("YYYY-MM-DD 00:00:00")
        ).toISOString(),
        endAt: moment(
          moment().subtract(1, "days").format("YYYY-MM-DD 23:59:59")
        ).toISOString(),
      },
      textContent: "検索結果(CSV)",
      userNo: "",
      downloadingFromUserNo: false,
      paramsDefinitions: [],
    };
  },
  mounted: async function () {
    this.paramsDefinitions = await getParamsDefinitions();
  },
  methods: {
    downloadWatchedVideos: function () {
      const query = firebase.firestore().collection("watchedVideos");
      query
        .where("createdAt", ">=", new Date(this.queryOption.startAt))
        .where("createdAt", "<=", new Date(this.queryOption.endAt))
        .get()
        .then(function (querySnapshot) {
          let csv = "datetime,userID,videoID,sec\n";
          querySnapshot.forEach(function (doc) {
            let d = doc.data();
            let row = "";
            if (d["createdAt"] && d["video"]) {
              row = [
                d["createdAt"]
                  .toDate()
                  .toISOString()
                  .replace("T", " ")
                  .substr(0, 19),
                d["uId"],
                d["video"].id,
                d["expectedDuration"],
              ].join(",");
            }
            if (row) {
              csv += row + "\n";
            }
          });
          const anchor = document.createElement("a");
          anchor.href =
            "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
          anchor.target = "_blank";
          anchor.download = "watchedVideoExport.csv";
          anchor.click();
        })
        .catch(function (error) {
          console.error("Error getting documents: ", error);
        });
    },
    downloadFromUserNo: async function () {
      this.downloadingFromUserNo = true;
      let self = this;
      self.textContent = "UserNo,UserID,userName,email,token,"
      let pds = this.paramsDefinitions;
      const keyNames = {
        doneChapterCnt: "チャプタ",
        stepCnt: "歩数",
        totalPts: "ポイント",
        totalSeconds: "動画閲覧秒数",
        watchedParams: {},
        //trainerPts:
        //trainerStep:
        //usedStepCnt:
      };
      pds.forEach((d) => {
        keyNames.watchedParams[d.id] = d.ja;
      });
      for (const keyName in keyNames) {
        if (keyName == "watchedParams") {
          pds.forEach((pd) => {
            self.textContent += pd.ja + ",";
          });
        } else {
          self.textContent += keyNames[keyName] + ",";
        }
      }
      self.textContent += "joinEventIds";
      self.textContent += "\n";
      const query = firebase.firestore().collection("users");
      const userNos = this.userNo.split(/,|\n|\t/);
      const chunkSize = 10;
      for (let i = 0; i < userNos.length; i += chunkSize) {
        const chunk = userNos.slice(i, i + chunkSize);
        query
          .where("userNo", "in", chunk.map(Number))
          .get()
          .then(async function (querySnapshot) {
            const users = {};
            const uids = [];
            querySnapshot.forEach(function (doc) {
              users[doc.ref.id] = doc;
              uids.push(doc.ref.id);
            });
            const functions = firebase.app().functions("asia-northeast1");
            const userList = functions.httpsCallable("userList");
            const res = await userList({ uids: uids });
            const authInfos = res.data;
            
            querySnapshot.forEach(function (doc) {
              let row = "";
              const d = doc.data();
              const user = users[doc.id].data();
              row += user.userNo + ",";
              row += doc.id + ",";
              if (user.userName) {
                row += '"' + user.userName + '",';
              } else {
                row += ",";
              }

              if (authInfos[doc.id]) {
                row += '"' + authInfos[doc.id].email + '",';
              } else {
                row += ",";
              }

              if (user.fcm_token) {
                row += '"' + user.fcm_token + '",';
              } else {
                row += ",";
              }

              for (const keyName in keyNames) {
                if (keyName == "watchedParams") {
                  pds.forEach((pd) => {
                    if (d[keyName] && d[keyName][pd.id]) {
                      row += String(d[keyName][pd.id]) + ",";
                    } else {
                      row += "0,";
                    }
                  });
                } else {
                  if (d[keyName]) {
                    row += String(d[keyName]) + ",";
                  } else {
                    row += "0,";
                  }
                }
              }
              if (user.joinEventIds) {
                row += user.joinEventIds.join(",") + ",";
              } else {
                row += ",";
              }
              self.textContent += row + "\n";
            });
          })
          .catch(function (error) {
            console.error("Error getting documents: ", error);
          });
      }
      this.downloadingFromUserNo = false;
    },
    downloadParams: function () {
      let pds = this.paramsDefinitions;
      let queryOption = this.queryOption;
      const keyNames = {
        doneChapterCnt: "チャプタ",
        stepCnt: "歩数",
        totalPts: "ポイント",
        totalSeconds: "動画閲覧秒数",
        watchedParams: {},
        //trainerPts:
        //trainerStep:
        //usedStepCnt:
      };
      pds.forEach((d) => {
        keyNames.watchedParams[d.id] = d.ja;
      });
      const query = firebase.firestore().collectionGroup("histories");
      query
        .where(
          "yyyymmdd",
          ">=",
          Number(moment(queryOption.startAt).format("YYYYMMDD"))
        )
        .where(
          "yyyymmdd",
          "<=",
          Number(moment(queryOption.endAt).format("YYYYMMDD"))
        )
        .get()
        .then(async function (querySnapshot) {
          let csv = "DATE,UserID,UserNo,userName,email,token,";
          for (const keyName in keyNames) {
            if (keyName == "watchedParams") {
              pds.forEach((pd) => {
                csv += pd.ja + ",";
              });
            } else {
              csv += keyNames[keyName] + ",";
            }
          }
          csv += "joinEventIds";
          csv += "\n";
          const promises = [];
          const userIds = [];
          querySnapshot.forEach(function (doc) {
            if (doc.ref.parent.parent) {
              if (!userIds[doc.ref.parent.parent.id]) {
                userIds.push(doc.ref.parent.parent.id);
                promises.push(doc.ref.parent.parent.get());
              }
            }
          });

          const users = {};
          const uids = [];
          const all = await Promise.all(promises);
          all.forEach((d) => {
            users[d.id] = d;
            uids.push(d.id);
          });

          const functions = firebase.app().functions("asia-northeast1");
          const userList = functions.httpsCallable("userList");
          const res = await userList({ uids: uids });
          const authInfos = res.data;

          querySnapshot.forEach(function (doc) {
            let row = "";
            if (doc.ref.parent.parent) {
              const d = doc.data();
              const user = users[doc.ref.parent.parent.id].data();
              (row += String(d["yyyymmdd"]) + ","),
                (row += doc.ref.parent.parent.id + ",");
              row += user.userNo;
              if (user.userName) {
                row += '"' + user.userName + '",';
              } else {
                row += ",";
              }

              if (authInfos[doc.ref.parent.parent.id]) {
                row += '"' + authInfos[doc.ref.parent.parent.id].email + '",';
              } else {
                row += ",";
              }

              if (user.fcm_token) {
                row += '"' + user.fcm_token + '",';
              } else {
                row += ",";
              }

              for (const keyName in keyNames) {
                if (keyName == "watchedParams") {
                  pds.forEach((pd) => {
                    if (d[keyName] && d[keyName][pd.id]) {
                      row += String(d[keyName][pd.id]) + ",";
                    } else {
                      row += "0,";
                    }
                  });
                } else {
                  if (d[keyName]) {
                    row += String(d[keyName]) + ",";
                  } else {
                    row += "0,";
                  }
                }
              }
              if (user.joinEventIds) {
                row += user.joinEventIds.join(",") + ",";
              } else {
                row += ",";
              }
              csv += row + "\n";
            }
          });

          const anchor = document.createElement("a");

          var bom = new Uint8Array([0xef, 0xbb, 0xbf]);
          const blob = new Blob([bom, csv], { type: "text/csv" });

          anchor.href = window.webkitURL.createObjectURL(blob);
          anchor.download =
            "params-" +
            moment(queryOption.startAt).format("YYYYMMDD") +
            "-" +
            moment(queryOption.endAt).format("YYYYMMDD") +
            ".csv";
          anchor.click();
        })
        .catch(function (error) {
          console.error("Error getting documents: ", error);
        });
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.getTags();
    this.saved = false;
    this.deleted = false;
    next();
  },
};
</script>
