import Vue from "vue";
import VueRouter from "vue-router";
import Video from "../views/Video.vue";
import Quest from "../views/Quest.vue";
import Trainer from "../views/Trainer.vue";
import Tag from "../views/Tag.vue";
import ParamDefinition from "../views/ParamDefinition.vue";
import FirstTest from "../views/FirstTest.vue";
import Category from "../views/Category.vue";
import Data from "../views/Data.vue";
import Comment from "../views/Comment.vue";
import Achievement from "../views/Achievement.vue";
import Topic from "../views/Topic.vue";
import Event from "../views/Event.vue";
import SignIn from "../views/SignIn.vue";
import SignOut from "../views/SignOut.vue";
import firebase from "firebase/app";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "TOP",
    component: Video,
  },
  {
    path: "/Video",
    name: "Video",
    component: Video,
  },
  {
    path: "/Quest",
    name: "Quest",
    component: Quest,
  },
  {
    path: "/ParamDefinition",
    name: "ParamDefinition",
    component: ParamDefinition,
  },
  {
    path: "/FirstTest",
    name: "FirstTest",
    component: FirstTest,
  },
  {
    path: "/Tag",
    name: "Tag",
    component: Tag,
  },
  {
    path: "/Trainer",
    name: "Trainer",
    component: Trainer,
  },
  {
    path: "/Topic",
    name: "Topic",
    component: Topic,
  },
  {
    path: "/Event",
    name: "Event",
    component: Event,
  },
  {
    path: "/Category",
    name: "Category",
    component: Category,
  },
  {
    path: "/Data",
    name: "Data",
    component: Data,
  },
  {
    path: "/Comment",
    name: "Comment",
    component: Comment,
  },
  {
    path: "/Achievement",
    name: "Achievement",
    component: Achievement,
  },
  {
    path: "/SignIn",
    name: "SignIn",
    component: SignIn,
  },
  {
    path: "/SignOut",
    name: "SignOut",
    component: SignOut,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isNotChrome = !window.chrome;
  if (isNotChrome) {
    alert("EdgeもしくはChromeブラウザでアクセスしてください。");
    return;
  }
  if (to.path == "/SignIn" || to.path == "/SignOut") {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user.getIdTokenResult(true).then(() => {});

        if (to.path == "/SignIn") {
          next({ path: "/Video" });
        } else {
          next();
        }
      } else {
        next();
      }
    });
  } else {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user.getIdTokenResult(true).then((result) => {
          if (result.claims.admin) {
            if (to.path == "/SignIn") {
              next({ path: "/Video" });
            } else {
              next();
            }
          }
        });
      } else {
        next({ path: "/SignIn" });
      }
    });
  }
});

export default router;
