import firebase from "firebase";
import "firebase/firestore";

export { getFirstTests };
async function getFirstTests() {
  const docs = await firebase
    .firestore()
    .collection("firstTests")
    .orderBy("sortOrder")
    .get();
  let datas = [];
  docs.forEach(function(doc) {
    let d = doc.data();
    d.id = doc.id;
    d.reference = doc.ref;
    datas.push(d);
  });
  return datas;
}
