<template>
  <div class="home">
    <div v-if="deleted" class="alert alert-danger" role="alert">
      削除しました。
    </div>
    <b-row class="py-3">
      <b-col cols="1" class="text-right pr-0"> 期間: </b-col>
      <b-col cols="2">
        <vuejs-datepicker
          :language="ja"
          :format="customFormatter"
          @closed="pickerClosedFrom"
          v-model="from"
        ></vuejs-datepicker>
      </b-col>
      <b-col cols="1" class="text-right pr-0"> から </b-col>
      <b-col cols="2">
        <vuejs-datepicker
          :language="ja"
          :format="customFormatter"
          @closed="pickerClosedTo"
          v-model="to"
        ></vuejs-datepicker>
      </b-col>
    </b-row>

    <h4 v-if="!comments">まだありません・・・</h4>
    <table class="table  table-bordered table-striped" v-else>
      <tbody>
      <tr v-for="(comment, key) in comments" :key="key">
        <td
          style="width: 30%;"
        >
          <div
            v-if="comment.video && videos.find((g) => g.id == comment.video.id)"
          >
            <b-img
              v-bind:src="
                videos.find((g) => g.id == comment.video.id).thumbnailUrl
              "
              style="max-height: 80px"
            />
            <br />
            <small style="overflow: hidden; text-overflow: ellipsis">
              {{ videos.find((g) => g.id == comment.video.id).title }}
            </small>
          </div>
          <div
            v-if="
              comment.trainer &&
              trainers.find((g) => g.id == comment.trainer.id)
            "
          >
            <b-img
              v-bind:src="
                trainers.find((g) => g.id == comment.trainer.id).iconUrl
              "
              rounded="circle"
              style="max-height: 100px"
            />
            <br />
            <small>
              {{ trainers.find((g) => g.id == comment.trainer.id).name }}
            </small>
          </div>
        </td>
        <td class="align-top text-left">
          <p class="text-left pt-0 pb-2">
            <small>{{ formatDate(comment.createdAt) }}<br />ユーザId: {{ comment.uId }}</small>
          </p>
          <h5>{{ comment.body }}</h5>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import firebase from "firebase";
import "firebase/firestore";
import "firebase/storage";
import moment from "moment";
import { getTrainers } from "../getTrainers";
import { getVideos } from "../getVideos";
import vuejsDatepicker from "vuejs-datepicker";
import { ja } from "vuejs-datepicker/dist/locale";

import Vue from "vue";
import VueHolder from "vue-holderjs";
Vue.use(VueHolder);

export default {
  name: "Comment",
  components: { vuejsDatepicker },
  data: function() {
    return {
      hostname: window.location.hostname,
      from: moment()
        .subtract(1, "months")
        .format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      DatePickerFormat: "yyyy-MM-dd",
      videos: [],
      comments: [],
      trainers: [],
      errors: [],
      saved: false,
      deleted: false,
      moment: moment,
      ja: ja
    };
  },
  mounted: async function() {
    this.getFromUrlParams();
    this.trainers = await getTrainers();
    this.videos = await getVideos();
    this.getComments();
  },
  methods: {
    customFormatter(date) {
      return moment(date).format("📅 YYYY-MM-DD");
    },
    pickerClosedFrom() {
      if (this.from) {
        this.from = moment(this.from).format(" YYYY-MM-DD");
        this.routerPush();
      }
    },
    pickerClosedTo() {
      if (this.to) {
        this.to = moment(this.to).format("YYYY-MM-DD");
        this.routerPush();
      }
    },
    getComments: function() {
      let self = this;
      const ref = firebase.firestore().collection("comments");
      self.comments = [];
      let query = ref;
      query
        .orderBy("createdAt", "desc")
        .where("createdAt", ">=", new Date(self.from))
        .where("createdAt", "<=", new Date(self.to))
        .get()
        .then(function(querySnapshot) {
          let datas = [];
          querySnapshot.forEach(function(doc) {
            let d = doc.data();
            d.id = doc.id;
            datas.push(d);
          });
          self.comments = datas;
        })
        .catch(function(error) {
          console.error("Error getting documents: ", error);
        });
    },
    onDelete: function(comment) {
      this.saved = false;
      this.deleted = false;
      this.$bvModal
        .msgBoxConfirm("削除します。この操作はやり直せません。削除しますか？")
        .then(result => {
          if (!result) {
            return;
          }
          firebase
            .firestore()
            .collection("comments")
            .doc(comment.id)
            .delete()
            .then(function() {})
            .catch(function(error) {
              console.error(error);
              alert("削除エラー: ", error);
            });
        });
    },
    getFromUrlParams: function() {
      if (this.$route.query["from"]) {
        this.from = moment(this.$route.query["from"]).format("YYYY-MM-DD");
      }

      if (this.$route.query["to"]) {
        this.to = moment(this.$route.query["to"]).format("YYYY-MM-DD");
      }
    },
    routerPush() {
      let pushRouteParam = {
        from: this.from,
        to: this.to
      };
      this.$router.push({ path: "", query: pushRouteParam });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.getComments();
    this.saved = false;
    this.deleted = false;
    next();
  }
};
</script>
