<template>
  <div class="home">
    <div v-if="saved" class="alert alert-primary" role="alert">
      保存しました。反映に時間が少しかかることがあります。新規作成の場合、もし間違えたとしても修正のための削除する場合は時間を置き、動画画面から「設定中」パラメータが無くなってから行ってください。
    </div>
    <div v-if="deleted" class="alert alert-danger" role="alert">
      削除しました。
    </div>
    <b-modal
      size="xl"
      id="form-modal"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      v-bind:title="
        !selectedParamDefinition.createdAt
          ? '新規作成'
          : '「' + selectedParamDefinition.id + '」を編集'
      "
      hide-footer
    >
      <p v-if="selectedParamDefinition.createdAt">
        削除する場合は、和名をカラにして、削除を押下してください。
      </p>
      <b-form @submit.prevent="saveParamDefinition">
        <b-form-group class="form-group">
          <div class="input-group text-center">
            <div class="mr-2 col-3 text-right">ID</div>
            <div
              class="col-5 text-left"
              v-if="selectedParamDefinition.createdAt"
            >
              {{ selectedParamDefinition.id }}
              <input
                type="hidden"
                required="required"
                v-model.trim="selectedParamDefinition.id"
              />
            </div>
            <input
              v-else
              type="text"
              inputmode="email"
              class="form-control col-9 mt-1"
              required="required"
              v-model.trim="selectedParamDefinition.id"
              placeholder="abs lumberSideFlection など"
              pattern="^[a-z][0-9A-Za-z]+[0-9a-z]$"
              @keydown.self.prevent.enter
            />
          </div>
          <div class="input-group">
            <div class="offset-3 text-left">
              <small
                >※変更は不能です。<br />英数字のみで、最初の単語だけ小文字始まり、以降は大文字始まりです。<br />詳細は「キャメルケース」を調べてください。</small
              >
            </div>
          </div>
        </b-form-group>
        <b-form-group class="form-group">
          <div class="input-group text-center">
            <div class="mr-2 col-3 text-right">和名</div>
            <input
              type="text"
              class="form-control col-9 mt-1"
              required="required"
              v-model.trim="selectedParamDefinition.ja"
              placeholder="腹筋 など"
              @keydown.self.prevent.enter
            />
          </div>
        </b-form-group>
        <b-form-group class="form-group">
          <div class="input-group text-center">
            <div class="mr-2 col-3 text-right">ソート番号(昇順)</div>
            <input
              type="number"
              class="form-control col-4 mt-1 text-right"
              required="required"
              step="1"
              v-model="selectedParamDefinition.sortOrder"
              @keydown.self.prevent.enter
            />
          </div>
          <div class="input-group">
            <div class="offset-3 text-left">
              <small
                >※100ずつずらしたりすると間に入れるとき楽です。重複も問いません。</small
              >
            </div>
          </div>
        </b-form-group>
        <input
          type="hidden"
          required="required"
          v-model.trim="selectedParamDefinition.valueType"
        />
        <b-form-group class="form-group">
          <div class="input-group text-center">
            <div class="mr-2 col-3 text-right">初期値(0-100で入力)</div>
            <input
              type="number"
              class="form-control col-4 mt-1 text-right"
              required="required"
              v-model.trim="selectedParamDefinition.defaultValue"
              step="1"
              @keydown.self.prevent.enter
            />
          </div>
          <div class="input-group">
            <div class="offset-3 text-left">
              <small>※0以外にすることはまずありません。</small><br />
              <small>※編集で変更しても既存のパラメータに影響はしません。</small>
            </div>
          </div>
        </b-form-group>
        <b-form-group class="form-group">
          <div class="input-group text-center">
            <div class="mr-2 col-3 text-right">メモ</div>
            <input
              type="text"
              class="form-control col-9 mt-1"
              v-model.trim="selectedParamDefinition.note"
              placeholder="パラメータの説明です。(アプリには表示しません)"
              @keydown.self.prevent.enter
            />
          </div>
        </b-form-group>
        <div class="input-group row mt-2 mb-2">
          <a
            v-if="
              selectedParamDefinition.createdAt &&
              selectedParamDefinition.ja.trim() == ''
            "
            class="btn btn-sm btn-danger offset-7 col-2"
            @click="deleteParamDefinition()"
          >
            削除
          </a>
          <button
            v-else
            class="btn btn-sm btn-primary offset-7 col-2"
            type="submit"
          >
            保存
          </button>
          <a
            class="btn btn-sm btn-outline-secondary offset-1 col-2"
            @click="$bvModal.hide('form-modal')"
          >
            キャンセル
          </a>
        </div>
      </b-form>
      <div
        v-if="confirmMessage"
        class="alert alert-info"
        role="alert"
        style="white-space: pre-wrap; word-wrap: break-word"
      >
        {{ confirmMessage.trim() }}
      </div>
    </b-modal>
    <div>&nbsp;</div>
    <div>動画に割り当てるパラメータを作ります。</div>
    <div>
      作ればパラメータは全ての動画に規定値で(CloudFunctionsで)勝手に出来上がります。消せば全ての動画からパラメータは(CloudFunctionsで)勝手に消えます。
    </div>
    <div>
      出来ればパラメータの作成は動画の作成と同タイミングで行わないでください。
    </div>
    <div class="text-right p-3">
      <button
        class="btn btn-primary col-3"
        @click="
          confirmMessage = null;
          newParamDefinition();
          $bvModal.show('form-modal');
        "
      >
        ＋新規作成
      </button>
    </div>
    <table class="table">
      <tr>
        <th class="align-middle text-center" style="width: 10%">編集</th>
        <th class="align-middle text-center" style="width: 10%">ソート番号</th>
        <th class="align-middle text-center" style="width: 15%">ID</th>
        <th class="align-middle text-center" style="width: 20%">和名</th>
        <th class="align-middle text-center" style="width: 5%">初期値</th>
        <th class="align-middle text-center" style="width: 30%">情報</th>
      </tr>
      <tr v-for="(item, key) in this.paramDefinitions" :key="key">
        <td class="align-middle text-center">
          <div class="">
            <button
              class="btn btn-secondary"
              @click="editParamDefinition(item)"
            >
              編集
            </button>
          </div>
        </td>
        <td class="align-middle text-center">
          {{ item.sortOrder }}
        </td>
        <td class="align-middle text-center">
          {{ item.id }}
        </td>
        <td class="align-middle text-center">
          {{ item.ja }}
        </td>
        <td class="align-middle text-right">
          {{ item.defaultValue }}
        </td>
        <td class="align-middle text-center">
          <div>
            {{ item.note }}
          </div>
          <div>
            <small>作成日時：{{ formatDate(item.createdAt) }}</small>
          </div>
          <div>
            <small>更新日時：{{ formatDate(item.updatedAt) }}</small>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import firebase from "firebase";
import "firebase/firestore";
import { getParamsDefinitions } from "../getParamsDefinitions";

import Vue from "vue";
import VueHolder from "vue-holderjs";
Vue.use(VueHolder);

export default {
  name: "ParamDefinition",
  data: function() {
    return {
      paramDefinition: {},
      selectedParamDefinition: {},
      paramDefinitions: [],
      errors: [],
      saved: false,
      deleted: false,
      editFlg: false,
      confirmMessage: null
    };
  },
  mounted: async function() {
    this.paramDefinitions = await getParamsDefinitions();
  },
  methods: {
    //paramDefinition
    newParamDefinition: function() {
      this.saved = false;
      this.deleted = false;
      this.selectedParamDefinition = {
        sortOrder: 100,
        valueType: "number",
        defaultValue: 0
      };
    },
    editParamDefinition: function(paramDefinition) {
      this.saved = false;
      this.deleted = false;
      this.selectedParamDefinition = {
        id: paramDefinition.id,
        sortOrder: Number(paramDefinition.sortOrder),
        ja: paramDefinition.ja,
        valueType: paramDefinition.valueType,
        defaultValue: Number(paramDefinition.defaultValue),
        note: paramDefinition.note,
        createdAt: paramDefinition.createdAt,
        updatedAt: paramDefinition.updatedAt
      };
      this.$bvModal.show("form-modal");
    },
    setParamDefinition: async function(paramDefinition) {
      let data = this;
      return new Promise(function(resolve, reject) {
        firebase
          .firestore()
          .collection("paramDefinitions")
          .doc(data.selectedParamDefinition.id)
          .set(paramDefinition)
          .then(function() {
            data.saved = true;
            resolve();
          })
          .catch(function(error) {
            reject("保存でエラーが発生しました:" + error);
          });
      });
    },
    storeParamDefinition: async function(paramDefinition) {
      let data = this;
      return new Promise(function(resolve, reject) {
        firebase
          .firestore()
          .collection("paramDefinitions")
          .doc(data.selectedParamDefinition.id)
          .update(paramDefinition)
          .then(function() {
            data.saved = true;
            resolve();
          })
          .catch(function(error) {
            reject("保存でエラーが発生しました:" + error);
          });
      });
    },
    saveParamDefinition: function() {
      let data = this;
      if (data.selectedParamDefinition.createdAt) {
        this.updateParamDefinition();
      } else {
        if (
          data.paramDefinitions.some(
            x => x.id == data.selectedParamDefinition.id
          )
        ) {
          alert("すでにこのIDは存在します");
          return false;
        }
        if (
          data.paramDefinitions.some(
            x => x.ja == data.selectedParamDefinition.ja
          )
        ) {
          alert("すでにこの和名は存在します");
          return false;
        }
        this.createParamDefinition();
      }
    },
    createParamDefinition: async function() {
      let data = this;
      let paramDefinition = {
        ja: data.selectedParamDefinition.ja,
        sortOrder: Number(data.selectedParamDefinition.sortOrder),
        valueType: data.selectedParamDefinition.valueType,
        defaultValue: data.selectedParamDefinition.defaultValue,
        note: data.selectedParamDefinition.note ? data.selectedParamDefinition.note : null,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      };
      try {
        await this.setParamDefinition(paramDefinition);
        this.$bvModal.hide("form-modal");
        this.paramDefinitions = await getParamsDefinitions();
      } catch (err) {
        alert("エラーが発生しました:" + err);
      }
    },
    updateParamDefinition: async function() {
      let data = this;
      let paramDefinition = {
        ja: data.selectedParamDefinition.ja,
        sortOrder: Number(data.selectedParamDefinition.sortOrder),
        valueType: data.selectedParamDefinition.valueType,
        defaultValue: data.selectedParamDefinition.defaultValue,
        note: data.selectedParamDefinition.note ? data.selectedParamDefinition.note : null,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        createdAt: data.selectedParamDefinition.createdAt
      };
      try {
        await this.storeParamDefinition(paramDefinition);
        this.$bvModal.hide("form-modal");
        this.paramDefinitions = await getParamsDefinitions();
      } catch (err) {
        alert("もう一度ご送信ください エラー:" + err);
      }
    },
    deleteParamDefinition: async function() {
      let data = this;
      firebase
        .firestore()
        .collection("paramDefinitions")
        .doc(this.selectedParamDefinition.id)
        .delete()
        .then(async function() {
          data.deleted = true;
          data.$bvModal.hide("form-modal");
          this.paramDefinitions = await getParamsDefinitions();
        })
        .catch(function(error) {
          console.error(error);
          alert("削除エラー: ", error);
        });
    }
  },
  async beforeRouteUpdate(to, from, next) {
    this.paramDefinitions = await getParamsDefinitions();
    this.saved = false;
    this.deleted = false;
    next();
  }
};
</script>
