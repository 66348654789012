import firebase from "firebase";
import "firebase/firestore";

export { getTrainers };
async function getTrainers() {
  const docs = await firebase
    .firestore()
    .collection("trainers")
    .orderBy("name")
    .get();

  let datas = [];
  docs.forEach(function(doc) {
    let d = doc.data();
    d.ref = doc.ref;
    d.reference = doc.ref;
    d.id = doc.id;
    d.titleAndName = (d.title ? d.title : "") + " " + d.name;
    datas.push(d);
  });
  return datas;
}
