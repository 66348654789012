<template>
  <div class="home">
    <b-modal
      size="xl"
      cancel-only
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      id="upload-files"
      title="アップロード中です"
      hide-footer
    >
    </b-modal>
    <b-modal
      size="xl"
      cancel-only
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      id="set-manually"
      title="付与中..."
      hide-footer
    >
      <p class="my-4">{{ messageManually }}</p>
    </b-modal>
    <b-modal
      size="xl"
      cancel-only
      no-close-on-backdrop
      no-close-on-esc
      id="manually"
      @ok="setManually"
      v-bind:title="'「' + selectedAchievement.name + '」をユーザに与える'"
    >
      <b-form-textarea
        id="textarea"
        v-model="uids"
        placeholder="改行、カンマ、タブ等区切りでユーザID（名前ではありません！）をご入力ください。なお、期限などあらゆる条件を無視して追加できます。
        結果はUTF-8で開けるCSVとしてダウンロードされます。"
        rows="20"
      ></b-form-textarea>
    </b-modal>
    <div v-if="saved" class="alert alert-primary" role="alert">
      保存しました。
    </div>
    <div v-if="deleted" class="alert alert-danger" role="alert">
      削除しました。
    </div>
    <b-modal
      size="xl"
      id="form-modal"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      v-bind:title="
        !selectedAchievement.id
          ? '新規作成'
          : '「' + selectedAchievement.name + '」を編集'
      "
      hide-footer
    >
      <div v-if="imageFileProgressMessage">
        <p>
          トロフィー画像：{{
            imageFileProgressMessage ? imageFileProgressMessage : "変更なし"
          }}
        </p>
        <div class="text-right">
          <button
            class="btn btn-sm btn-outline-secondary col-5"
            @click="cancelSaving()"
          >
            キャンセル
          </button>
        </div>
      </div>
      <div v-else>
        <b-form @submit.prevent="saveAchievement">
          <b-form-group class="form-group">
            <div class="input-group text-center">
              <div class="mr-2 mt-2 col-3 text-right">タイプ:</div>
              <b-form-select
                :disabled="!!selectedAchievement.id"
                :options="achievementTypes"
                v-model="selectedAchievement.achievementType"
                class="col-4 mt-1"
              >
              </b-form-select>
            </div>

            <template v-if="selectedAchievement.achievementType == 'condition'">
              <div class="input-group text-center">
                <div class="mr-2 mt-3 col-3 text-right">条件:</div>
                <b-input-group class="col-8 pl-0">
                  <template #prepend>
                    <b-form-select
                      :disabled="!!selectedAchievement.id"
                      :options="conditionTypes"
                      v-model="selectedAchievement.conditionType"
                      @change="
                        () => {
                          changeSelectedAchievementType();
                        }
                      "
                      class="mt-2"
                    >
                    </b-form-select>
                  </template>
                  <template
                    v-if="selectedAchievement.conditionType != 'periodTotal'"
                  >
                    <b-form-input
                      :type="
                        conditionTypeInputTypes[
                          selectedAchievement.conditionType
                        ]
                      "
                      required="required"
                      :disabled="!!selectedAchievement.id"
                      v-model.trim="selectedAchievement.conditionValue"
                      :placeholder="
                        conditionTypeUnitDescriotions[
                          selectedAchievement.conditionType
                        ]
                      "
                      @keydown.self.prevent.enter
                      min="1"
                      class="mt-2"
                    ></b-form-input>
                  </template>
                  <template v-else>
                    <b-row
                      class="block-right pt-3"
                      v-if="selectedAchievement.conditionValue != null"
                    >
                      <b-input-group
                        class="col-12 text-right"
                        v-for="(item, key) in dutys"
                        :key="key"
                      >
                        <b-input-group-prepend
                          class="d-flex align-items-center text-right col-8"
                        >
                          <small>{{ dutyNames[key] }}:</small>
                        </b-input-group-prepend>
                        <b-form-input
                          class="text-right mx-1 col-4"
                          size="sm"
                          type="number"
                          required="required"
                          :disabled="!!selectedAchievement.id"
                          v-model.trim="selectedAchievement.conditionValue[key]"
                          @keydown.self.prevent.enter
                          min="0"
                        ></b-form-input>
                      </b-input-group>
                    </b-row>
                  </template>
                </b-input-group>
              </div>
            </template>
            <template v-else>
              <div class="input-group text-center">
                <div class="mr-2 mt-3 col-3 text-right">コード:</div>
                <b-form-input
                  type="text"
                  required="required"
                  v-model.trim="selectedAchievement.code"
                  placeholder=""
                  @keydown.self.prevent.enter
                  class="col-4 mt-2"
                ></b-form-input>
              </div>
            </template>
            <div
              v-if="selectedAchievement.conditionType == 'periodTotal'"
              class="input-group text-left pt-3"
            >
              <div class="mr-2 mt-3 col-3 text-right">&nbsp;</div>
              <b-col column="9"
                ><small
                  >限定期間はイベントの+1に、X日以内にはイベントの日数にすると、集計して翌日に取得できます。</small
                ></b-col
              >
            </div>
            <div class="input-group text-center">
              <div class="mr-2 mt-3 col-3 text-right">イベント:</div>
              <b-form-input
                type="text"
                v-model.trim="selectedAchievement.eventId"
                placeholder="イベントのIDを入力します"
                @keydown.self.prevent.enter
                class="col-6 mt-2"
              ></b-form-input>
            </div>
            <div
              v-if="
                selectedAchievement.conditionType == 'RepVideo' ||
                selectedAchievement.conditionType == 'RepQuest'
              "
              class="input-group text-left pt-3"
            >
              <div class="mr-2 mt-3 col-3 text-right">&nbsp;</div>
              <b-col column="9"
                >※同一クエスト、同一動画はタイトルと説明内に%name%を入れると<br />クエスト・動画名で置き換えることができます</b-col
              >
            </div>
            <div class="input-group text-center pt-3">
              <div class="mr-2 mt-3 col-3 text-right">タイトル:</div>
              <b-form-input
                type="text"
                required="required"
                v-model.trim="selectedAchievement.name"
                placeholder=""
                @keydown.self.prevent.enter
                class="col-4 mt-2"
              ></b-form-input>
            </div>

            <div class="input-group text-center mt-2">
              <div class="mr-2 mt-2 col-3 text-right">説明:</div>
              <b-form-textarea
                id="textarea"
                required="required"
                v-model.trim="selectedAchievement.description"
                placeholder="価値を感じるカッコいい感じに"
                rows="3"
                max-rows="6"
                class="col-9 mt-2"
              ></b-form-textarea>
            </div>

            <div class="input-group text-center mt-4">
              <div class="mr-2 col-3 text-right">限定期間:</div>
              <div class="col-2">
                <datetime
                  v-model="selectedAchievement.gettingStartAt"
                  type="datetime"
                >
                </datetime>
              </div>
              <div class="px-2 mx-2 col-1 text-right">～</div>
              <div class="col-2">
                <datetime
                  v-model="selectedAchievement.gettingEndAt"
                  type="datetime"
                >
                </datetime>
              </div>
              <small>※集計が必要な実績は数日、余裕を見ましょう。</small>
            </div>

            <div
              v-if="selectedAchievement.image"
              class="col-12 text-center mt-4 text-center"
            >
              <strong
                >以下は画像を差し替えるときのみアップロードしてください。</strong
              >
            </div>
            <div class="input-group text-center mt-4">
              <template v-if="selectedAchievement.achievementType == 'code'">
                <div class="col-3 text-right mt-2">トロフィー画像:</div>
                <div class="col-9 px-2">
                  <b-form-file
                    v-model="selectedAchievement.imageFile"
                    :state="Boolean(selectedAchievement.imageFile)"
                    accept=".webp, .jpg, .jpeg, .png, .gif, .svg"
                    placeholder="svg,webp,jpeg,png,gifを選択かドロップ"
                    drop-placeholder="Drop file here..."
                    :required="selectedAchievement.id == ''"
                  ></b-form-file>
                  ※1:1、512x512以上。出来ればpng、webp,svg。splitエラーが出たときはwebページを更新してください。
                </div>
              </template>
              <template v-else>
                <div class="col-3 text-right mt-2">
                  がくぶち/トロフィー画像:
                </div>
                <div class="col-9 px-2">
                  <b-form-file
                    v-model="selectedAchievement.imageFile"
                    :state="Boolean(selectedAchievement.imageFile)"
                    accept=".webp, .jpg, .jpeg, .png, .gif, .svg"
                    placeholder="svg,webp,jpeg,png,gifを選択かドロップ"
                    drop-placeholder="Drop file here..."
                    :required="selectedAchievement.id == ''"
                  ></b-form-file>
                  <div class="text-left">
                    <strong
                      >透明部の背景:&nbsp;{{
                        conditionTypeImages[selectedAchievement.conditionType]
                      }}</strong
                    ><br />
                    ※1:1、512x512以上。透明部分を用意するとトレーナーアイコンやロゴにかぶせる額縁になります。<br />
                    真ん中を透明にしなければオリジナルトロフィー画像になります。
                  </div>
                </div>
              </template>
            </div>
          </b-form-group>
          <div class="input-group row pb-3">
            <button class="btn btn-sm btn-primary offset-7 col-2" type="submit">
              保存
            </button>
            <a
              class="btn btn-sm btn-outline-secondary offset-1 col-2"
              @click="$bvModal.hide('form-modal')"
            >
              キャンセル
            </a>
          </div>
          <div
            v-if="selectedAchievement.info"
            class="col-12 text-center mt-4 text-center"
          >
            {{ selectedAchievement.info }}
          </div>
        </b-form>
        <div
          v-if="confirmMessage"
          class="alert alert-info"
          role="alert"
          style="white-space: pre-wrap; word-wrap: break-word"
        >
          {{ confirmMessage.trim() }}
        </div>
      </div>
    </b-modal>
    <div class="text-right p-3">
      <button
        class="btn btn-primary col-3"
        @click="
          confirmMessage = null;
          newAchievement();
          $bvModal.show('form-modal');
        "
      >
        ＋新規作成
      </button>
    </div>

    <div class="text-right p-3" style="display: none">
      <button class="btn btn-primary col-3" @click="testAchievement()">
        テスト
      </button>
    </div>
    <div class="text-left px-3">
      ※ユーザには新着順でのみ見れます。新規作成後、編集不能なパラメータがあります。
    </div>
    <table class="table">
      <tr>
        <th class="align-middle text-center" style="width: 10%">編集</th>
        <th class="align-middle text-center" style="width: 30%">
          画像 / がくぶち
        </th>
        <th class="align-middle text-center" style="width: 60%">情報</th>
      </tr>
      <tr v-for="(item, key) in this.achievements" :key="key">
        <td class="align-middle text-center">
          <div class="pt-3">
            <b-badge v-if="item.timeStatus == '期間前'" variant="success"
              >ー開催前</b-badge
            >
            <b-badge v-if="item.timeStatus == '期間中'" variant="info"
              >開催中ー</b-badge
            >
            <b-badge v-if="item.timeStatus == '期間後'" variant="dark"
              >ー終了ー</b-badge
            >
          </div>
          <div class="pt-3">
            <button class="btn btn-secondary" @click="editAchievement(item)">
              編集
            </button>
          </div>
          <div class="pt-3">
            <button class="btn btn-danger" @click.stop.prevent="onDelete(item)">
              削除
            </button>
          </div>
          <div class="pt-3">
            <button class="btn btn-sm btn-light" @click="onmanually(item)">
              手動付与
            </button>
          </div>

          <div class="pt-3">
            <button
              class="btn btn-sm btn-light"
              @click="downloadAchievedUsers(item)"
            >
              達成者DL
            </button>
          </div>
        </td>
        <td class="align-middle text-center">
          <img v-bind:src="item.imageUrl" style="max-height: 200px" />
        </td>
        <td class="align-middle">
          <template v-if="item.achievementType == 'condition'">
            <div class="pb-2">
              <span class="text" v-if="item.conditionType == 'periodTotal'">
                条件:
                {{
                  conditionTypeNames[item.conditionType].replace(
                    "N",
                    item.conditionValue.withinDays.toString()
                  )
                }}
                /
                <small>
                  {{
                    Object.keys(item.conditionValue)
                      .filter((key) => key != "withinDays")
                      .map(
                        (key) => dutyNames[key] + ":" + item.conditionValue[key]
                      )
                      .join("&nbsp;")
                  }}
                </small>
              </span>
              <span v-else>
                条件: {{ conditionTypeNames[item.conditionType] }} /
                {{ conditionTypeUnits[item.conditionType] }}:{{
                  item.conditionValue
                }}
              </span>
              <br />
              <small class="text-warning" v-if="item.eventId">
                ＋{{ item.eventId }}への参加
              </small>
            </div>
          </template>
          <template v-if="item.achievementType == 'code'">
            <div class="pb-2">コード: {{ item.code }}</div>
          </template>
          <h3 style="white-space: pre-wrap; word-wrap: break-word">
            {{ item.name }}
          </h3>
          <p style="white-space: pre-wrap; word-wrap: break-word">
            {{ item.description.replaceAll("\\n", "\n") }}
          </p>
          <div>
            限定：{{ formatDateShort(item.gettingStartAt) }}&nbsp;～&nbsp;{{
              formatDateShort(item.gettingEndAt)
            }}
          </div>
          <div>
            <small>ID:{{ item.id }}</small>
          </div>
          <div>
            <small>作成日時：{{ formatDateShort(item.createdAt) }}</small>
          </div>
          <div>
            <small>更新日時：{{ formatDateShort(item.updatedAt) }}</small>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import firebase from "firebase";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";
import { getParamsDefinitions } from "../getParamsDefinitions";

import Vue from "vue";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import VueHolder from "vue-holderjs";
import moment from "moment";
Vue.use(VueHolder);

export default {
  components: {
    datetime: Datetime,
  },
  name: "Achievement",
  data: function () {
    return {
      uids: "",
      offsetOption: "500",
      selectedAchievement: {},
      achievements: [],
      dutys: {
        withinDays: 0,
        point: 0,
        step: 0,
      },
      dutyNames: {
        withinDays: "X日以内（1～99999999）",
        point: "SPORYポイント",
        step: "歩数",
      },
      achievementTypes: [
        { value: "condition", text: "条件" },
        { value: "code", text: "コード入力" },
      ],
      conditionTypes: [
        { value: "RepQuest", text: "同一クエスト達成回数" },
        { value: "RepVideo", text: "同一動画達成回数" },
        { value: "Steps", text: "万歩計の歩数１日" },
        { value: "periodTotal", text: "ポイント・パラメータの合計" },
        {
          value: "ContinueAllLesson",
          text: "動画単体・クエスト・パーソナルすべての挑戦継続日数",
        },
        { value: "RepParsonalLesson", text: "パーソナルの達成回数" },
        { value: "Quest", text: "特定のクエスト" },
        { value: "Video", text: "特定の動画" },
      ],
      conditionTypeNames: {
        RepQuest: "同一クエスト達成回数",
        RepVideo: "同一動画達成回数",
        Steps: "万歩計の歩数１日",
        periodTotal: "ポイント・パラメータのN日の合計",
        ContinueAllLesson: "動画単体・クエスト・パーソナルすべての挑戦継続日数",
        RepParsonalLesson: "パーソナルの達成回数",
        Quest: "特定のクエスト",
        Video: "特定の動画",
      },
      conditionTypeUnitDescriotions: {
        RepQuest: "回数を1以上で入力します",
        RepVideo: "回数を1以上で入力します",
        Steps: "歩数を1以上で入力します",
        periodTotal: "ポイント・パラメータを1以上で入力します",
        ContinueAllLesson: "継続日数を1以上で入力します",
        RepParsonalLesson: "回数を1以上で入力します",
        Quest: "クエストIDを入力します",
        Video: "動画のドキュメントIDを入力します",
      },
      conditionTypeUnits: {
        RepQuest: "回",
        RepVideo: "回",
        Steps: "歩",
        periodTotal: "ポイント・パラメータ",
        ContinueAllLesson: "日継続",
        RepParsonalLesson: "回",
        Quest: "クエストID",
        Video: "動画ID",
      },
      conditionTypeInputTypes: {
        RepQuest: "number",
        RepVideo: "number",
        Steps: "number",
        periodTotal: "duty",
        ContinueAllLesson: "number",
        RepParsonalLesson: "number",
        Quest: "text",
        Video: "text",
      },
      conditionTypeImages: {
        RepQuest: "トレーナー（複数の場合一人目）",
        RepVideo: "トレーナー（複数の場合一人目）",
        Steps: "スポリーロゴ",
        periodTotal: "スポリーロゴ",
        ContinueAllLesson: "スポリーロゴ",
        RepParsonalLesson: "スポリーロゴ",
        Quest: "トレーナー（複数の場合一人目）",
        Video: "トレーナー（複数の場合一人目）",
      },
      errors: [],
      saved: false,
      deleted: false,
      keyword: "",
      editFlg: false,
      confirmMessage: null,
      messageManually: "処理中...",
      imageFileProgressMessage: null,
      imageUploadTask: null,
    };
  },
  mounted: async function () {
    let self = this;
    const p = await getParamsDefinitions();
    p.forEach((item) => {
      self.dutys[item.id] = 0;
      self.dutyNames[item.id] = item.ja;
    });
    this.getFromUrlParams();
    this.getAchievements();
  },
  methods: {
    changeSelectedAchievementType: function () {
      this.selectedAchievement.conditionValue =
        this.selectedAchievement.conditionType == "periodTotal"
          ? Object.assign({}, this.dutys)
          : null;
    },
    //achievement
    newAchievement: function () {
      this.saved = false;
      this.deleted = false;
      this.selectedAchievement = {
        id: "",
        name: "",
        description: "",
        achievementType: "condition",
        conditionType: "RepQuest",
        conditionValue: null,
        eventId: "",
        code: "",
        image: null,
        imageUrl: null,
        imageFile: null,
        gettingStartAt: moment(
          moment().format("YYYY-MM-DD HH:mm:ss")
        ).toISOString(),
        gettingEndAt: moment(
          moment().format("2999-MM-DD HH:mm:ss")
        ).toISOString(),
      };
    },
    onmanually: function (achievement) {
      this.selectedAchievement = {
        id: achievement.id,
        name: achievement.name,
      };
      this.$bvModal.show("manually");
    },
    setManually: async function (_bvModalEvent) {
      console.log(_bvModalEvent);
      this.messageManually = "処理中...";
      this.$bvModal.show("set-manually");
      let data = this;
      let achievement = await firebase
        .firestore()
        .collection("achievements")
        .doc(data.selectedAchievement.id)
        .get();

      const promises = [];
      const messages = [];
      let userIds = data.uids.split(/,|\n|\t/);
      userIds.forEach((userIDt) => {
        if (userIDt && userIDt.trim()) {
          const userID = userIDt.trim();
          const p = new Promise(function (resolve, reject) {
            firebase
              .firestore()
              .collection("users")
              .doc(userID)
              .get()
              .then((userDoc) => {
                if (userDoc.exists) {
                  userDoc.ref
                    .collection("achievements")
                    .doc(achievement.id)
                    .get()
                    .then((userAchievement) => {
                      if (userAchievement.exists) {
                        messages.push(userID + "は追加済み");
                        resolve();
                        return;
                      } else {
                        userDoc.ref
                          .collection("achievements")
                          .doc(achievement.id)
                          .set({
                            createdAt:
                              firebase.firestore.FieldValue.serverTimestamp(),
                            achievement: achievement.data(),
                          })
                          .then((item) => {
                            messages.push(userID + "に追加");
                            console.log(item);
                            resolve();
                            return;
                          });
                      }
                    });
                } else {
                  messages.push(userID + "はユーザに居ませんでした");
                  reject();
                  return;
                }
              })
              .catch((e) => {
                reject();
                messages.push(userID + "でエラー：" + e);
              });
          });
          promises.push(p);
        }
      });
      await Promise.allSettled(promises);
      data.messageManually = messages.join("\n");
      const anchor = document.createElement("a");
      anchor.href =
        "data:text/csv;charset=utf-8," +
        encodeURIComponent(data.messageManually);
      anchor.target = "_blank";
      anchor.download = data.selectedAchievement.id + ".csv";
      anchor.click();

      this.$root.$emit("bv::hide::modal", "set-manually", "#btnShow");
    },
    editAchievement: function (achievement) {
      this.saved = false;
      this.deleted = false;
      this.selectedAchievement = {
        id: achievement.id,
        name: achievement.name,
        description: achievement.description.replaceAll("\\n", "\n"),
        code: achievement.code,
        eventId: achievement.eventId,
        achievementType: achievement.achievementType,
        conditionType: achievement.conditionType,
        conditionValue: achievement.conditionValue,
        imageUrl: achievement.imageUrl,
        image: achievement.image,
        gettingStartAt: achievement.gettingStartAt.toDate().toISOString(),
        gettingEndAt: achievement.gettingEndAt.toDate().toISOString(),
        imageFile: null,
        info: null,
      };

      let q = firebase.firestore().collectionGroup("achievements");
      if (achievement.achievementType) {
        q = q.where(
          "achievement.achievementType",
          "==",
          achievement.achievementType
        );
      }
      if (achievement.code) {
        q = q.where("achievement.code", "==", achievement.code);
      }
      if (achievement.conditionType) {
        q = q.where(
          "achievement.conditionType",
          "==",
          achievement.conditionType
        );
      }
      if (achievement.conditionValue) {
        q = q.where(
          "achievement.conditionValue",
          "==",
          achievement.conditionValue
        );
      }
      q.get().then((res) => {
        this.selectedAchievement.info =
          "(取得された件数:" + res.size.toString() + "  ※v8)";
      });

      this.$bvModal.show("form-modal");
    },
    downloadAchievedUsers: async function (achievement) {
      let q = firebase.firestore().collectionGroup("achievements");
      if (achievement.achievementType) {
        q = q.where(
          "achievement.achievementType",
          "==",
          achievement.achievementType
        );
      }
      if (achievement.code) {
        q = q.where("achievement.code", "==", achievement.code);
      }
      if (achievement.conditionType) {
        q = q.where(
          "achievement.conditionType",
          "==",
          achievement.conditionType
        );
      }
      if (achievement.conditionValue) {
        q = q.where(
          "achievement.conditionValue",
          "==",
          achievement.conditionValue
        );
      }
      const csv = ["id,userNo"];
      const query = await q.get();
      const promises = [];
      query.docs.forEach((item) => {
        if (item.ref.parent.parent) {
          promises.push(item.ref.parent.parent.get());
        }
      });
      console.log(promises);
      
      const all = await Promise.all(promises);
      all.forEach((d) => {
        csv.push(d.id + ',' + d.data().userNo);
      });

      const anchor = document.createElement("a");
      anchor.href =
        "data:text/csv;charset=utf-8," + encodeURIComponent(csv.join("\n"));
      anchor.target = "_blank";
      anchor.download = "achievedUsers.csv";
      anchor.click();
    },
    storeAchievement: async function (achievement) {
      let data = this;
      return new Promise(function (resolve, reject) {
        if (data.selectedAchievement.id) {
          firebase
            .firestore()
            .collection("achievements")
            .doc(data.selectedAchievement.id)
            .update(achievement)
            .then(function () {
              data.saved = true;
              resolve();
            })
            .catch(function (error) {
              reject("保存でエラーが発生しました:" + error);
            });
        } else {
          //コードはIDにつかう。完全なユニークにするため
          const doc =
            data.selectedAchievement.achievementType == "code"
              ? firebase
                  .firestore()
                  .collection("achievements")
                  .doc(data.selectedAchievement.code)
              : firebase.firestore().collection("achievements").doc();
          doc
            .set(achievement)
            .then(function () {
              data.saved = true;
              resolve();
            })
            .catch(function (error) {
              reject("保存でエラーが発生しました:" + error);
            });
        }
      });
    },
    putStorageimage: function (achievement) {
      let data = this;
      return new Promise(function (resolve, reject) {
        const storageRef = firebase.storage().ref();
        data.imageUploadTask = storageRef
          .child(achievement.image)
          .put(
            data.selectedAchievement.imageFile,
            data.selectedAchievement.imageFile.type
          );

        data.imageUploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED,
          function (snapshot) {
            data.imageFileProgressMessage =
              (
                (snapshot.bytesTransferred / snapshot.totalBytes) *
                100
              ).toString() + "%";
          },
          function (error) {
            switch (error.code) {
              case "storage/canceled":
                break;
              default:
                alert(
                  "イメージアップロード中にクラウドストレージにおけるその他のエラーが発生しました。:" +
                    error.code
                );
                break;
            }
            reject();
          },
          function () {
            data.imageUploadTask.snapshot.ref
              .getDownloadURL()
              .then(function (downloadURL) {
                resolve(downloadURL);
              });
          }
        );
      });
    },
    saveAchievement: async function () {
      this.imageFileProgressMessage = null;
      let s = moment(this.selectedAchievement.gettingStartAt);
      let e = moment(this.selectedAchievement.gettingEndAt);
      if (e.isSameOrBefore(s)) {
        alert("限定時刻の設定は開始より終了を未来にしてください。:");
        return;
      }

      if (
        this.selectedAchievement.conditionValue !== null &&
        typeof this.selectedAchievement.conditionValue === "object"
      ) {
        let allZero = true;
        Object.keys(this.selectedAchievement.conditionValue).forEach((key) => {
          this.selectedAchievement.conditionValue[key] = parseInt(
            this.selectedAchievement.conditionValue[key],
            10
          );
          if (key == "withinDays") {
            if (
              this.selectedAchievement.conditionValue[key] < 1 ||
              this.selectedAchievement.conditionValue[key] > 99999999
            ) {
              alert("条件の日数は1～99999999の間で入力してください。:");
              return;
            }
          } else {
            if (this.selectedAchievement.conditionValue[key] > 0) {
              allZero = false;
            }
          }
        });
        if (allZero) {
          alert("条件のどれかは1以上で入力してください。:");
          return;
        }
      }

      if (this.selectedAchievement.id) {
        this.updateAchievement();
      } else {
        this.createAchievement();
      }
    },
    createAchievement: async function () {
      let data = this;
      let achievement = {
        name: data.selectedAchievement.name,
        description: data.selectedAchievement.description,
        achievementType: data.selectedAchievement.achievementType,
        eventId: data.selectedAchievement.eventId,
        gettingStartAt: moment(
          data.selectedAchievement.gettingStartAt
        ).toDate(),
        gettingEndAt: moment(data.selectedAchievement.gettingEndAt).toDate(),
        image: data.selectedAchievement.imageFile
          ? "achievements/" + data.getHash(64)
          : null,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      };

      if (achievement.achievementType == "code") {
        achievement.code = data.selectedAchievement.code;
      } else if (achievement.achievementType == "condition") {
        achievement.conditionType = data.selectedAchievement.conditionType;
        achievement.conditionValue = data.selectedAchievement.conditionValue;
      }

      try {
        if (data.selectedAchievement.imageFile) {
          achievement.imageUrl = await this.putStorageimage(achievement);
        }
        this.imageFileProgressMessage = null;
        await this.storeAchievement(achievement);
        this.$bvModal.hide("form-modal");
        this.getAchievements();
      } catch (err) {
        this.imageFileProgressMessage = null;
        alert("エラーが発生しました:" + err);
      }
    },
    updateAchievement: async function () {
      let data = this;
      let achievement = {
        name: data.selectedAchievement.name,
        description: data.selectedAchievement.description,
        achievementType: data.selectedAchievement.achievementType,
        eventId: data.selectedAchievement.eventId,
        imageUrl: data.selectedAchievement.imageUrl,
        image: data.selectedAchievement.image,
        gettingStartAt: moment(
          data.selectedAchievement.gettingStartAt
        ).toDate(),
        gettingEndAt: moment(data.selectedAchievement.gettingEndAt).toDate(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      };

      if (achievement.achievementType == "code") {
        achievement.code = data.selectedAchievement.code;
      } else if (achievement.achievementType == "condition") {
        achievement.conditionType = data.selectedAchievement.conditionType;
        achievement.conditionValue = data.selectedAchievement.conditionValue;
      }

      try {
        if (data.selectedAchievement.imageFile) {
          achievement.imageUrl = await this.putStorageimage(achievement);
        }
        this.imageFileProgressMessage = null;
        await this.storeAchievement(achievement);
        this.$bvModal.hide("form-modal");
        this.getAchievements();
      } catch (err) {
        this.imageFileProgressMessage = null;
        alert("もう一度ご送信ください エラー:" + err);
      }
    },
    testAchievement: async function () {
      const functions = firebase.app().functions("asia-northeast1");
      const requestAchievementTypeCode = functions.httpsCallable(
        "requestAchievementTypeCode"
      );
      const msg = await requestAchievementTypeCode({ code: "abc" });
      console.log(msg);
    },
    onDelete: function (achievement) {
      this.saved = false;
      this.deleted = false;
      this.$bvModal
        .msgBoxConfirm(
          "「" +
            achievement.name +
            "」を削除します。この操作はやり直せません。削除しますか？"
        )
        .then((result) => {
          if (!result) {
            return;
          }
          firebase
            .firestore()
            .collection("achievements")
            .doc(achievement.id)
            .delete()
            .then(function () {
              this.getAchievements();
              /*
                  storageRef
                    .child(achievement.image)
                    .delete()
                    .then(function() {
                      data.getAchievements();
                      data.deleted = true;
                    })
                    .catch(function(error) {
                      alert("画像の削除時にエラー（無視してOKです）: ", error);
                    });
              */
            })
            .catch(function (error) {
              console.error(error);
            });
        });
    },
    cancelSaving: function () {
      if (this.imageUploadTask != null) {
        this.imageUploadTask.cancel();
      }
      this.$bvModal.hide("upload-files");
    },
    getAchievements: function () {
      let self = this;
      const query = firebase.firestore().collection("achievements");
      query
        .orderBy("createdAt", "desc")
        .limit(Number(this.offsetOption))
        .get()
        .then(function (querySnapshot) {
          let datas = [];
          querySnapshot.forEach(function (doc) {
            let d = doc.data();
            d.id = doc.id;

            d.timeStatus = self.checkTimeStatus(
              d.gettingStartAt,
              d.gettingEndAt
            );
            datas.push(d);
          });
          self.achievements = datas;
        })
        .catch(function (error) {
          console.error("Error getting documents: ", error);
        });
    },
    getFromUrlParams: function () {
      if (this.$route.query["c"]) {
        this.offsetOption = this.$route.query["c"];
      }

      if (this.$route.query["o"]) {
        this.sortOrderOption = this.$route.query["o"];
      }
    },
    routerPush() {
      let pushRouteParam = { c: this.offsetOption, o: this.sortOrderOption };
      this.$router.push({ path: "", query: pushRouteParam });
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.getAchievements();
    this.saved = false;
    this.deleted = false;
    next();
  },
};
</script>
