<template>
  <div
    class="border bg mx-auto"
    :style="
       (category.published ?
         '' :
         'filter: grayscale(100%);') +
      'background-image: url(' +
      'https://storage.googleapis.com/' +
      bucket +
      '/' +
      category.thumbnailFileName +
      '?' +
      moment().valueOf() +
      ')'
    "
  >
    <div class="sample1oya"></div>
  </div>
</template>

<script>
import firebase from "firebase";
import "firebase/storage";
import moment from "moment";
export default {
  props: ["category"],
  data() {
    return {
      bucket: firebase.storage().ref().bucket,
      moment: moment
    };
  },
  mounted: function() {},
  computed: {},
  methods: {}
};
</script>

<style type="text/css">
.bg {
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  height: 20vw;
  width: 20vw;
  background-size: cover;
}
.sample1oya {
  padding: 20px;
}
.sample1 {
  display: inline-block;
  font-size: 120%;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 2px 2px 10px #777, -2px 2px 10px #777, 2px -2px 10px #777,
    -2px -2px 10px #777;
}
.sample2 {
  display: inline-block;
  font-size: 80%;
  color: #ffffff;
  text-shadow: 2px 2px 10px #777, -2px 2px 10px #777, 2px -2px 10px #777,
    -2px -2px 10px #777;
}
</style>