<template>
  <div class="home">
    <b-modal
      size="sm"
      cancel-only
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      id="upload-files"
      title="アップロード中です"
      hide-footer
    >
    </b-modal>
    <div v-if="saved" class="alert alert-primary" role="alert">
      保存しました。
    </div>
    <div v-if="deleted" class="alert alert-danger" role="alert">
      削除しました。
    </div>
    <b-modal
      size="xl"
      id="form-modal"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      v-bind:title="
        !selectedVideo.title
          ? '新規作成'
          : '「' + selectedVideo.title + '」を編集'
      "
      hide-footer
    >
      <div v-if="thumbnailFileProgressMessage || videoFileProgressMessage">
        <p v-if="thumbnailFileProgressMessage">
          サムネイル：{{
            thumbnailFileProgressMessage
              ? thumbnailFileProgressMessage
              : "変更なし"
          }}
        </p>
        <p>
          動画：{{
            videoFileProgressMessage ? videoFileProgressMessage : "変更なし"
          }}
        </p>
        <div class="text-right">
          <button
            class="btn btn-sm btn-outline-secondary col-5"
            @click="cancelSaving()"
          >
            キャンセル
          </button>
        </div>
      </div>
      <div v-else>
        <b-form @submit.prevent="saveVideo">
          <b-form-group class="form-group">
            <div class="input-group text-center">
              <div class="mr-2 col-3 text-right">タイトル：</div>
              <b-form-textarea
                rows="3"
                type="text"
                class="form-control col-9 mt-1"
                required="required"
                v-model.trim="selectedVideo.title"
                placeholder="動画タイトル"
              ></b-form-textarea>
            </div>
            <div class="input-group text-center mt-4">
              <div class="mt-2 mr-2 col-3 text-right">トレーナー：</div>
              <div class="col-8 text-left">
                <b-form-checkbox-group
                  v-model="selectedVideo.trainers"
                  :options="trainers"
                  value-field="id"
                  text-field="titleAndName"
                ></b-form-checkbox-group>
              </div>
            </div>
            <div class="input-group text-center mt-4">
              <div class="mr-2 col-3 text-right">強度目安(0-100)：</div>
              <input
                type="number"
                class="form-control col-2 mt-1 text-right"
                required="required"
                min="0"
                max="100"
                step="1"
                v-model="selectedVideo.strength"
              />
            </div>

            <div class="input-group text-center mt-4">
              <div class="mr-2 col-3 text-right">概要：</div>
              <b-form-textarea
                id="textarea"
                required="required"
                v-model.trim="selectedVideo.description"
                placeholder="Enter something..."
                rows="3"
                max-rows="20"
                class="col-9 mt-1"
              ></b-form-textarea>
            </div>

            <div class="input-group text-center mt-4">
              <div class="col-3 text-right mt-2">タグ：</div>
              <div class="col-9 px-2 text-left">
                <b-form-checkbox-group
                  v-model="selectedVideo.tags"
                  :options="tags"
                  class="mb-3"
                  value-field="id"
                  text-field="name"
                ></b-form-checkbox-group>
                <b-input-group size="sm" class="inline mb-3 col-6 float-right">
                  <b-form-input
                    v-model="newTag"
                    placeholder="新規タグ"
                  ></b-form-input>
                  <b-input-group-append>
                    <a class="btn btn-sm btn-primary" @click="createTag()">
                      追加
                    </a>
                  </b-input-group-append>
                </b-input-group>
              </div>
            </div>

            <div
              v-if="selectedVideo.thumbnailFileName"
              class="col-12 text-center mt-4 text-center"
            >
              <strong
                >以下は動画を差し替えるときのみアップロードしてください。</strong
              >
            </div>

            <div class="input-group text-center mt-4">
              <div class="col-3 text-right mt-2">サムネイル画像：</div>
              <div class="col-9 px-2">
                <b-form-file
                  v-model="selectedVideo.thumbnailFile"
                  :state="Boolean(selectedVideo.thumbnailFile)"
                  accept=".webp, .jpg, .jpeg, .png, .gif"
                  placeholder="webp,jpeg,png,gifを選択かドロップ"
                  drop-placeholder="Drop file here..."
                  :required="selectedVideo.id == ''"
                ></b-form-file>
                ※768x432pxのみ。splitエラーが出たときはwebページを更新してください。
              </div>
            </div>

            <div class="input-group text-center mt-4">
              <div class="col-3 text-right mt-2">動画：</div>
              <div class="col-9 px-2">
                <b-form-file
                  v-model="selectedVideo.videoFile"
                  :state="Boolean(selectedVideo.videoFile)"
                  placeholder="mp4を選択かドロップ"
                  accept=".mp4"
                  drop-placeholder="Drop file here..."
                  :required="selectedVideo.id == ''"
                ></b-form-file>
                ※768x432pxのみ。splitエラーが出たときはwebページを更新してください。
              </div>
            </div>
          </b-form-group>

          <div class="input-group row text-right my-2">
            <b-form-checkbox
              :disabled="
                !selectedVideo.id ||
                ((!selectedVideo.chapters || !selectedVideo.chapters.length) &&
                  !selectedVideo.published)
              "
              v-model="selectedVideo.published"
              class="offset-3 col-2 p-0"
              size="lg"
              >公開</b-form-checkbox
            >
            <button class="btn btn-primary offset-1 col-2" type="submit">
              保存
            </button>
            <a
              class="btn btn-outline-secondary offset-1 col-2"
              @click="$bvModal.hide('form-modal')"
            >
              キャンセル
            </a>
          </div>
          <b-row class="justify-content-center py-3">
            <small>
              ※新規作成か非公開状態だとチャプタを編集してから公開出来ます。<br />
              下位互換のためチャプタなしで非公開にするとチャプタを追加するまで公開にしなおせません。<br />
            </small>
          </b-row>
        </b-form>

        <div
          v-if="confirmMessage"
          class="alert alert-info"
          role="alert"
          style="white-space: pre-wrap; word-wrap: break-word"
        >
          {{ confirmMessage.trim() }}
        </div>
      </div>
    </b-modal>
    <b-modal
      size="huge"
      id="form-chapters"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      v-bind:title="selectedVideo.title + ' チャプタ編集'"
      hide-footer
    >
      <b-form @submit.prevent="" ref="form-chapter">
        <b-form-group class="form-group text-center">
          <video
            class="w-100 video-fluid z-depth-1"
            style="max-height: 300px"
            ref="mov"
            crossOrigin="anonymous"
            controls
          >
            <source v-bind:src="selectedVideo.videoUrl" type="video/mp4" />
          </video>
          <b-button
            ref="capture"
            variant="outline-info"
            class="col-2"
            @click="capture(selectedChapter)"
          >
            キャプチャ
          </b-button>
          <b-button
            variant="outline-info"
            class="offset-1 col-2"
            type="button"
            @click="setTime()"
          >
            時間をセット
          </b-button>
        </b-form-group>
        <b-row class="py-0 pt-2">
          <b-col class="text-center py-0">
            <small>※ブラウザはChrome/Edgeのみ対応です！</small><br />
            <small
              >※キャプチャ（カメラアイコン）は省略時は自動セットされます。</small
            ><br />
            <small
              >※チャプタは時間順にセットしてください。並び替えが出来ません！</small
            ><br />
            <small
              >(終了時間は自動的に次のチャプタの開始時間か、動画終了時間になります)</small
            ><br />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="3">
            <b-img
              :src="
                selectedChapter.base64Image
                  ? selectedChapter.base64Image
                  : '/img/camera.png'
              "
              class="w-100 border"
            ></b-img>
          </b-col>
          <b-col cols="9">
            <b-row>
              <b-input-group
                prepend="開始時間"
                class="mt-1 col-lg-6 col-md-6 col-sm-6"
              >
                <b-form-input
                  @input="changeSelectedChapter()"
                  v-model="selectedChapter.startTime"
                  ref="start-time"
                  class="text-right"
                  required
                  type="text"
                  pattern="^[0-9][0-9]:[0-9][0-9]\.[0-9][0-9][0-9]$"
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    variant="info"
                    @click="playFrom(selectedChapter.startTime)"
                    >確認再生</b-button
                  >
                </b-input-group-append>
              </b-input-group>
              <b-input-group prepend="見出し" class="mt-1 col-6">
                <b-form-input
                  v-model="selectedChapter.title"
                  ref="chapter-title"
                  type="text"
                  step="0.000001"
                  required
                  @input="changeSelectedChapter()"
                  placeholder="休憩,説明,バーピーなど"
                ></b-form-input>
              </b-input-group>
            </b-row>
            <b-row class="pt-0">
              <b-col class="text-left pt-0 mt-0">
                <small
                  >※開始時間」は「分：秒.ミリ秒」です。「時：分.秒」ではありません。</small
                >
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-center pt-1">
                <small>※以下のパラメータは0~100で入力してください。</small>
              </b-col>
            </b-row>
            <b-row class="pl-3 pb-3 pr-3 pt-0">
              <b-input-group
                size="sm"
                v-for="(param, paramKey) in paramsDefinitions"
                :key="paramKey"
                class="col-4 p-0"
              >
                <b-input-group-prepend>
                  <b-input-group-text class="text-right rounded-0">{{
                    param.ja.padStart(2, "\u3000")
                  }}</b-input-group-text>
                </b-input-group-prepend>
                <b-form-input
                  v-model.number="selectedChapter.params[param.id]"
                  class="text-right rounded-0"
                  type="number"
                  min="0"
                  max="100"
                  step="1"
                  @input="changeSelectedChapter()"
                  required
                ></b-form-input>
              </b-input-group>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="justify-content-center pt-2 pb-3">
          <b-button
            :disabled="!selectedChapterChanged"
            variant="success"
            class="col-2"
            type="button"
            @click="addChapter()"
          >
            ↓&nbsp;追加
          </b-button>
        </b-row>
      </b-form>
      <hr />
      <b-form @submit.prevent="" ref="form-chapters">
        <b-row
          class="justify-content-center"
          v-if="selectedVideo.chapters.length == 0"
        >
          チャプタはまだありません。追加していきましょう。
        </b-row>
        <b-row
          v-for="(item, key) in selectedVideo.chapters"
          :key="key"
          class="justify-content-center pl-3 pb-1 pr-3 pt-1"
        >
          <b-col cols="2"
            ><b-img
              :src="
                item.base64Image
                  ? item.base64Image
                  : 'https://storage.googleapis.com/' +
                    bucket +
                    '/' +
                    item.thumbnailFileName +
                    '?' +
                    cacheTail
              "
              class="w-100"
            ></b-img
            ><b-input-group
              class="pt-1 text-center justify-content-center"
              size="sm"
            >
              <b-button
                size="sm"
                variant="outline-info"
                @click="capture(item)"
                class="mr-2"
                >キャプチャ</b-button
              >
              <b-button
                v-if="item.base64Image && item.thumbnailFileName"
                size="sm"
                variant="outline-info"
                @click="revertCapture(item)"
                >戻す</b-button
              >
            </b-input-group>
          </b-col>
          <b-col cols="10">
            <b-row class="pb-2">
              <b-input-group class="col-2 pr-0">
                
                <b-form-input
                  v-model="item.startTime"
                  ref="start-time"
                  class="text-right"
                  size="sm"
                  required
                  type="text"
                  @input="changeEditingChapter()"
                  pattern="^[0-9][0-9]:[0-9][0-9]\.[0-9][0-9][0-9]$"
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    size="sm"
                    variant="info"
                    @click="playFrom(item.startTime)"
                    >確認</b-button
                  >
                </b-input-group-append>
              </b-input-group>
              <b-input-group class="col-1">
                ～
              </b-input-group>
              <b-input-group class="col-3">
                <b-form-input
                  v-model="item.title"
                  ref="chapter-title"
                  type="text"
                  required
                  size="sm"
                  @input="changeEditingChapter()"
                  placeholder="休憩,説明,バーピーなど"
                ></b-form-input>
              </b-input-group>
               <b-input-group class="col-3">
                  <b-form-checkbox
                    type="checkbox"
                    v-model="item.unPersonalTop"
                    class="mr-n2 mb-n1"
                    @input="changeEditingChapter()"
                  >
                  <small>パーソナルの先頭に出さない</small>
                  </b-form-checkbox>
              </b-input-group>
              <b-input-group
                class="col-3 text-center justify-content-center"
                size="sm"
              >
                <b-input-group-prepend is-text>
                  <b-form-checkbox
                    type="checkbox"
                    v-model="item.remove"
                    class="mr-n2 mb-n1"
                  >
                  </b-form-checkbox>
                </b-input-group-prepend>
                <b-input-group-append>
                  <b-button
                    :disabled="!item.remove"
                    variant="danger"
                    @click="deleteChapter(key)"
                    >チャプタ削除</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </b-row>
            <b-row>
              <b-input-group
                size="sm"
                v-for="(param, paramKey) in paramsDefinitions"
                :key="paramKey"
                class="col-4 p-0"
              >
                <b-input-group-prepend>
                  <b-input-group-text class="text-right rounded-0">{{
                    param.ja.padStart(2, "\u3000")
                  }}</b-input-group-text>
                </b-input-group-prepend>
                <b-form-input
                  class="text-right rounded-0"
                  type="number"
                  min="0"
                  max="100"
                  size="sm"
                  step="1"
                  required
                  @input="changeEditingChapter()"
                  v-model.number.lazy="item.params[param.id]"
                ></b-form-input>
              </b-input-group>
            </b-row>
          </b-col>
        </b-row>
        <hr />
        <b-row class="justify-content-center pt-2 pb-3">
          ご注意ください。チャプタの時間は「ミリ秒」です。
        </b-row>
        <b-row class="justify-content-center pb-3">
          <b-button
            variant="primary"
            class="col-2"
            :disabled="!hasEditedChapter"
            @click="saveChapters()"
          >
            保存
          </b-button>
          <a
            class="btn btn-outline-secondary offset-1 col-2"
            @click="cancelEditChapters()"
          >
            キャンセル
          </a>
        </b-row>
      </b-form>
    </b-modal>
    <div class="pt-3">
      <b-form inline>
        <label class="ml-auto">絞り込み:</label>
        <b-form-select
          id="offset-option"
          :options="offsetOptions"
          v-model="offsetOption"
          class="m-md-2 col-2"
          variant="outline-secondary"
          @change="searchTimeOut(true)"
        >
        </b-form-select>
        <b-form-select
          :options="sortOrderOptions"
          v-model="sortOrderOption"
          class="m-md-2 col-4 col-md-2"
          variant="outline-secondary"
          @change="searchTimeOut(true)"
        >
        </b-form-select>
        <b-form-input
          type="text"
          class="m-md-2 col-3"
          placeholder="フリーワード"
          variant="outline-secondary"
          v-model="keyword"
          @keyup="searchTimeOut(true)"
          @keydown.self.prevent.enter
        ></b-form-input>
      </b-form>
      <b-form inline>
        <label class="ml-auto"></label>
        <b-form-select
          :options="trainers"
          v-model="selectedTrainer"
          value-field="id"
          text-field="name"
          class="m-md-2 col-5 col-md-3"
          variant="outline-secondary"
          @change="searchTimeOut(true)"
        >
          <template #first>
            <b-form-select-option :value="null"
              >-- トレーナー --</b-form-select-option
            >
          </template>
        </b-form-select>
        <b-form-select
          :options="tags"
          v-model="selectedTag"
          value-field="id"
          text-field="name"
          class="m-md-2 col-5 col-md-3"
          variant="outline-secondary"
          @change="searchTimeOut(true)"
        >
          <template #first>
            <b-form-select-option :value="null"
              >-- タグ --</b-form-select-option
            >
          </template>
        </b-form-select>
      </b-form>
    </div>
    <div class="text-right p-3">
      <button
        class="btn btn-primary col-3"
        @click="
          confirmMessage = null;
          newVideo();
          $bvModal.show('form-modal');
        "
      >
        ＋動画追加
      </button>
      <button
        v-if="!$production"
        class="btn btn-primary col-3 ml-3"
        @click="
          confirmMessage = null;
          createMock();
        "
      >
        ＋サンプル作成
      </button>
      <!--
      <b-button
        v-if="!$production"
            variant="danger"
            class="col-2"
            type="button"
            @click="fixTemp()"
          >
            maxparams修正
      </b-button>
      -->
    </div>
    <div class="text-left px-3">※削除は非公開にしてから行えます。</div>
    <div class="text-left px-3">※60分以上の動画には対応していません。</div>
    <div class="text-left px-3">
      ※非公開から公開に変更、あるいは公開設定で新規作成した場合、トレーナーをお気に入りに入れているユーザに通知が行われます。
    </div>
    <table class="table">
      <tr>
        <th class="align-middle text-center" style="width: 8%">編集 / 削除</th>
        <th class="align-middle text-center" style="width: 40%">動画</th>
        <th class="align-middle text-center" style="width: 52%">情報</th>
      </tr>
      <tr  v-for="(item, key) in filteredVideos()" :key="key">
        <td colspan="3">
          <table>
            <tr>
              <td class="align-middle text-center border-0" style="width: 8%">
                <div class="pt-1 pb-3">
                  <span v-if="item.published" class="btn btn-info">
                    公開済
                  </span>
                  <span v-else class="btn btn-warning"> 下書き </span>
                </div>
                <div class="pt-4">
                  <button class="btn btn-primary" @click="editVideo(item)">
                    データ<br />編集
                  </button>
                </div>
                <div class="pt-4">
                  <button class="btn btn-success" @click="editChapters(item)">
                    チャプタ<br />編集
                  </button>
                </div>
                <div class="pt-3" v-if="!item.published">
                  <button
                    class="btn btn-danger"
                    @click.stop.prevent="onDelete(item)"
                  >
                    削除
                  </button>
                </div>
              </td>
              <td class="align-middle text-center border-0" style="width: 40%">
                <video
                  @loadedmetadata="setDuration($event, item)"
                  :ref="'video-' + item.id"
                  class="w-100 video-fluid z-depth-1"
                  controls
                  v-bind:poster="'https://storage.googleapis.com/' +
                    bucket +
                    '/' +
                    item.thumbnailFileName"
                >
                  <source v-bind:src="'https://storage.googleapis.com/' +
                    bucket +
                    '/' +
                    item.videoFileName" type="video/mp4" />
                </video>
              </td>
              <td class="align-middle border-0" style="width: 52%">
                <h3 style="white-space: pre-wrap; word-wrap: break-word">
                  {{ item.title }}
                </h3>
                <div v-if="trainers.length > 0">
                  <div v-for="trainer in item.trainers" :key="trainer.id">
                    {{ trainers.find((g) => g.id == trainer.id).name }}
                  </div>
                </div>
                <small style="white-space: pre-wrap; word-wrap: break-word">{{
                  item.description
                    ? item.description.replaceAll("\\n", "\n")
                    : ""
                }}
                </small>
                <div v-if="tags.length > 0">
                  <span v-for="tag in item.tags" v-bind:key="tag.id">
                    <b-badge
                      class="align-middle"
                      style="margin-bottom: 3px; margin-left: 5px"
                      v-if="tags.find((g) => g.id == tag.id)"
                    >
                      {{ tags.find((g) => g.id == tag.id).name }}
                    </b-badge>
                  </span>
                </div>
                <b-row>
                  <b-col cols="6" class="text-left">
                    <div v-if="item.strength > 0">
                      <small>強度目安：{{ item.strength }}</small>
                    </div>
                    <div v-if="!isNaN(item.paramsTotal)">
                      <small>パラメータ合計：{{ item.paramsTotal }}</small>
                    </div>
                    <div>
                      <small
                        >動画時間：{{
                          getHumanTimeFromDuration(item.duration)
                        }}</small
                      >
                    </div>
                    <div>
                      <small>作成日時：{{ formatDate(item.createdAt) }}</small>
                    </div>
                    <div>
                      <small>更新日時：{{ formatDate(item.updatedAt) }}</small>
                    </div>
                  </b-col>
                  <b-col cols="6" v-if="item.params">
                    <b-row
                      v-for="(param, key4) in paramsDefinitions"
                      :key="key4"
                    >
                      <b-col cols="6" class="text-right p-0">
                        <small>{{ param.ja.padStart(2, "\u3000") }}: </small>
                      </b-col>
                      <b-col cols="4" class="text-right p-0">
                        <small v-if="item.params[param.id] != undefined">{{
                          item.params[param.id].toString()
                        }}</small>
                        <small v-else>設定中</small>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </td>
            </tr>
            <tr>
              <td colspan="3" class="border-0 text-left">
                    <small>
                      ドキュメントID： {{ item.id }}<br/>
                      ファイル： {{ item.videoFileName }}
                    </small>
                </td>
            </tr>
            <tr>
              <td colspan="3" class="border-0">
                <b-row>
                  <b-col
                    v-for="(chapter, key2) in item.chapters"
                    :key="key2"
                    class="
                      border
                      justify-content-center
                      pl-3
                      pb-3
                      pr-3
                      pt-1
                      col-md-3 col-sm-4
                    "
                    v-bind:class="[chapter.unPersonalTop ?  'bg-light' : '', '']"
                  >
                    <b-row class="justify-content-center">
                      <b-col cols="12">
                        <b-img
                          class="w-100"
                          :src="
                            'https://storage.googleapis.com/' +
                            bucket +
                            '/' +
                            chapter.thumbnailFileName +
                            '?' +
                            cacheTail
                          "
                        ></b-img>
                      </b-col>
                    </b-row>
                    <b-row class="justify-content-center p-0">
                      <b-col cols="12" class="p-1">
                        <small
                          >ch.{{ (key2 + 1).toString().padStart(2, "0") }} -
                          <strong>{{ chapter.title }}</strong>
                        </small></b-col
                      >
                    </b-row>
                    <b-row class="justify-content-center">
                      <b-col cols="12" class="p-0">
                        <small
                          >{{
                            getHumanPrecisionTimeFromDuration(
                              chapter.startTime
                            )
                          }}～{{
                            getHumanPrecisionTimeFromDuration(chapter.endTime)
                          }}</small
                        >
                      </b-col>
                    </b-row>
                    <b-row class="pt-2" v-once>
                      <b-col
                        cols="3"
                        class="py-2"
                        v-for="(param, key3) in paramsDefinitions"
                        :key="key3"
                      >
                        <b-row>
                          <b-col cols="6" class="text-right p-0">
                            <small
                              >{{ param.ja.padStart(2, "\u3000") }}:
                            </small>
                          </b-col>
                          <b-col cols="4" class="text-right p-0">
                            <small
                              v-if="chapter.params[param.id] != undefined"
                              >{{ chapter.params[param.id].toString() }}</small
                            >
                            <small v-else>設定中</small>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <b-row class="justify-content-center" v-if="chapter.unPersonalTop">
                      <b-col cols="12" class="p-0 text-danger">
                        <small>パーソナル先頭に出さない</small>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import firebase from "firebase";
import "firebase/firestore";
import "firebase/storage";
import moment from "moment";
import { getParamsDefinitions } from "../getParamsDefinitions";
import { getTags } from "../getTags";
import { getTrainers } from "../getTrainers";

import Vue from "vue";
import VueHolder from "vue-holderjs";
Vue.use(VueHolder);

export default {
  name: "Video",
  data: function() {
    return {
      hostname: window.location.hostname,
      bucket: firebase.storage().ref().bucket,
      video: {
        id: null,
        title: null,
        tags: [],
        trainers: []
      },
      offsetOption: "10",
      offsetOptions: [
        { value: "10", text: "10件" },
        { value: "30", text: "30件" },
        { value: "50", text: "50件" }
      ],
      sortOrderOption: "updatedAt,desc",
      sortOrderOptions: [
        { value: "updatedAt,desc", text: "最新更新順" },
        { value: "createdAt,desc", text: "最新作成順" },
        { value: "strength,asc", text: "強度昇順" },
        { value: "strength,desc", text: "強度降順" },
        { value: "paramsTotal,asc", text: "パラメータ合計昇順" },
        { value: "paramsTotal,desc", text: "パラメータ合計降順" }
      ],
      hasEditedChapter: false,
      selectedTrainer: null,
      selectedTag: null,
      newTag: "",
      selectedVideo: {
        chapters: [],
        params: []
      },
      videos: [],
      tags: [],
      trainers: [],
      errors: [],
      saved: false,
      deleted: false,
      keyword: "",
      editFlg: false,
      confirmMessage: null,
      thumbnailFileProgressMessage: null,
      videoFileProgressMessage: null,
      thumbnailUploadTask: null,
      videoUploadTask: null,
      chaptersUploadTask: null,
      selectedChapterChanged: false,
      selectedChapter: {
        startTime: "00:00.000",
        unPersonalTop: false,
        params: {}
      },
      paramsDefinitions: [],
      moment: moment,
      timer: null,
      needReLoad: true,
      cacheTail: moment().valueOf()
    };
  },
  mounted: async function() {
    this.tags = await getTags();
    this.trainers = await getTrainers();
    this.paramsDefinitions = await getParamsDefinitions();
    this.getVideos();
    this.getFromUrlParams();
    this.newChapter();
    this.paramsDefinitions.forEach(param => {
      this.sortOrderOptions.push({
        value: "maxParams." + param.id + ",asc",
        text: param.ja + "昇順"
      });
      this.sortOrderOptions.push({
        value: "maxParams." + param.id + ",desc",
        text: param.ja + "降順"
      });
    });
  },
  methods: {
    setChapterEndTimeHuman: function() {
      this.selectedVideo.chapters.forEach((chapter, index) => {
        if (this.selectedVideo.chapters.length - 1 == index) {
          chapter.endTime = this.getHumanPrecisionTimeFromDuration(
            this.$refs["mov"].duration
          );
        } else {
          chapter.endTime = this.getHumanPrecisionTimeFromDuration(
            this.getDurationFromHumanTime(
              this.selectedVideo.chapters[index + 1].startTime
            ) - 0.001
          );
        }
      });
    },
    newChapter: function(time) {
      this.selectedChapterChanged = false;
      this.selectedChapter = {
        startTime: time ? time : "00:00.000",
        unPersonalTop: false,
        params: {}
      };
      this.paramsDefinitions.forEach(item => {
        this.selectedChapter.params[item.id] = item.defaultValue;
      });
    },
    addChapter: function() {
      let self = this;
      if (!this.$refs["form-chapter"].reportValidity()) {
        return;
      }
      if (!self.selectedChapter.base64Image) {
        const d = this.getDurationFromHumanTime(self.selectedChapter.startTime);
        this.$refs["mov"].currentTime = d;
        this.capture(self.selectedChapter);
      }
      if (!self.selectedVideo.chapters) {
        self.selectedVideo.chapters = [];
      } else {
        if (
          self.selectedVideo.chapters[self.selectedVideo.chapters.length - 1] &&
          this.getDurationFromHumanTime(
            self.selectedVideo.chapters[self.selectedVideo.chapters.length - 1]
              .startTime
          ) >= this.getDurationFromHumanTime(self.selectedChapter.startTime)
        ) {
          alert(
            "最後のチャプタの開始時間より前の開始時間を指定することは出来ません"
          );
          return;
        }

        if (
          self.selectedVideo.chapters[self.selectedVideo.chapters.length - 1] &&
          this.getDurationFromHumanTime(
            self.selectedVideo.chapters[self.selectedVideo.chapters.length - 1]
              .startTime
          ) >=
            this.getDurationFromHumanTime(self.selectedChapter.startTime) - 3
        ) {
          if (
            !confirm(
              "本当に3秒未満のチャプタを追加しますか？\n「開始時間」は「分：秒.ミリ秒」です。\n「時：分.秒」ではありません。"
            )
          ) {
            return;
          }
        }
      }
      self.hasEditedChapter = true;
      self.selectedVideo.chapters.push(self.selectedChapter);
      this.setChapterEndTimeHuman();

      self.newChapter();
      this.$forceUpdate();
    },
    fixTemp: function() {
        let self = this;
        self.videos.forEach((_video) => {
          let video = { maxParams: {}, paramsTotal: 0};
          if (_video.chapters) {
            _video.chapters.forEach((chapter) => {
              self.paramsDefinitions.forEach(param => {
                if (!video.maxParams[param.id]) {
                  video.maxParams[param.id] = 0;
                }
                let p = parseInt(chapter.params[param.id], 10);
                video.paramsTotal += p;
                if (video.maxParams[param.id] < p) {
                  video.maxParams[param.id] = p;
                }
              });
            });
            firebase
              .firestore()
              .collection("videos")
              .doc(_video.id)
              .update(video)
              .then(function() {
                console.log(_video.id);
              });
          }
        });
    },
    deleteChapter: function(index) {
      this.selectedVideo.chapters.splice(index, 1);
      this.hasEditedChapter = true;
    },
    editChapters: function(video) {
      this.hasEditedChapter = false;
      this.setSelectedVideo(video);

      if (!this.selectedVideo.chapters) {
        this.selectedVideo.chapters = [];
      }
      this.selectedVideo.chapters.forEach(e => {
        e.startTime = this.getHumanPrecisionTimeFromDuration(e.startTime);
        e.endTime = this.getHumanPrecisionTimeFromDuration(e.endTime);
      });
      this.newChapter();
      this.$bvModal.show("form-chapters");
    },
    changeSelectedChapter: function() {
      this.selectedChapterChanged = true;
    },
    changeEditingChapter: function() {
      this.hasEditedChapter = true;
    },
    cancelEditChapters: async function() {
      if (!this.hasEditedChapter) {
        this.$bvModal.hide("form-chapters");
      }
      if (
        (this.selectedChapterChanged || this.hasEditedChapter) &&
        confirm("編集を破棄しますか？")
      ) {
        this.$bvModal.hide("form-chapters");
      }
    },
    saveChapters: async function() {
      let data = this;

      if (!this.$refs["form-chapters"].reportValidity()) {
        return;
      }
      if (
        data.selectedChapterChanged &&
        !confirm("チャプタの「追加」をせずに保存しますか？")
      ) {
        return;
      }
      let video = {
        chapters: [],
        params: {},
        maxParams: {},
        paramsTotal: 0,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      };

      data.paramsDefinitions.forEach(param => {
        video.params[param.id] = 0;
      });
      try {
        data.selectedVideo.chapters.forEach((chapter, index) => {
          if (
            this.$refs["mov"].duration <=
            this.getDurationFromHumanTime(chapter.startTime)
          ) {
            alert(
              chapter.startTime + "開始のチャプタは動画時間を超えています。"
            );
            throw "duration over exception";
          }
          if (index === 0) {
            if (this.getDurationFromHumanTime(chapter.startTime) > 0) {
              alert("最初のチャプタは00:00.000にしてください。");
              throw "first chapter starttime not 0 exception";
            }
          } else {
            if (
              this.getDurationFromHumanTime(
                data.selectedVideo.chapters[index - 1].startTime
              ) >= this.getDurationFromHumanTime(chapter.startTime)
            ) {
              alert("チャプタは時間の速い方から上から順に設定してください。");
              throw "first chapter starttime not 0 exception";
            }
          }
          const p = {};
          data.paramsDefinitions.forEach(param => {
            p[param.id] = parseInt(chapter.params[param.id], 10);
          });

          if (this.selectedVideo.chapters.length - 1 == index) {
            chapter.endTime = this.getHumanPrecisionTimeFromDuration(
              this.$refs["mov"].duration
            );
          } else {
            chapter.endTime = this.getHumanPrecisionTimeFromDuration(
              this.getDurationFromHumanTime(
                this.selectedVideo.chapters[index + 1].startTime
              ) - 0.001
            );
          }
          video.chapters.push({
            title: chapter.title,
            startTime: this.getDurationFromHumanTime(chapter.startTime),
            endTime: this.getDurationFromHumanTime(chapter.endTime),
            unPersonalTop: chapter.unPersonalTop != undefined && chapter.unPersonalTop,
            params: p,
            thumbnailFileName:
              "chapters/" +
              data.selectedVideo.id +
              "_" +
              index.toString() +
              ".png"
          });
          data.paramsDefinitions.forEach(param => {
            video.params[param.id] += p[param.id];
            video.paramsTotal += p[param.id];
            if (!video.maxParams[param.id]) {
              video.maxParams[param.id] = 0;
            }
            if (video.maxParams[param.id] < p[param.id]) {
              video.maxParams[param.id] = p[param.id];
            }
          });
        });
      } catch (err) {
        alert("もう一度ご送信ください エラー:" + err);
        return;
      }
      try {
        await data.storeVideo(video);
      } catch (err) {
        this.thumbnailFileProgressMessage = null;
        alert("もう一度ご送信ください エラー:" + err);
        return;
      }

      const storageRef = firebase.storage().ref();
      const tasks = [];
      data.selectedVideo.chapters.forEach(async (chapter, index) => {
        if (chapter.base64Image) {
          tasks.push(
            storageRef
              .child(video.chapters[index].thumbnailFileName)
              .putString(chapter.base64Image, "data_url")
          );
        }
      });
      if (tasks.length > 0) {
        data.thumbnailFileProgressMessage =
          "ファイル群をアップロード中です・・・";
        await Promise.all(tasks)
          .then(values => {
            console.log(values);
            data.thumbnailFileProgressMessage = null;
            data.$bvModal.hide("form-chapters");
            data.getVideos();
          })
          .catch(error => {
            data.thumbnailFileProgressMessage = null;
            alert(
              "動画アップロード中にクラウドストレージにおけるその他のエラーが発生しました。:" +
                error.code
            );
          });
      } else {
        data.thumbnailFileProgressMessage = null;
        data.$bvModal.hide("form-chapters");
        data.getVideos();
      }
    },
    setTime: function() {
      this.selectedChapter.startTime = this.getHumanPrecisionTimeFromDuration(
        this.$refs["mov"].currentTime
      );
      this.changeSelectedChapter();
    },
    capture: function(chapter) {
      const video = this.$refs["mov"];
      const canvasSizeX = 300;
      const canvasSizeY = (canvasSizeX * video.videoHeight) / video.videoWidth;
      let mem_canvas = document.createElement("canvas");
      mem_canvas.width = canvasSizeX;
      mem_canvas.height = canvasSizeY;
      mem_canvas
        .getContext("2d")
        .drawImage(video, 0, 0, canvasSizeX, canvasSizeY);

      chapter.base64Image = mem_canvas.toDataURL("image/webp");
      if (chapter == this.selectedChapter) {
        this.changeSelectedChapter();
      } else {
        this.hasEditedChapter = true;
      }
      this.$forceUpdate();
      return false;
    },
    revertCapture: function(chapter) {
      delete chapter.base64Image;
      this.$forceUpdate();
    },
    createTag: function() {
      let self = this;
      const tag = self.newTag.trim();
      if (!tag) {
        alert("カラです。");
        return;
      }
      let t = self.tags.find(g => g.name == tag);
      if (t) {
        self.selectedVideo.tags.push(t.id);
        self.newTag = "";
        return;
      }
      firebase
        .firestore()
        .collection("tags")
        .add({
          name: tag,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp()
        })
        .then(async function(d) {
          self.tags = await getTags();
          self.selectedVideo.tags.push(d.id);
          self.newTag = "";
        })
        .catch(function(error) {
          alert("エラーが起きました: ", error);
        });
    },
    //video
    newVideo: function() {
      this.saved = false;
      this.deleted = false;
      this.selectedVideo = {
        chapters: [],
        params: [],
        tags: []
      };
    },
    setSelectedVideo: function(video) {
      const tags = [];
      const trainers = [];
      video.tags.forEach(t => {
        tags.push(t.id);
      });
      video.trainers.forEach(t => {
        trainers.push(t.id);
      });
      this.selectedVideo = {
        id: video.id,
        title: video.title,
        strength: video.strength,
        description: video.description
          ? video.description.replaceAll("\\n", "\n")
          : "",
        thumbnailUrl: video.thumbnailUrl,
        thumbnailFileName: video.thumbnailFileName,
        thumbnailFile: null,
        videoUrl: video.videoUrl,
        videoFile: null,
        videoFileName: video.videoFileName,
        tags: tags,
        trainers: trainers,
        params: JSON.parse(JSON.stringify(video.params ? video.params : [])),
        duration: video.duration,
        chapters: JSON.parse(
          JSON.stringify(video.chapters ? video.chapters : [])
        ),
        published: video.published
      };
    },
    editVideo: function(video) {
      this.saved = false;
      this.deleted = false;
      this.setSelectedVideo(video);
      this.$bvModal.show("form-modal");
    },
    storeVideo: async function(video) {
      let data = this;
      return new Promise(function(resolve, reject) {
        if (data.selectedVideo.id) {
          firebase
            .firestore()
            .collection("videos")
            .doc(data.selectedVideo.id)
            .update(video)
            .then(function() {
              data.saved = true;
              resolve();
            })
            .catch(function(error) {
              reject("保存でエラーが発生しました:" + error);
            });
        } else {
          firebase
            .firestore()
            .collection("videos")
            .add(video)
            .then(function() {
              data.saved = true;
              resolve();
            })
            .catch(function(error) {
              reject("保存でエラーが発生しました:" + error);
            });
        }
      });
    },
    putStorageThumbnail: function(video) {
      let data = this;
      return new Promise(function(resolve, reject) {
        const storageRef = firebase.storage().ref();
        data.thumbnailUploadTask = storageRef
          .child(video.thumbnailFileName)
          .put(
            data.selectedVideo.thumbnailFile,
            data.selectedVideo.thumbnailFile.type
          );

        data.thumbnailUploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED,
          function(snapshot) {
            data.thumbnailFileProgressMessage =
              (
                (snapshot.bytesTransferred / snapshot.totalBytes) *
                100
              ).toString() + "%";
          },
          function(error) {
            switch (error.code) {
              case "storage/canceled":
                break;
              default:
                alert(
                  "サムネイルアップロード中にクラウドストレージにおけるその他のエラーが発生しました。:" +
                    error.code
                );
                break;
            }
            reject();
          },
          function() {
            data.thumbnailUploadTask.snapshot.ref
              .getDownloadURL()
              .then(function(downloadURL) {
                resolve(downloadURL);
              });
          }
        );
      });
    },
    putStorageVideo: function(video) {
      let data = this;
      return new Promise(function(resolve, reject) {
        const storageRef = firebase.storage().ref();
        data.videoUploadTask = storageRef
          .child(video.videoFileName)
          .put(data.selectedVideo.videoFile, data.selectedVideo.videoFile.type);

        data.videoUploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED,
          function(snapshot) {
            data.videoFileProgressMessage =
              (
                (snapshot.bytesTransferred / snapshot.totalBytes) *
                100
              ).toString() + "%";
          },
          function(error) {
            switch (error.code) {
              case "storage/canceled":
                break;
              default:
                alert(
                  "動画アップロード中にクラウドストレージにおけるその他のエラーが発生しました。:" +
                    error.code
                );
                break;
            }
            reject();
          },
          function() {
            data.videoUploadTask.snapshot.ref
              .getDownloadURL()
              .then(function(downloadURL) {
                resolve(downloadURL);
              });
          }
        );
      });
    },
    saveVideo: async function() {
      if (
        this.selectedVideo.trainers == undefined ||
        this.selectedVideo.trainers.length == 0
      ) {
        alert("トレーナーを一人は選んでください。");
        return;
      }

      this.thumbnailFileProgressMessage = null;
      this.video.videoFileProgressMessage = null;

      if (this.selectedVideo.id) {
        this.updateVideo();
      } else {
        this.createVideo();
      }
    },
    createMock: async function() {
      if (this.$production) {
        return;
      }

      //よく使うので以下はコメントアウトしつつ残しておく
      /*デモアカウントのwatchedVideosを挿入する。
      
      let demoVideos =  await firebase
          .firestore()
          .collection("videos").get();
      let demoVideoRefs = [];
      demoVideos.forEach(function(doc) {
        demoVideoRefs.push(doc.ref);
      });
      for (var i = 0; i < 40; i++) {
        let d = new Date();
        d.setDate(d.getDate() - Math.floor(Math.random() * (30 + 1 - 1)) + 1);
        let paramDemo = {};
        this.paramsDefinitions.forEach(param => {
          paramDemo[param.id] = Math.floor(Math.random() * (300 + 0 - 1)) + 0;
        });
        await firebase
          .firestore()
          .collection("watchedVideos")
          .add({
            uId: "gH6vkUylGoRnEKG0FLQwLMd7ac53",
            expectedDuration: Math.floor(Math.random() * (400 + 1 - 1)) + 1,
            createdAt: d,
            video: demoVideoRefs[
              Math.floor(Math.random() * (50 + 1 - 1)) + 1
            ],
            params: paramDemo,
          });
      }
      */

      /* これは動画の全てをどうにかするやつ
      firebase.firestore().collection("videos")
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            let d = doc.data();
            let total = 0;
            Object.keys(d.params).forEach(key => {
              total += d.params[key];
            });
            firebase
              .firestore()
              .collection("videos")
              .doc(doc.id)
              .update({
                ＊＊＊＊＊＊
              });
          });
        })
        .catch(function(error) {
          console.error("Error getting documents: ", error);
        });
      */
      /*モック作成
      let data = this;
      var N = 16;

      let video = {
        title:
          "サンプル" +
          btoa(
            String.fromCharCode(...crypto.getRandomValues(new Uint8Array(N)))
          ).substring(0, N),
        strength: 80,
        description:
          "「スポーツシーンセンシング」を導入することで、施設利用者への運動データ提供が可能となります。不特定多数が利用する運動施設が、単に運動するだけの場ではなく、自身の運動データを取得できる場となることで、利用時の魅力につながり、施設価値の向上に貢献します。また、利用者の増加も見込めるため稼働率も向上します。",
        thumbnailUrl:
          "https://storage.googleapis.com/appscop20.appspot.com/test/images.jpg",
        thumbnailFileName:
          "thumbnails/03NZ9KOSDS2QUW6NH5FXB0AY869KIVQKBD7IPHAB99Q2VSYC1SKUUUHV0VNV1785",
        videoUrl:
          "https://storage.googleapis.com/appscop20.appspot.com/test/ex.mp4",
        videoFileName:
          "videos/YZJ4143QV0R2QGLI6M385PIP1FI52Y0SY8QC8ZSGJ6RW0BRRVFJT4S9D0SN44JQ8",
        tags: this.getReferences(data.tags, [data.tags[0].id]),
        trainers: this.getReferences(data.trainers, [data.trainers[0].id]),
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        published: true
      };

      this.thumbnailFileProgressMessage = null;
      this.videoFileProgressMessage = null;
      await this.storeVideo(video);
      this.getVideos();
      */
    },
    createVideo: async function() {
      let data = this;
      let video = {
        title: data.selectedVideo.title,
        strength: Number(data.selectedVideo.strength),
        description: data.selectedVideo.description,
        thumbnailFileName: "thumbnails/" + data.getHash(64),
        videoFileName: "videos/" + data.getHash(64),
        tags: this.getReferences(data.tags, data.selectedVideo.tags),
        trainers: this.getReferences(
          data.trainers,
          data.selectedVideo.trainers
        ),
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        published:
          data.selectedVideo.published == undefined
            ? false
            : data.selectedVideo.published
      };
      try {
        video.thumbnailUrl = await this.putStorageThumbnail(video);
        video.videoUrl = await this.putStorageVideo(video);
        this.thumbnailFileProgressMessage = null;
        this.videoFileProgressMessage = null;
        await this.storeVideo(video);
        this.$bvModal.hide("form-modal");
        this.getVideos();
      } catch (err) {
        this.thumbnailFileProgressMessage = null;
        this.videoFileProgressMessage = null;
        alert("エラーが発生しました:" + err);
      }
    },
    updateVideo: async function() {
      let data = this;
      let video = {
        title: data.selectedVideo.title,
        strength: Number(data.selectedVideo.strength),
        description: data.selectedVideo.description,
        tags: data.getReferences(data.tags, data.selectedVideo.tags),
        trainers: data.getReferences(
          data.trainers,
          data.selectedVideo.trainers
        ),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        thumbnailFileName: data.selectedVideo.thumbnailFileName,
        videoFileName: data.selectedVideo.videoFileName,
        published: data.selectedVideo.published
      };

      try {
        if (data.selectedVideo.thumbnailFile) {
          video.thumbnailUrl = await this.putStorageThumbnail(video);
        }
        if (data.selectedVideo.videoFile) {
          video.videoUrl = await this.putStorageVideo(video);
        }
        this.thumbnailFileProgressMessage = null;
        this.videoFileProgressMessage = null;
        await this.storeVideo(video);
        this.$bvModal.hide("form-modal");
        this.getVideos();
      } catch (err) {
        this.thumbnailFileProgressMessage = null;
        this.videoFileProgressMessage = null;
        alert("もう一度ご送信ください エラー:" + err);
      }
    },
    cancelSaving: function() {
      if (this.thumbnailUploadTask != null) {
        this.thumbnailUploadTask.cancel();
      }
      if (this.videoUploadTask != null) {
        this.videoUploadTask.cancel();
      }
      this.$bvModal.hide("upload-files");
    },
    filteredVideos: function() {
      let self = this;
      let videos = this.videos;

      const res = [];

      const filteredTrainers = this.trainers.filter(
        g =>
          this.keyword &&
          g.name.toLowerCase().includes(this.keyword.toLowerCase())
      );
      const filteredTags = this.tags.filter(
        g =>
          this.keyword &&
          g.name.toLowerCase().includes(this.keyword.toLowerCase())
      );
      for (const video of videos) {
        if (
          (!self.selectedTrainer ||
            (video.trainers &&
              video.trainers.find(g => g.id == self.selectedTrainer))) &&
          (!self.selectedTag ||
            (video.tags && video.tags.find(g => g.id == self.selectedTag))) &&
          (!self.keyword ||
            video.title.toLowerCase().includes(self.keyword.toLowerCase()) ||
            video.description
              .toLowerCase()
              .includes(self.keyword.toLowerCase()) ||
            (video.trainers &&
              filteredTrainers &&
              video.trainers.some(g =>
                filteredTrainers.find(e => g.id == e.id)
              )) ||
            (video.tags &&
              filteredTags &&
              video.tags.some(g => filteredTags.find(e => g.id == e.id))))
        ) {
          res.push(video);
          if (res.length == Number(this.offsetOption)) {
          this.routerPush();
            return res;
          }
        }
      }
      return res;
    },
    getVideos: function() {
      this.casheTail = moment().valueOf();
      let self = this;
      const ref = firebase.firestore().collection("videos");
      self.videos = [];
      let query = ref;
      query
        .orderBy(
          this.sortOrderOption.split(",")[0],
          this.sortOrderOption.split(",")[1]
        )
        .get()
        .then(function(querySnapshot) {
          let datas = [];
          querySnapshot.forEach(function(doc) {
            let d = doc.data();
            d.id = doc.id;
            datas.push(d);
          });
          self.videos = datas;
        })
        .catch(function(error) {
          console.error("Error getting documents: ", error);
        });
    },
    onDelete: function(video) {
      this.saved = false;
      this.deleted = false;
      let data = this;
      this.$bvModal
        .msgBoxConfirm(
          "「" +
            video.title +
            "」を削除します。この操作はやり直せません。削除しますか？"
        )
        .then(result => {
          if (!result) {
            return;
          }
          const storageRef = firebase.storage().ref();
          firebase
            .firestore()
            .collection("videos")
            .doc(video.id)
            .delete()
            .then(function() {
              storageRef
                .child(video.thumbnailFileName)
                .delete()
                .then(function() {
                  storageRef
                    .child(video.videoFileName)
                    .delete()
                    .then(function() {
                      data.getVideos();
                      data.deleted = true;
                    })
                    .catch(function(error) {
                      alert("動画の削除時にエラー。: ", error);
                    });
                })
                .catch(function(error) {
                  alert("サムネイルの削除時にエラー: ", error);
                });
            })
            .catch(function(error) {
              console.error(error);
              alert("削除エラー: ", error);
            });
        });
    },
    playFrom: function(time) {
      this.$refs["mov"].currentTime = this.getDurationFromHumanTime(time);
      this.$refs["mov"].play();
    },
    // 管理者のブラウザから取得したものをつかってdurationを更新する。updatedAtは設定しない。
    setDuration: function(e, item) {
      const duration = e.target.duration;
      if (duration && duration > 1.0 && item.duration != duration) {
        item.duration = duration;
        firebase
          .firestore()
          .collection("videos")
          .doc(item.id)
          .update({
            duration: duration
          })
          .then(function() {})
          .catch(function(error) {
            console.error("保存でエラーが発生しました:" + error);
          });

        this.$forceUpdate();
      }
    },
    getFromUrlParams: function() {
      if (this.$route.query["c"]) {
        this.offsetOption = this.$route.query["c"];
      }

      if (this.$route.query["o"]) {
        this.sortOrderOption = this.$route.query["o"];
      }

      if (this.$route.query["trainer"]) {
        this.selectedTrainer = this.$route.query["trainer"];
      }

      if (this.$route.query["tag"]) {
        this.selectedTag = this.$route.query["tag"];
      }

      if (this.$route.query["kw"]) {
        this.keyword = this.$route.query["kw"];
      }
    },
    routerPush() {
      let pushRouteParam = {
        c: this.offsetOption,
        o: this.sortOrderOption,
        kw: this.keyword
      };
      pushRouteParam.trainer = this.selectedTrainer;
      pushRouteParam.tag = this.selectedTag;
      this.$router.push({ path: "", query: pushRouteParam }).catch(err => {
        console.log(err);
      });
    },
    searchTimeOut(needReLoad) {
      this.needReLoad = needReLoad;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.routerPush();
      }, 1000);
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (this.needReLoad) {
      this.needReLoad = false;
      this.getVideos();
    }
    this.saved = false;
    this.deleted = false;
    next();
  }
};
</script>
