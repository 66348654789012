import firebase from "firebase";
import "firebase/firestore";
import moment from "moment";

export { getCategories };
async function getCategories() {
  const bucket = firebase.storage().ref().bucket;
  const docs = await firebase
    .firestore()
    .collection("categories")
    .orderBy("ja")
    .get();
  let datas = [];
  docs.forEach(function(doc) {
    let d = doc.data();
    d.id = doc.id;
    d.html =
      '<img style="width:45px;" src="https://storage.googleapis.com/' +
      bucket +
      "/" +
      d.thumbnailFileName +
      "?" +
      moment().valueOf() +
      '"><small>' +
      d.ja +
      "</small></img>";
    d.reference = doc.ref;
    datas.push(d);
  });
  return datas;
}
