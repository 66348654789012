<template>
  <div>
    <div v-if="category.displayType != $categoryDisplayTypeCategories">
      動画数:{{ filteredVideos.length ? filteredVideos.length : 0 }}
      <span v-if="category.displayType == $categoryDisplayTypeChapters">
        からチャプタ数:{{
          filteredChapters.length ? filteredChapters.length : 0
        }}
      </span>
      <hr />
    </div>
    <b-row v-if="category.displayType == $categoryDisplayTypeTrainers">
      <b-col
        class="px-1 col-3 text-center"
        v-for="(t, key) in filteredTrainers"
        :key="key"
      >
        <b-img
          rounded="circle"
          v-bind:src="t.iconUrl"
          style="max-height: 128px"
        />
        <p class="px-1 small">{{ t.title }}&nbsp;{{ t.name }}</p>
      </b-col>
    </b-row>
    <b-row
      v-if="
        category.displayType == $categoryDisplayTypeMovies ||
        category.displayType == $categoryDisplayTypeTrainers
      "
    >
      <b-col
        class="px-1 col-4"
        v-for="(video, key) in filteredVideos"
        :key="key"
      >
        <img v-bind:src="video.thumbnailUrl" style="max-width: 100%" />
        <p
          class="px-1"
          style="
            color: #fff;
            position: absolute;
            top: 15px;
            left: 5px;
            max-width: 100%;
            max-height: 100%;
            text-shadow: 5px 5px 0 #000;
          "
        >
          {{ video.title }}
        </p>
      </b-col>
    </b-row>
    <b-row v-if="category.displayType == $categoryDisplayTypeChapters">
      <b-col
        class="px-1 col-4"
        v-for="(vc, key) in filteredChapters"
        :key="key"
        style="line-height: 1"
      >
        <img
          v-bind:src="getStorageUrlFromFileName(vc.chapter.thumbnailFileName)"
          style="max-width: 100%"
        />
        <div>
          <small>{{ vc.video.title }}</small>
        </div>
        <div>
          <small>ch.{{ vc.chapter.title }}</small>
        </div>
        <div>
          <small>{{ vc.chapter.startTime }}-{{ vc.chapter.endTime }}</small>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from "vue";
import VueHolder from "vue-holderjs";
import "vue-slider-component/theme/default.css";

import moment from "moment";
Vue.use(VueHolder);

export default {
  props: [
    "category",
    "filteredTrainers",
    "filteredVideos",
    "filteredChapters",
    "bucket"
  ],
  methods: {
    getStorageUrlFromFileName: function(fileName) {
      return (
        "https://storage.googleapis.com/" +
        this.bucket +
        "/" +
        fileName +
        "?" +
        moment().valueOf()
      );
    }
  }
};
</script>
