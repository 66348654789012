import firebase from "firebase/app";
import "firebase/auth";

let GlobalData = new Vue({
  data: {
    $production: window.location.hostname == "spory-admin.web.app", // グローバル変数
  },
});

Vue.mixin({
  methods: {
    $_production() {
      return GlobalData.$data.$production;
    },
    $_setProduction(newMsg) {
      GlobalData.$data.$production = newMsg;
    },
  },
  computed: {
    // 2-2. 各コンポーネント内の<template>タグ内でグローバル変数を参照するための処置
    $production: {
      get: function() {
        return GlobalData.$data.$production;
      },
      set: function(newMsg) {
        GlobalData.$data.$production = newMsg;
      },
    },
  },
});

import { utils } from "./utils";
Vue.mixin(utils);

//下記は最終的にJSで公開されるため見られても全く問題はない。
//(静的ホスティングで動くことを考えると当然なのだけれども、ウェブ上にはあやしい情報が多い)
const firebaseConfig = {
  apiKey: "AIzaSyDmc0leHfL13-3FdHkcAaHGInkJ-LffxGM",
  authDomain: "appscop20.firebaseapp.com",
  databaseURL: "https://appscop20-default-rtdb.firebaseio.com",
  projectId: "appscop20",
  storageBucket: "appscop20.appspot.com",
  messagingSenderId: "730379032943",
  appId: "1:730379032943:web:7c6c62f0f2edb6fda7f09a",
  measurementId: "G-8Q9VXZKJEP",
};
const firebaseConfigProduction = {
  apiKey: "AIzaSyAik5T9CBF9unWrgrdeF7uBcDf17VLIlNg",
  authDomain: "spory-app.firebaseapp.com",
  projectId: "spory-app",
  storageBucket: "spory-app.appspot.com",
  messagingSenderId: "179511805708",
  appId: "1:179511805708:web:4a009abcf5e9115d352b29",
  measurementId: "G-3WX61DKPX3",
};

if (!firebase.apps.length) {
  if (GlobalData.$data.$production) {
    Vue.config.productionTip = false;
    firebase.initializeApp(firebaseConfigProduction);
  } else {
    firebase.initializeApp(firebaseConfig);
  }
}

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "bootstrap/dist/css/bootstrap.css"; //Add
import "bootstrap-vue/dist/bootstrap-vue.css"; //Add
import BootstrapVue from "bootstrap-vue"; // Add
import vuetify from "./plugins/vuetify";
Vue.use(BootstrapVue); // Add

Vue.config.productionTip = false;

Vue.prototype.$categoryDisplayTypeCategories = 0;
Vue.prototype.$categoryDisplayTypeMovies = 1;
Vue.prototype.$categoryDisplayTypeChapters = 2;
Vue.prototype.$categoryDisplayTypeTrainers = 3;

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
