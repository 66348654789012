import firebase from "firebase";
import "firebase/firestore";

export { getParamsDefinitions };
async function getParamsDefinitions() {
  const docs = await firebase
    .firestore()
    .collection("paramDefinitions")
    .orderBy("sortOrder")
    .get();
  let datas = [];
  docs.forEach(function(doc) {
    let d = doc.data();
    d.id = doc.id;
    d.ref = doc.ref;
    d.reference = doc.ref;
    datas.push(d);
  });
  return datas;
}
