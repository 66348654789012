<template>
  <div></div>
</template>

<script>
import firebase from "firebase/app";
import router from "../router";

export default {
  name: "SignIn",
  mounted: () => {
    firebase.auth().onAuthStateChanged(user => {
      if (!user) {
        // URLを取得
        var url = new URL(window.location.href);
        if (url.toJSON().endsWith("readytosignin")) {
          const provider = new firebase.auth.GoogleAuthProvider();
          firebase
            .auth()
            .signInWithRedirect(provider)
            .then(() => {
              router.go("/");
            })
            .catch(error => {
              console.error(error);
              router.push("/SignIn");
            });
        }
      }
    });
  }
};
</script>
