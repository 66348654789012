<template>
  <div class="home">
    <div v-if="saved" class="alert alert-primary" role="alert">
      保存しました。
    </div>
    <div v-if="deleted" class="alert alert-danger" role="alert">
      削除しました。
    </div>
    <b-modal
      size="huge"
      id="form-modal"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      v-bind:title="
        !selectedQuest.id
          ? '新規クエスト作成'
          : '「' + selectedQuest.title.ja + '」を編集'
      "
      hide-footer
    >
      <div>
        <b-form @submit.prevent="saveQuest">
          <b-form-group class="form-group">
            <div class="input-group text-center">
              <div class="mr-2 pt-2 col-3 text-right">タイトル：</div>
              <b-form-input
                type="text"
                class="form-control col-9 mt-1"
                required="required"
                v-model.trim="selectedQuest.title.ja"
                placeholder="クエストタイトル"
              ></b-form-input>
            </div>
            <div class="input-group text-center mt-4">
              <div class="mr-2 col-3 text-right">概要：</div>
              <b-form-textarea
                id="textarea"
                required="required"
                v-model.trim="selectedQuest.description.ja"
                placeholder="ときに激しくあおるような説明を追加します"
                rows="3"
                max-rows="20"
                class="col-9 mt-1"
              ></b-form-textarea>
            </div>
            <div class="input-group text-center mt-4">
              <div class="mr-2 col-3 text-right">出題者(トレーナー)：</div>
              <div class="col-8 text-left">
                <b-form-checkbox-group
                  v-model="selectedQuest.trainers"
                  :options="trainers"
                  value-field="id"
                  text-field="titleAndName"
                ></b-form-checkbox-group>
              </div>
            </div>
          </b-form-group>

          <div class="input-group text-center mt-4">
            <div class="mr-2 col-3 text-right">限定期間:</div>
            <div class="col-2">
              <datetime v-model="selectedQuest.gettingStartAt" type="datetime">
              </datetime>
            </div>
            <div class="px-2 mx-2 col-1 text-right">～</div>
            <div class="col-2">
              <datetime v-model="selectedQuest.gettingEndAt" type="datetime">
              </datetime>
            </div>
          </div>

          <div
            v-if="selectedQuest.thumbnailFileName"
            class="col-12 text-center mt-4 text-center"
          >
            <strong
              >以下は画像を差し替えるときのみアップロードしてください。</strong
            >
          </div>

          <div class="input-group text-center mt-4">
            <div class="col-3 text-right mt-2">サムネイル画像：</div>
            <div class="col-9 px-2">
              <b-form-file
                v-model="selectedQuest.thumbnailFile"
                :state="Boolean(selectedQuest.thumbnailFile)"
                accept=".webp, .jpg, .jpeg, .png, .gif"
                placeholder="webp,jpeg,png,gifを選択かドロップ"
                drop-placeholder="Drop file here..."
                :required="selectedQuest.id == ''"
              ></b-form-file>
              ※768x432pxのみ。splitエラーが出たときはwebページを更新してください。
            </div>
          </div>

          <b-row class="mx-2">
            <b-col
              v-for="(chapter, key2) in selectedQuest.chapters"
              :key="key2"
              class="border justify-content-center pl-3 pr-3 py-1 col-1 rounded"
              :style="hasLoad(chapter) ? 'background-color:#fdd' : ''"
            >
              <b-row class="justify-content-center">
                <b-col cols="12" class="px-1 mx-1">
                  <b-button
                    variant="danger"
                    class="p-0 position-absolute rounded"
                    @click="deleteChapter(key2)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      class="bi bi-trash"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
                      />
                      <path
                        fill-rule="evenodd"
                        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                      />
                    </svg>
                  </b-button>
                  <b-img
                    class="w-100 rounded"
                    :src="
                      'https://storage.googleapis.com/' +
                      bucket +
                      '/' +
                      getVideo(chapter.videoId).chapters[chapter.chapterId]
                        .thumbnailFileName +
                      '?' +
                      cacheTail
                    "
                  ></b-img>
                </b-col>
              </b-row>
              <b-row class="justify-content-center">
                <b-col cols="12" class="px-1" style="line-height: 0.8rem">
                  <small style="font-size: 0.5rem"
                    >{{ (key2 + 1).toString().padStart(2, "0") }} -
                    <span
                      v-for="trainer in getVideo(chapter.videoId).trainers"
                      :key="trainer.id"
                    >
                      {{ trainers.find((g) => g.id == trainer.id).name }} </span
                    ><br />
                    {{ getVideo(chapter.videoId).title }}<br />
                    {{
                      getVideo(chapter.videoId).chapters[chapter.chapterId]
                        .title
                    }}
                  </small>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="input-group my-2">
            <div class="mr-2 pt-2 col-2 text-right py-0">
              追加するチャプタ：
            </div>
            <div class="col-5 px-0">
              <b-form-input
                type="text"
                placeholder="ここで動画を絞り込んでチャプタを追加します"
                variant="outline-secondary"
                v-model="videoKeyword"
                @keyup="searchTimeOut()"
                @keydown.self.prevent.enter
              ></b-form-input>
            </div>
            <b-button
              class="col-2"
              variant="secondary"
              @click="
                () => {
                  videoKeyword = '';
                }
              "
            >
              <span
                style="
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
                >絞込クリア</span
              >
            </b-button>
            <div class="col-2">
              <b-select v-model="insertionPosition">
                <template v-if="selectedQuest.chapters.length == 0">
                  <option value="0">末尾</option>
                </template>
                <template v-else>
                  <template v-for="index in selectedQuest.chapters.length">
                    <option :value="index - 1" :key="index">
                      <template v-if="index == 1"> 先頭 </template>
                      <template v-else>
                        {{ index - 1 }}と{{ index }}の間
                      </template>
                    </option>
                  </template>
                  <option :value="selectedQuest.chapters.length">末尾</option>
                </template>
              </b-select>
            </div>
          </b-row>
          <b-row v-if="!videoKeyword">
            <b-col cols="12 text-center">
              公開済みの動画をタイトルやIDで絞り込んでください。
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col
              cols="12"
              class="pb-2"
              v-for="(video, key2) in filteredVideos"
              :key="key2"
            >
              <b-card :header="video.title" class="text-center border">
                <b-card-body class="text-center p-1">
                  <b-row>
                    <b-col
                      cols="3"
                      v-for="(chapter, key3) in video.chapters"
                      :key="key3"
                      class="p-1"
                    >
                      <b-button
                        class="p-1"
                        :style="
                          hasLoad(chapter)
                            ? 'background-color:#fdd; color:black;'
                            : 'background-color:#fff;'
                        "
                        @click="addChapter(video.id, key3)"
                      >
                        <small
                          style="
                            overflow: hidden;
                            text-overflow: ellipsis;
                            color: black;
                          "
                          >{{ chapter.title }}</small
                        ><br />
                        <b-img
                          class="w-100 rounded"
                          :src="
                            'https://storage.googleapis.com/' +
                            bucket +
                            '/' +
                            chapter.thumbnailFileName
                          "
                        ></b-img>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
          <div class="input-group row text-right mb-2 mt-4">
            <b-form-checkbox
              :disabled="!selectedQuest.chapters.length"
              v-model="selectedQuest.published"
              class="offset-3 col-2 p-0"
              size="lg"
              >期間中は公開</b-form-checkbox
            >
            <button class="btn btn-primary offset-1 col-2" type="submit">
              保存
            </button>
            <a
              class="btn btn-outline-secondary offset-1 col-2"
              @click="$bvModal.hide('form-modal')"
            >
              キャンセル
            </a>
          </div>
          <b-row class="justify-content-center py-3">
            <small>
              ※新規作成か下書き状態だとチャプタを編集してから公開出来ます。<br />
            </small>
          </b-row>
        </b-form>

        <div
          v-if="confirmMessage"
          class="alert alert-info"
          role="alert"
          style="white-space: pre-wrap; word-wrap: break-word"
        >
          {{ confirmMessage.trim() }}
        </div>
      </div>
    </b-modal>
    <div class="pt-3">
      <b-form inline>
        <label class="ml-auto">絞り込み:</label>
        <b-form-input
          type="text"
          class="m-md-2 col-3"
          placeholder="フリーワード"
          variant="outline-secondary"
          v-model="keyword"
          @keyup="searchTimeOut()"
          @keydown.self.prevent.enter
        ></b-form-input>
      </b-form>
    </div>
    <div class="text-right p-3">
      <button
        class="btn btn-primary col-3"
        @click="
          confirmMessage = null;
          newQuest();
          $bvModal.show('form-modal');
        "
      >
        ＋クエスト追加
      </button>
    </div>
    <div class="text-left px-3">※削除は下書きにしてから行えます。</div>
    <table class="table">
      <tr>
        <th class="align-middle text-center" style="width: 8%">編集 / 削除</th>
        <th class="align-middle text-center" style="width: 92%">クエスト</th>
      </tr>
      <tr
        v-for="(item, key) in getFilteredQuests()"
        :key="key"
        :set="(itemDetail = getQuestDetail(item))"
      >
        <td colspan="3">
          <table>
            <tr>
              <td class="align-middle text-center border-0" style="width: 8%">
                <div class="pt-4">
                  <button class="btn btn-primary" @click="editQuest(item)">
                    編集
                  </button>
                </div>
                <div class="pt-3" v-if="!item.published">
                  <button
                    class="btn btn-danger"
                    @click.stop.prevent="onDelete(item)"
                  >
                    削除
                  </button>
                </div>
              </td>
              <td class="align-middle text-center border-0" style="">
                <b-row>
                  <b-col cols="8">
                    <b-row> <small
                        >クエストID:&nbsp;{{ item.id }}</small
                      > </b-row>
                    <b-row>
                      <h5>
                        【
                        <span v-if="item.published">
                          期間中は公開 </span>
                        <span v-else> 下書き </span>
                        】
                        {{ item.title.ja }}
                      </h5>
                    </b-row>
                    <b-row>
                      出題者（トレーナー）:
                      <span v-for="trainer in item.trainers" :key="trainer.id">
                        {{
                          trainers.find((g) => g.id == trainer.id).name
                        }}&nbsp;
                      </span>
                    </b-row>
                    <b-row>
                       <small>
                      公開期間：{{
                        formatDateShort(item.gettingStartAt)
                      }}&nbsp;～&nbsp;{{ formatDateShort(item.gettingEndAt)
                      }}</small
                      >
                    </b-row>
                    <b-row>
                      <small
                        >全体:{{
                          getHumanTimeFromDuration(itemDetail.duration)
                        }}
                        負荷:{{
                          getHumanTimeFromDuration(itemDetail.loadDuration)
                        }}</small
                      >
                    </b-row>
                    <b-row class="text-left">
                      <small>{{ item.description.ja }}</small>
                    </b-row>
                  </b-col>

                  <b-col
                    cols="4"
                    v-if="item.thumbnailFileName && item.thumbnailFileName.ja"
                  >
                    <b-img
                      class="w-100"
                      :src="
                        'https://storage.googleapis.com/' +
                        bucket +
                        '/' +
                        item.thumbnailFileName.ja +
                        '?' +
                        cacheTail
                      "
                    ></b-img>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    cols="1"
                    v-for="(chapter, key2) in itemDetail.chapters"
                    :key="key2"
                    class="border p-1 rounded"
                    :style="hasLoad(chapter) ? 'background-color:#fdd;' : ''"
                  >
                    <b-row class="justify-content-center">
                      <b-col cols="12">
                        <b-img
                          class="w-100 rounded"
                          :src="
                            'https://storage.googleapis.com/' +
                            bucket +
                            '/' +
                            chapter.thumbnailFileName +
                            '?' +
                            cacheTail
                          "
                        ></b-img>
                      </b-col>
                    </b-row>
                    <b-row class="justify-content-center">
                      <b-col cols="12" class="px-3">
                        <small
                          >{{ (key2 + 1).toString().padStart(2, "0") }} -
                          {{ chapter.video.title }} <br />
                          {{ chapter.title }}
                        </small>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import firebase from "firebase";
import "firebase/firestore";
import "firebase/storage";
import moment from "moment";
import { getParamsDefinitions } from "../getParamsDefinitions";
import { getTags } from "../getTags";
import { getTrainers } from "../getTrainers";
import { getVideos } from "../getVideos";
import { getQuests } from "../getQuests";

import Vue from "vue";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import VueHolder from "vue-holderjs";
Vue.use(VueHolder);

export default {
  components: {
    datetime: Datetime,
  },
  name: "Quest",
  data: function () {
    return {
      filteredVideos: [],
      hostname: window.location.hostname,
      bucket: firebase.storage().ref().bucket,
      quest: {
        id: null,
        title: null,
        trainers: [],
      },
      offsetOption: "10",
      offsetOptions: [
        { value: "10", text: "10件" },
        { value: "30", text: "30件" },
        { value: "50", text: "50件" },
      ],
      sortOrderOption: "updatedAt,desc",
      hasEditedChapter: false,
      selectedTrainer: null,
      selectedTag: null,
      newTag: "",
      selectedQuest: {
        title: {},
        description: {},
        chapters: [],
        thumbnailFileName: {},
      },
      quests: [],
      videos: [],
      trainers: [],
      errors: [],
      saved: false,
      deleted: false,
      videoKeyword: "",
      keyword: "",
      editFlg: false,
      confirmMessage: null,
      thumbnailFileProgressMessage: null,
      questFileProgressMessage: null,
      thumbnailUploadTask: null,
      questUploadTask: null,
      chaptersUploadTask: null,
      insertionPosition: 0,
      paramsDefinitions: [],
      moment: moment,
      timer: null,
      needReLoad: true,
      cacheTail: moment().valueOf(),
    };
  },
  mounted: async function () {
    this.tags = await getTags();
    this.trainers = await getTrainers();
    this.paramsDefinitions = await getParamsDefinitions();
    this.videos = await getVideos();
    this.quests = await getQuests();
  },
  methods: {
    getQuestDetail: function (quest) {
      let q = quest;
      q.duration = 0;
      q.loadDuration = 0;
      q.chapters.forEach((c) => {
        c.video = this.getVideo(c.videoId);
        Object.assign(c, c.video.chapters[c.chapterId]);
        q.duration += c.endTime - c.startTime;
        if (this.hasLoad(c)) {
          q.loadDuration += c.endTime - c.startTime;
        }
      });
      return q;
    },
    getFilteredQuests: function () {
      const res = [];
      if (!this.keyword.trim()) {
        return this.quests;
      }
      const filteredTrainers = this.trainers.filter(
        (g) =>
          this.keyword &&
          g.name.toLowerCase().includes(this.keyword.toLowerCase())
      );
      for (const quest of this.quests) {
        console.log(
          quest.trainers.find((g) =>
            filteredTrainers.map((t) => t.id).includes(g.id)
          )
        );
        if (
          quest.trainers.find((g) =>
            filteredTrainers.map((t) => t.id).includes(g.id)
          ) ||
          quest.id.toLowerCase().includes(this.keyword.toLowerCase()) ||
          quest.title.ja
            .toLowerCase()
            .includes(
              this.keyword.toLowerCase() ||
                quest.description.ja
                  .toLowerCase()
                  .includes(this.keyword.toLowerCase())
            )
        ) {
          res.push(quest);
        }
      }
      return res;
    },
    filterVideos: function () {
      if (!this.videoKeyword) {
        return [];
      }
      let self = this;
      let videos = this.videos;

      const res = [];
      for (const video of videos) {
        if (!video.published) continue;
        if (
          video.id.toLowerCase().includes(self.videoKeyword.toLowerCase()) ||
          video.title.toLowerCase().includes(self.videoKeyword.toLowerCase())
        ) {
          res.push(video);
        }
      }
      this.filteredVideos = res;
    },
    addChapter: function (videoId, chapterId) {
      this.selectedQuest.chapters.splice(this.insertionPosition, 0, {
        videoId: videoId,
        chapterId: chapterId,
      });
      this.insertionPosition++;
    },
    getVideo: function (videoId) {
      return this.videos.find((g) => g.id == videoId);
    },
    deleteChapter: function (index) {
      this.selectedQuest.chapters.splice(index, 1);
      if (this.insertionPosition >= index) {
        this.insertionPosition--;
      }
    },
    createTag: function () {
      let self = this;
      const tag = self.newTag.trim();
      if (!tag) {
        alert("カラです。");
        return;
      }
      let t = self.tags.find((g) => g.name == tag);
      if (t) {
        self.selectedQuest.tags.push(t.id);
        self.newTag = "";
        return;
      }
      firebase
        .firestore()
        .collection("tags")
        .add({
          name: tag,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(async function (d) {
          self.tags = await getTags();
          self.selectedQuest.tags.push(d.id);
          self.newTag = "";
        })
        .catch(function (error) {
          alert("エラーが起きました: ", error);
        });
    },
    //quest
    newQuest: function () {
      this.saved = false;
      this.deleted = false;
      this.selectedQuest = {
        title: {},
        description: {},
        chapters: [],
        gettingStartAt: moment(
          moment().format("YYYY-MM-DD HH:mm:ss")
        ).toISOString(),
        gettingEndAt: moment(
          moment().format("2999-MM-DD HH:mm:ss")
        ).toISOString(),
      };
      this.insertionPosition = 0;
    },
    setSelectedQuest: function (quest) {
      const trainers = [];
      quest.trainers.forEach((t) => {
        trainers.push(t.id);
      });
      this.selectedQuest = {
        id: quest.id,
        title: quest.title,
        description: quest.description,
        thumbnailFileName: quest.thumbnailFileName,
        trainers: trainers,
        chapters: JSON.parse(
          JSON.stringify(quest.chapters ? quest.chapters : [])
        ),
        published: quest.published,
        gettingStartAt: quest.gettingStartAt.toDate().toISOString(),
        gettingEndAt: quest.gettingEndAt.toDate().toISOString(),
      };
    },
    editQuest: function (quest) {
      this.saved = false;
      this.deleted = false;
      this.setSelectedQuest(quest);
      this.insertionPosition = this.selectedQuest.chapters.length;
      this.$bvModal.show("form-modal");
    },
    storeQuest: async function (quest) {
      let data = this;
      return new Promise(function (resolve, reject) {
        if (data.selectedQuest.id) {
          firebase
            .firestore()
            .collection("quests")
            .doc(data.selectedQuest.id)
            .update(quest)
            .then(function () {
              data.saved = true;
              resolve();
            })
            .catch(function (error) {
              reject("保存でエラーが発生しました:" + error);
            });
        } else {
          firebase
            .firestore()
            .collection("quests")
            .add(quest)
            .then(function () {
              data.saved = true;
              resolve();
            })
            .catch(function (error) {
              reject("保存でエラーが発生しました:" + error);
            });
        }
      });
    },
    saveQuest: async function () {
      if (
        this.selectedQuest.trainers == undefined ||
        this.selectedQuest.trainers.length == 0
      ) {
        alert("トレーナーを一人は選んでください。");
        return;
      }
      let s = moment(this.selectedQuest.gettingStartAt);
      let e = moment(this.selectedQuest.gettingEndAt);
      if (e.isSameOrBefore(s)) {
        alert("限定時刻の設定は開始より終了を未来にしてください。:");
        return;
      }
      if (this.selectedQuest.id) {
        this.updateQuest();
      } else {
        this.createQuest();
      }
    },
    createQuest: async function () {
      let data = this;
      let quest = {
        title: data.selectedQuest.title,
        description: data.selectedQuest.description,
        tags: this.getReferences(data.tags, data.selectedQuest.tags),
        trainers: this.getReferences(
          data.trainers,
          data.selectedQuest.trainers
        ),
        chapters: data.selectedQuest.chapters.map((g) => {
          return {
            videoId: g.videoId,
            chapterId: g.chapterId,
          };
        }),
        gettingStartAt: moment(data.selectedQuest.gettingStartAt).toDate(),
        gettingEndAt: moment(data.selectedQuest.gettingEndAt).toDate(),
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        published:
          data.selectedQuest.published == undefined
            ? false
            : data.selectedQuest.published,
      };
      if (data.selectedQuest.thumbnailFile) {
        quest.thumbnailFileName = {};
        quest.thumbnailFileName.ja = "thumbnails/" + data.getHash(64);
      }
      try {
        if (data.selectedQuest.thumbnailFile) {
          await this.putStorageThumbnail(quest);
        }
        this.thumbnailFileProgressMessage = null;
        await this.storeQuest(quest);
        this.$bvModal.hide("form-modal");
        this.quests = await getQuests();
      } catch (err) {
        this.thumbnailFileProgressMessage = null;
        this.questFileProgressMessage = null;
        alert("エラーが発生しました:" + err);
      }
    },
    updateQuest: async function () {
      let data = this;
      let quest = {
        title: data.selectedQuest.title,
        strength: Number(data.selectedQuest.strength),
        description: data.selectedQuest.description,
        tags: data.getReferences(data.tags, data.selectedQuest.tags),
        trainers: data.getReferences(
          data.trainers,
          data.selectedQuest.trainers
        ),
        chapters: data.selectedQuest.chapters.map((g) => {
          return {
            videoId: g.videoId,
            chapterId: g.chapterId,
          };
        }),
        gettingStartAt: moment(data.selectedQuest.gettingStartAt).toDate(),
        gettingEndAt: moment(data.selectedQuest.gettingEndAt).toDate(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        published: data.selectedQuest.published,
      };
      if (
        data.selectedQuest.thumbnailFileName &&
        data.selectedQuest.thumbnailFileName.ja
      ) {
        quest.thumbnailFileName = data.selectedQuest.thumbnailFileName;
      } else if (data.selectedQuest.thumbnailFile) {
        quest.thumbnailFileName = {};
        quest.thumbnailFileName.ja = "thumbnails/" + data.getHash(64);
      }
      try {
        if (data.selectedQuest.thumbnailFile) {
          await this.putStorageThumbnail(quest);
        }
        this.thumbnailFileProgressMessage = null;
        await this.storeQuest(quest);
        this.$bvModal.hide("form-modal");
        this.quests = await getQuests();
      } catch (err) {
        this.thumbnailFileProgressMessage = null;
        this.questFileProgressMessage = null;
        alert("もう一度ご送信ください エラー:" + err);
      }
    },
    hasLoad: function (chapter) {
      let ch = chapter;
      if (!ch.params) {
        ch = this.getVideo(chapter.videoId).chapters[chapter.chapterId];
      }
      return (
        ch.params &&
        Object.keys(ch.params) &&
        Object.keys(ch.params).find((g) => ch.params[g] > 0)
      );
    },
    onDelete: function (quest) {
      this.saved = false;
      this.deleted = false;
      this.$bvModal
        .msgBoxConfirm(
          "「" +
            quest.title +
            "」を削除します。この操作はやり直せません。削除しますか？"
        )
        .then((result) => {
          if (!result) {
            return;
          }
          firebase
            .firestore()
            .collection("quests")
            .doc(quest.id)
            .delete()
            .then(function () {})
            .catch(function (error) {
              console.error(error);
              alert("削除エラー: ", error);
            });
        });
    },
    putStorageThumbnail: function (quest) {
      let data = this;
      return new Promise(function (resolve, reject) {
        const storageRef = firebase.storage().ref();
        data.thumbnailUploadTask = storageRef
          .child(quest.thumbnailFileName.ja)
          .put(
            data.selectedQuest.thumbnailFile,
            data.selectedQuest.thumbnailFile.type
          );

        data.thumbnailUploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED,
          function (snapshot) {
            data.thumbnailFileProgressMessage =
              (
                (snapshot.bytesTransferred / snapshot.totalBytes) *
                100
              ).toString() + "%";
          },
          function (error) {
            switch (error.code) {
              case "storage/canceled":
                break;
              default:
                alert(
                  "サムネイルアップロード中にクラウドストレージにおけるその他のエラーが発生しました。:" +
                    error.code
                );
                break;
            }
            reject();
          },
          function () {
            data.thumbnailUploadTask.snapshot.ref
              .getDownloadURL()
              .then(function (downloadURL) {
                resolve(downloadURL);
              });
          }
        );
      });
    },
    routerPush() {
      let pushRouteParam = {
        kw: this.keyword,
      };
      pushRouteParam.trainer = this.selectedTrainer;
      this.$router.push({ path: "", query: pushRouteParam });
    },
    searchTimeOut(needReLoad) {
      this.needReLoad = needReLoad;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.filterVideos();
      }, 1000);
    },
  },
  async beforeRouteUpdate(to, from, next) {
    if (this.needReLoad) {
      this.needReLoad = false;
      this.quests = await getQuests();
    }
    this.saved = false;
    this.deleted = false;
    next();
  },
};
</script>
