<template>
  <div class="home">
    <div v-if="saved" class="alert alert-primary" role="alert">
      保存しました。
    </div>
    <div v-if="deleted" class="alert alert-danger" role="alert">
      削除しました。
    </div>
    <b-modal
      size="xl"
      id="form-modal"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      v-bind:title="
        !selectedTag.name ? '新規作成' : '「' + selectedTag.name + '」を編集'
      "
      hide-footer
    >
      <p v-if="selectedTag.id && selectedTag.videoCount == 0">
        削除する場合は、名前をカラにしてください。
      </p>
      <b-form @submit.prevent="saveTag">
        <b-form-group class="form-group">
          <div class="input-group text-center">
            <div class="mr-2 col-3 text-right">名前</div>
            <input
              type="text"
              class="form-control col-9 mt-1"
              required="required"
              v-model.trim="selectedTag.name"
              placeholder="名前"
              @keydown.self.prevent.enter
            />
          </div>
        </b-form-group>

        <div class="input-group row mt-2 mb-2">
          <a
            v-if="
              selectedTag.id &&
              selectedTag.name.trim() == '' &&
              selectedTag.videoCount == 0
            "
            class="btn btn-sm btn-danger offset-7 col-2"
            @click="deleteTag()"
          >
            削除
          </a>
          <button
            v-else
            class="btn btn-sm btn-primary offset-7 col-2"
            type="submit"
          >
            保存
          </button>
          <a
            class="btn btn-sm btn-outline-secondary offset-1 col-2"
            @click="$bvModal.hide('form-modal')"
          >
            キャンセル
          </a>
        </div>
      </b-form>
      <div
        v-if="confirmMessage"
        class="alert alert-info"
        role="alert"
        style="white-space: pre-wrap; word-wrap: break-word"
      >
        {{ confirmMessage.trim() }}
      </div>
    </b-modal>
    <div class="pt-3">
      <b-form-group>
        <label>絞り込み:</label>
        <b-form-select
          :options="sortOrderOptions"
          v-model="sortOrderOption"
          class="m-md-2 col-3"
          variant="outline-secondary"
          @change="routerPush()"
        >
        </b-form-select>
      </b-form-group>
    </div>
    <div>
      ※タグをクリックすると編集です。動画数ゼロのタグは編集画面の名前をカラにすると削除できます。タグはカテゴリと密接に関係する情報です。
    </div>
    <div>
      ※タグはアプリから閲覧できませんが、直接閲覧できると思ってください。「○○が出来ない人向け」ではなく「XXだけで出来る」などの言葉選択をしましょう。
    </div>
    <div>※(動画数)が0になったものは早めに削除しましょう。</div>
    <div class="text-right p-3">
      <button
        class="btn btn-primary col-3"
        @click="
          confirmMessage = null;
          newTag();
          $bvModal.show('form-modal');
        "
      >
        ＋新規作成
      </button>
    </div>
    <div class="row px-3" v-if="videos">
      <div
        v-for="(item, key) in this.tags"
        :key="key"
        class="col-4 col-md-2 p-1"
      >
        <button class="btn btn-outline-secondary" @click="editTag(item)">
          {{ item.name }}&nbsp;({{
            videos.filter((x) => x.tags && x.tags.some((g) => g.id == item.id))
              .length
          }})
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import "firebase/firestore";

import Vue from "vue";
import VueHolder from "vue-holderjs";
Vue.use(VueHolder);

export default {
  name: "Tag",
  data: function() {
    return {
      tag: {
        id: "",
        name: ""
      },
      sortOrderOption: "name,asc",
      sortOrderOptions: [
        { value: "name,asc", text: "名前昇順" },
        { value: "name,desc", text: "名前降順" }
      ],
      selectedTag: {},
      tags: [],
      videos: null,
      errors: [],
      saved: false,
      deleted: false,
      editFlg: false,
      confirmMessage: null
    };
  },
  mounted: function() {
    this.getFromUrlParams();
    this.getTags();
    this.getVideos();
  },
  methods: {
    //tag
    newTag: function() {
      this.saved = false;
      this.selectedTag = {};
    },
    editTag: function(tag) {
      this.saved = false;
      this.selectedTag = {
        id: tag.id,
        name: tag.name,
        videoCount: this.videos.filter(
          x => x.tags && x.tags.some(g => g == tag.id)
        ).length
      };
      this.$bvModal.show("form-modal");
    },
    storeTag: async function(tag) {
      let data = this;
      return new Promise(function(resolve, reject) {
        if (data.selectedTag.id) {
          firebase
            .firestore()
            .collection("tags")
            .doc(data.selectedTag.id)
            .update(tag)
            .then(function() {
              data.saved = true;
              resolve();
            })
            .catch(function(error) {
              reject("保存でエラーが発生しました:" + error);
            });
        } else {
          firebase
            .firestore()
            .collection("tags")
            .add(tag)
            .then(function() {
              data.saved = true;
              resolve();
            })
            .catch(function(error) {
              reject("保存でエラーが発生しました:" + error);
            });
        }
      });
    },
    saveTag: async function() {
      this.thumbnailFileProgressMessage = null;

      if (this.selectedTag.id) {
        this.updateTag();
      } else {
        this.createTag();
      }
    },
    createTag: async function() {
      let data = this;
      let tag = {
        name: data.selectedTag.name,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      };
      try {
        await this.storeTag(tag);
        this.$bvModal.hide("form-modal");
        this.getTags();
      } catch (err) {
        this.thumbnailFileProgressMessage = null;
        alert("エラーが発生しました:" + err);
      }
    },
    updateTag: async function() {
      let data = this;
      let tag = {
        name: data.selectedTag.name,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      };
      try {
        await this.storeTag(tag);
        this.$bvModal.hide("form-modal");
        this.getTags();
      } catch (err) {
        this.thumbnailFileProgressMessage = null;
        alert("もう一度ご送信ください エラー:" + err);
      }
    },
    cancelSaving: function() {
      if (this.thumbnailUploadTask != null) {
        this.thumbnailUploadTask.cancel();
      }
      this.$bvModal.hide("upload-files");
    },
    getTags: function() {
      let self = this;
      const ref = firebase.firestore().collection("tags");
      const query = ref;
      query
        .orderBy(
          this.sortOrderOption.split(",")[0],
          this.sortOrderOption.split(",")[1]
        )
        .get()
        .then(function(querySnapshot) {
          let datas = [];
          querySnapshot.forEach(function(doc) {
            let d = doc.data();
            d.id = doc.id;
            datas.push(d);
          });
          self.tags = datas;
        })
        .catch(function(error) {
          console.error("Error getting documents: ", error);
        });
    },
    deleteTag: async function() {
      let data = this;
      let tag = await firebase
        .firestore()
        .collection("tags")
        .doc(data.selectedTag.id)
        .get();
      const query2 = firebase.firestore().collection("videos");
      query2.get().then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          doc.update({
            tags: firebase.firestore.FieldValue.arrayRemove(tag.reference)
          });
        });
      });
      firebase
        .firestore()
        .collection("tags")
        .doc(this.selectedTag.id)
        .delete()
        .then(function() {
          data.deleted = true;
          data.$bvModal.hide("form-modal");
          data.getTags();
        })
        .catch(function(error) {
          console.error(error);
          alert("削除エラー: ", error);
        });
    },
    getVideos: function() {
      let self = this;
      const ref = firebase.firestore().collection("videos");
      const query = ref;
      query
        .get()
        .then(function(querySnapshot) {
          let datas = [];
          querySnapshot.forEach(function(doc) {
            let d = doc.data();
            datas.push(d);
          });
          self.videos = datas;
        })
        .catch(function(error) {
          console.error("Error getting documents: ", error);
        });
    },
    formatDate: function(firestoreStamp) {
      const date = firestoreStamp.toDate();
      return (
        date.getFullYear() +
        "/" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "/" +
        ("0" + date.getDate()).slice(-2) +
        " " +
        ("0" + date.getHours()).slice(-2) +
        ":" +
        ("0" + date.getMinutes()).slice(-2) +
        ":" +
        ("0" + date.getSeconds()).slice(-2)
      );
    },
    getFromUrlParams: function() {
      if (this.$route.query["c"]) {
        this.offsetOption = this.$route.query["c"];
      }

      if (this.$route.query["o"]) {
        this.sortOrderOption = this.$route.query["o"];
      }
    },
    routerPush() {
      let pushRouteParam = { c: this.offsetOption, o: this.sortOrderOption };
      this.$router.push({ path: "", query: pushRouteParam });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.getTags();
    this.saved = false;
    this.deleted = false;
    next();
  }
};
</script>
