<template>
  <div class="home">
    <b-modal
      size="sm"
      cancel-only
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      id="upload-files"
      title="アップロード中です"
      hide-footer
    >
    </b-modal>
    <div v-if="saved" class="alert alert-primary" role="alert">
      保存しました。
    </div>
    <div v-if="deleted" class="alert alert-danger" role="alert">
      削除しました。
    </div>
    <b-modal
      size="xl"
      id="form-modal"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      v-bind:title="
        (!selectedtopic.id
          ? '新規作成'
          : '「' + selectedtopic.title + '」を編集') + ' - 即座に公開されます。'
      "
      hide-footer
    >
      <div v-if="thumbnailFileProgressMessage">
        <p>
          イメージ画像：{{
            thumbnailFileProgressMessage
              ? thumbnailFileProgressMessage
              : "変更なし"
          }}
        </p>
        <div class="text-right">
          <button
            class="btn btn-sm btn-outline-secondary col-5"
            @click="cancelSaving()"
          >
            キャンセル
          </button>
        </div>
      </div>
      <div v-else>
        <b-form @submit.prevent="savetopic">
          <b-form-group class="form-group">
            <div class="input-group text-center">
              <div class="mt-2 mr-2 col-3 text-right">タイトル</div>
              <b-form-textarea
                row="3"
                class="form-control col-9"
                required="required"
                v-model.trim="selectedtopic.title"
                placeholder="タイトル"
              ></b-form-textarea>
            </div>
            <div class="input-group text-center mt-4">
              <div class="mt-2 mr-2 col-3 text-right">概要:</div>
              <b-form-textarea
                id="textarea"
                required="required"
                v-model.trim="selectedtopic.description"
                placeholder="端的に記述します。長くなる場合は特設ページ等を作ってリンクで誘導してください。"
                rows="3"
                max-rows="6"
                class="col-9"
              ></b-form-textarea>
            </div>

            <div class="input-group text-center mt-4">
              <div class="mr-2 col-3 text-right">外部へのリンク（あれば）</div>
              <input
                type="url"
                class="form-control col-9 mt-1"
                v-model.trim="selectedtopic.url"
                placeholder="https://...からはじまるフルリンク"
                @keydown.self.prevent.enter
              />
            </div>
            <div class="input-group text-center mt-4 mb-0 pb-0">
              <div class="mr-2 col-3 text-right">アプリ内リンク（あれば）</div>
              <input
                type="text"
                class="form-control col-9 mt-1"
                v-model.trim="selectedtopic.deep_link"
                disabled="disabled"
                placeholder=""
                @keydown.self.prevent.enter
              />
            </div>
            <div class="input-group text-center">
              <div class="mr-2 mt-3 col-3 text-right">イベント（あれば）:</div>
              <b-form-input
                type="text"
                v-model.trim="selectedtopic.eventId"
                placeholder="イベントのIDを入力します"
                @keydown.self.prevent.enter
                class="col-9 mt-2"
              ></b-form-input>
            </div>
            <div class="text-right pt-0 mt-0">
              ※現状はトレーナーページから公開動画がある状態で表示される「通知作成」から行ってください。<br />
              ※技術的な話:encodeURIComponentは通知するサーバサイドで行います
            </div>
            <div
              v-if="selectedtopic.thumbnailFileName"
              class="col-12 text-center mt-4 text-center"
            >
              <strong
                >以下はイメージを差し替えるときのみアップロードしてください。</strong
              >
            </div>
            <div class="input-group text-center mt-4">
              <div class="col-3 text-right mt-2">イメージ画像：</div>
              <div class="col-9 px-2">
                <b-form-file
                  v-model="selectedtopic.thumbnailFile"
                  :state="Boolean(selectedtopic.thumbnailFile)"
                  accept=".webp, .jpg, .jpeg, .png, .gif"
                  placeholder="webp,jpeg,png,gifを選択かドロップ"
                  drop-placeholder="Drop file here..."
                  :required="selectedtopic.id == ''"
                ></b-form-file>
                ※768x432pxのみ。splitエラーが出たときはwebページを更新してください。
              </div>
            </div>
          </b-form-group>

          <div class="input-group row pb-3">
            <button class="btn btn-sm btn-primary offset-7 col-2" type="submit">
              公開
            </button>
            <a
              class="btn btn-sm btn-outline-secondary offset-1 col-2"
              @click="$bvModal.hide('form-modal')"
            >
              キャンセル
            </a>
          </div>
        </b-form>
        <div
          v-if="confirmMessage"
          class="alert alert-info"
          role="alert"
          style="white-space: pre-wrap; word-wrap: break-word"
        >
          {{ confirmMessage.trim() }}
        </div>
      </div>
    </b-modal>
    <div class="pt-3">
      <b-form-group>
        <label>絞り込み:</label>
        <b-form-select
          id="offset-option"
          :options="offsetOptions"
          v-model="offsetOption"
          class="m-md-2 col-2"
          variant="outline-secondary"
          @change="routerPush()"
        >
        </b-form-select>
        <b-form-select
          :options="sortOrderOptions"
          v-model="sortOrderOption"
          class="m-md-2 col-3"
          variant="outline-secondary"
          @change="routerPush()"
        >
        </b-form-select>
      </b-form-group>
    </div>
    <div class="text-right p-3">
      <button
        class="btn btn-primary col-3"
        @click="
          confirmMessage = null;
          newtopic();
          $bvModal.show('form-modal');
        "
      >
        ＋新規作成
      </button>
    </div>
    <div class="text-left px-3">※ユーザには新着順でのみ見れます。</div>
    <table class="table">
      <tr>
        <th class="align-middle text-center" style="width: 10%">編集</th>
        <th class="align-middle text-center" style="width: 30%">イメージ</th>
        <th class="align-middle text-center" style="width: 60%">情報</th>
      </tr>
      <tr v-for="(item, key) in this.topics" :key="key">
        <td class="align-middle text-center">
          <div class="pt-4">
            <button
              v-if="!item.fcm_pushed_at"
              class="btn btn-success"
              @click="pushfcm(item)"
            >
              通知
            </button>
            <button v-else class="btn btn-outline-success" disabled>
              通知済
            </button>
          </div>
          <div class="pt-3">
            <button class="btn btn-secondary" @click="edittopic(item)">
              編集
            </button>
          </div>
          <div class="pt-3">
            <button class="btn btn-danger" @click.stop.prevent="onDelete(item)">
              削除
            </button>
          </div>
        </td>
        <td class="align-middle text-center">
          <img v-bind:src="item.thumbnailUrl" style="max-height: 200px" />
        </td>
        <td class="align-middle">
          <h3 style="white-space: pre-wrap; word-wrap: break-word">
            {{ item.title }}
          </h3>
          <p style="white-space: pre-wrap; word-wrap: break-word">
            {{ item.description.replaceAll("\\n", "\n") }}
          </p>
          <p v-if="item.url">
            <b-link :href="item.url" target="_blank"> {{ item.url }}</b-link>
          </p>
          <div v-if="item.eventId">
            <small>接続イベント：{{ item.eventId }}</small>
          </div>
          <p v-if="item.deep_link">DeepLink:{{ item.deep_link }}</p>
          <div>
            <small>作成日時：{{ formatDate(item.createdAt) }}</small>
          </div>
          <div>
            <small>更新日時：{{ formatDate(item.updatedAt) }}</small>
          </div>
          <div v-if="item.fcm_pushed_at">
            <small>通知日時：{{ formatDate(item.fcm_pushed_at) }}</small>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import firebase from "firebase";
import "firebase/firestore";
import "firebase/storage";

import Vue from "vue";
import VueHolder from "vue-holderjs";
Vue.use(VueHolder);

export default {
  name: "Topic",
  data: function() {
    return {
      topic: {
        id: "",
        title: "",
        description: "",
        url: "",
        deep_link: null,
        thumbnailFileName: null,
        thumbnailUrl: null,
        thumbnailFile: null
      },
      offsetOption: "10",
      offsetOptions: [
        { value: "10", text: "10件" },
        { value: "30", text: "30件" },
        { value: "50", text: "50件" }
      ],
      sortOrderOption: "createdAt,desc",
      sortOrderOptions: [
        { value: "updatedAt,desc", text: "最新更新順" },
        { value: "createdAt,desc", text: "最新作成順" },
        { value: "title,asc", text: "タイトル昇順" },
        { value: "title,desc", text: "タイトル降順" }
      ],
      selectedtopic: {},
      topics: [],
      errors: [],
      saved: false,
      deleted: false,
      keyword: "",
      editFlg: false,
      confirmMessage: null,
      thumbnailFileProgressMessage: null,
      thumbnailUploadTask: null
    };
  },
  mounted: function() {
    this.getFromUrlParams();
    this.gettopics();
  },
  methods: {
    //topic
    newtopic: function() {
      this.saved = false;
      this.deleted = false;
      this.selectedtopic = {};
    },
    edittopic: function(topic) {
      this.saved = false;
      this.deleted = false;
      this.selectedtopic = {
        id: topic.id,
        title: topic.title,
        description: topic.description.replaceAll("\\n", "\n"),
        url: topic.url,
        eventId: topic.eventId,
        deep_link: topic.deep_link,
        thumbnailUrl: topic.thumbnailUrl,
        thumbnailFileName: topic.thumbnailFileName,
        thumbnailFile: null
      };
      this.$bvModal.show("form-modal");
    },
    pushfcm: function(topic) {
      let data = this;
      this.$bvModal
        .msgBoxConfirm("「" + topic.title + "」をユーザに通知します。")
        .then(result => {
          if (!result) {
            return;
          }
          firebase
            .firestore()
            .collection("topics")
            .doc(topic.id)
            .update({
              fcm_pushed_at: firebase.firestore.FieldValue.serverTimestamp()
            })
            .then(function() {
              data.gettopics();
              alert("通知しました");
            })
            .catch(function(error) {
              alert("通知でエラーが発生しました:" + error);
            });
        });
    },
    storetopic: async function(topic) {
      let data = this;
      return new Promise(function(resolve, reject) {
        if (data.selectedtopic.id) {
          firebase
            .firestore()
            .collection("topics")
            .doc(data.selectedtopic.id)
            .update(topic)
            .then(function() {
              data.saved = true;
              resolve();
            })
            .catch(function(error) {
              reject("保存でエラーが発生しました:" + error);
            });
        } else {
          firebase
            .firestore()
            .collection("topics")
            .add(topic)
            .then(function() {
              data.saved = true;
              resolve();
            })
            .catch(function(error) {
              reject("保存でエラーが発生しました:" + error);
            });
        }
      });
    },
    putStorageThumbnail: function(topic) {
      let data = this;
      return new Promise(function(resolve, reject) {
        const storageRef = firebase.storage().ref();
        data.thumbnailUploadTask = storageRef
          .child(topic.thumbnailFileName)
          .put(
            data.selectedtopic.thumbnailFile,
            data.selectedtopic.thumbnailFile.type
          );

        data.thumbnailUploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED,
          function(snapshot) {
            data.thumbnailFileProgressMessage =
              (
                (snapshot.bytesTransferred / snapshot.totalBytes) *
                100
              ).toString() + "%";
          },
          function(error) {
            switch (error.code) {
              case "storage/canceled":
                break;
              default:
                alert(
                  "イメージアップロード中にクラウドストレージにおけるその他のエラーが発生しました。:" +
                    error.code
                );
                break;
            }
            reject();
          },
          function() {
            data.thumbnailUploadTask.snapshot.ref
              .getDownloadURL()
              .then(function(downloadURL) {
                resolve(downloadURL);
              });
          }
        );
      });
    },
    savetopic: async function() {
      this.thumbnailFileProgressMessage = null;

      if (this.selectedtopic.id) {
        this.updatetopic();
      } else {
        this.createtopic();
      }
    },
    createtopic: async function() {
      let data = this;
      let topic = {
        title: data.selectedtopic.title,
        description: data.selectedtopic.description,
        url: data.selectedtopic.url ? data.selectedtopic.url : null,
        eventId: data.selectedtopic.eventId ? data.selectedtopic.eventId : null,
        deep_link: data.selectedtopic.deep_link
          ? data.selectedtopic.deep_link
          : null,
        thumbnailFileName: data.selectedtopic.thumbnailFile
          ? "topics/" + data.getHash(64)
          : null,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      };
      try {
        if (data.selectedtopic.thumbnailFile) {
          topic.thumbnailUrl = await this.putStorageThumbnail(topic);
        }
        this.thumbnailFileProgressMessage = null;
        await this.storetopic(topic);
        this.$bvModal.hide("form-modal");
        this.gettopics();
      } catch (err) {
        this.thumbnailFileProgressMessage = null;
        alert("エラーが発生しました:" + err);
      }
    },
    updatetopic: async function() {
      let data = this;
      let topic = {
        title: data.selectedtopic.title,
        description: data.selectedtopic.description,
        url: data.selectedtopic.url ? data.selectedtopic.url : null,
        eventId: data.selectedtopic.eventId ? data.selectedtopic.eventId : null,
        deep_link: data.selectedtopic.deep_link
          ? data.selectedtopic.deep_link
          : null,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        thumbnailFileName: this.selectedtopic.thumbnailFileName
      };
      try {
        if (data.selectedtopic.thumbnailFile) {
          topic.thumbnailUrl = await this.putStorageThumbnail(topic);
        }
        this.thumbnailFileProgressMessage = null;
        await this.storetopic(topic);
        this.$bvModal.hide("form-modal");
        this.gettopics();
      } catch (err) {
        this.thumbnailFileProgressMessage = null;
        alert("もう一度ご送信ください エラー:" + err);
      }
    },
    onDelete: function(topic) {
      this.saved = false;
      this.deleted = false;
      let data = this;
      this.$bvModal
        .msgBoxConfirm(
          "「" +
            topic.title +
            "」を削除します。この操作はやり直せません。削除しますか？"
        )
        .then(result => {
          if (!result) {
            return;
          }
          firebase
            .firestore()
            .collection("topics")
            .doc(topic.id)
            .delete()
            .then(function() {
              data.deleted = true;
              data.gettopics();
            })
            .catch(function(error) {
              console.error(error);
              alert("削除エラー: ", error);
            });
        });
    },
    cancelSaving: function() {
      if (this.thumbnailUploadTask != null) {
        this.thumbnailUploadTask.cancel();
      }
      this.$bvModal.hide("upload-files");
    },
    gettopics: function() {
      let self = this;
      const ref = firebase.firestore().collection("topics");
      const query = ref;
      query
        .orderBy(
          this.sortOrderOption.split(",")[0],
          this.sortOrderOption.split(",")[1]
        )
        .limit(Number(this.offsetOption))
        .get()
        .then(function(querySnapshot) {
          let datas = [];
          querySnapshot.forEach(function(doc) {
            let d = doc.data();
            d.id = doc.id;
            datas.push(d);
          });
          self.topics = datas;
        })
        .catch(function(error) {
          console.error("Error getting documents: ", error);
        });
    },
    getFromUrlParams: function() {
      if (this.$route.query["c"]) {
        this.offsetOption = this.$route.query["c"];
      }

      if (this.$route.query["o"]) {
        this.sortOrderOption = this.$route.query["o"];
      }
      if (this.$route.query["trainer"]) {
        this.newtopic();
        this.selectedtopic.deep_link =
          "trainer=" + this.$route.query["trainer"];
        this.$bvModal.show("form-modal");
      }
    },
    routerPush() {
      let pushRouteParam = { c: this.offsetOption, o: this.sortOrderOption };
      this.$router.push({ path: "", query: pushRouteParam });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.gettopics();
    this.saved = false;
    this.deleted = false;
    next();
  }
};
</script>
