import moment from "moment";
export const utils = {
  methods: {
    checkTimeStatus(startFireStoreStamp, endFireStoreStamp) {
      const now = moment();
      const start = moment(startFireStoreStamp.toDate());
      const end = moment(endFireStoreStamp.toDate());
      if (now.isBefore(start)) {
        return '期間前';
      } else if (now.isBetween(start, end)) {
        return '期間中';
      } else {
        return '期間後';
      }
    },
    getHash(length) {
      var base = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789".split("");
      var array = new Uint8Array(length);
      window.crypto.getRandomValues(array);
      var str = "";
      for (var i = 0; i < array.length; i++) {
        str += base[array[i] % base.length];
      }
      return str;
    },
    getDurationFromHumanTime: function(time) {
      console.log(time);
      const d = moment.duration("00:" + time);
      //_millisecondsでよい
      return parseFloat(d._milliseconds) / 1000;
    },
    getHumanTimeFromDuration: function(duration) {
      if (isNaN(duration)) {
        return "--:--";
      }
      const d = moment.duration(duration * 1000);
      return (
        d
          .minutes()
          .toString()
          .padStart(2, "0") +
        ":" +
        d
          .seconds()
          .toString()
          .padStart(2, "0")
      );
    },
    getHumanPrecisionTimeFromDuration: function(duration) {
      if (isNaN(duration)) {
        return "--:--.---";
      }
      const d = moment.duration(duration * 1000);
      return (
        d
          .minutes()
          .toString()
          .padStart(2, "0") +
        ":" +
        d
          .seconds()
          .toString()
          .padStart(2, "0") +
        "." +
        Math.floor(d.milliseconds())
          .toString()
          .padStart(3, "0")
      );
    },
    formatDate: function(firestoreStamp) {
      if (!firestoreStamp) return '----/--/-- --:--:--';
      const date = firestoreStamp.toDate();
      return (
        date.getFullYear() +
        "/" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "/" +
        ("0" + date.getDate()).slice(-2) +
        " " +
        ("0" + date.getHours()).slice(-2) +
        ":" +
        ("0" + date.getMinutes()).slice(-2) +
        ":" +
        ("0" + date.getSeconds()).slice(-2)
      );
    },
    formatDateDay: function(firestoreStamp) {
      if (!firestoreStamp) return '----/--/--';
      const date = firestoreStamp.toDate();
      return (
        date.getFullYear() +
        "/" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "/" +
        ("0" + date.getDate()).slice(-2)
      );
    },
    formatDateShort: function(firestoreStamp) {
      if (!firestoreStamp) return '----/--/-- --:--'
      const date = firestoreStamp.toDate();
      return (
        date.getFullYear() +
        "/" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "/" +
        ("0" + date.getDate()).slice(-2) +
        " " +
        ("0" + date.getHours()).slice(-2) +
        ":" +
        ("0" + date.getMinutes()).slice(-2)
      );
    },
    getCategoryTypeName: function(typeId) {
      if (typeId == this.$categoryDisplayTypeMovies) {
        return "動画リスト";
      } else if (typeId == this.$categoryDisplayTypeChapters) {
        return "チャプターリスト";
      }
      return "カテゴリリスト";
    },
    getReferences: function(master, docIdArray) {
      let res = [];
      if (docIdArray && docIdArray.length > 0) {
        docIdArray.forEach(function(id) {
          if (master.find((g) => g.id == id)) {
            res.push(master.find((g) => g.id == id).reference);
          }
        });
      }
      return res;
    },
  },
};
