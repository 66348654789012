<template>
  <div class="image-input border mx-auto" :style="sizingStyle">
    <div
      class="image-input__field"
      :class="{ over: isDragOver }"
      @dragover.prevent="onDrag('over')"
      @dragleave="onDrag('leave')"
      @drop.stop="onDrop"
      :style="{
        backgroundImage: 'url(' + (dataUrl ? dataUrl : defaultImage) + ')',
      }"
      style="
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      "
    >
      <input
        type="file"
        title
        @change="onChange"
        accept=".webp, .jpg, .jpeg, .png, .gif"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "value",
    "sizingStyle",
    "validSize",
    "defaultImage",
    "selectedCategory",
    "copyOriginUrl"
  ],
  data() {
    return {
      isDragOver: false,
      dataUrl: null
    };
  },
  mounted: function() {
    if (this.copyOriginUrl) {
      const canvas = document.createElement("canvas");
      const image = new Image();
      image.addEventListener("load", {
        obj: this,
        handleEvent: function() {
          canvas.width = image.naturalWidth;
          canvas.height = image.naturalHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(image, 0, 0);
          try {
            const dataURL = canvas.toDataURL("image/webp");
            this.obj.setImage(dataURL);
          } catch (error) {
            console.error(error);
            // webp 非対応
          }
        }
      });
      image.src = this.copyOriginUrl;
    }
  },
  computed: {
    image: {
      set(value) {
        this.$emit("value", value);
      },
      get() {
        return this.value;
      }
    }
  },
  methods: {
    onDrag(type) {
      this.isDragOver = type === "over";
    },
    onDrop(event) {
      this.isDragOver = false;
      const files = event.dataTransfer.files;
      if (files.length !== 1 || files[0].type.indexOf("image") !== 0) {
        return;
      }
      this.readImage(files[0]);
    },
    onChange(event) {
      const files = event.target.files;
      if (files.length !== 1 || files[0].type.indexOf("image") !== 0) {
        return;
      }
      this.readImage(files[0]);
    },
    readImage(file) {
      let reader = new FileReader();
      reader.onload = this.loadImage;
      reader.readAsDataURL(file);
    },
    loadImage(e) {
      this.setImage(e.target.result);
    },
    setImage(dataUrl) {
      if (dataUrl.startsWith("data:image/webp;")) {
        this.dataUrl = dataUrl;
        this.$emit("value", this.dataUrl);
        return;
      }
      const canvas = document.createElement("canvas");
      const image = new Image();
      image.addEventListener("load", {
        obj: this,
        handleEvent: function() {
          canvas.width = image.naturalWidth;
          canvas.height = image.naturalHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(image, 0, 0);
          try {
            const convData = canvas.toDataURL("image/webp");
            this.obj.dataUrl = convData;
            this.obj.$emit("value", this.obj.dataUrl);
          } catch (error) {
            console.error(error);
            // webp 非対応
          }
        }
      });
      image.src = dataUrl;
    }
  }
};
</script>
<style>
.image-input {
  background-color: #eee;
}
.image-input__field {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-input__field.over {
  background-color: #666;
}
.image-input__field > input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.image-input__field > p {
  text-align: center;
}
</style>
